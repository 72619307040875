import React, { Component } from 'react';
import AuthService from '../service/auth/AuthService';
import { Link, withRouter } from 'react-router-dom';
import RoleContext from '../contexts/RoleContext';

const Auth = new AuthService();

let photo_url = Auth.getUserPhoto();
if (photo_url === 'undefined') {
  photo_url = 'dist/img/user2-160x160.jpg';
}

class Header extends Component {
  static contextType = RoleContext;
  state = {
    user_name: Auth.getName(),
    photo_url: this.photo_url,
  };

  render() {
    return (
      <header className='main-header'>
        <div className='logo'>
          <span className='logo-mini'><img src={'/bogalakon_icon.png'} alt={'bogalakon icon'} /></span>
          <span className='logo-lg'><b>Boga</b> Dashboard</span>
        </div>
        <nav className='navbar navbar-static-top'>
          <a href='#' className='sidebar-toggle' data-toggle='push-menu' role='button'>
            <span className='sr-only'>Toggle navigation</span>
          </a>
          <div className='navbar-custom-menu'>
            <ul className='nav navbar-nav'>
              <li className='dropdown user user-menu'>
                <a href='#' className='dropdown-toggle' data-toggle='dropdown'>
                  <img src={photo_url} className='user-image' alt='User' />
                  <span className='hidden-xs'>{this.state.user_name}</span>
                </a>
                <ul className='dropdown-menu'>
                  <li className='user-header'>
                    <img src={photo_url} className='img-circle' alt='User' />
                    <p>
                      {this.state.user_name + ' - ' + this.context.groupName}
                    </p>
                  </li>
                  <li className='user-footer'>
                    <div className='pull-left'>
                      <Link className='btn btn-default btn-flat'
                            to={{ pathname: '/profile', handleLogout: this.props.handleLogout }}>
                        Profile</Link>
                    </div>
                    <div className='pull-right'>
                      <button type='button' className='btn btn-default btn-flat'
                              onClick={this.props.handleLogout.bind(this)}>Logout
                      </button>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    );
  }
}

export default withRouter(Header);
