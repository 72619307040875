import React, { Component } from 'react';
import DeliveryService from '../../../service/DeliveryService';
import { Link, withRouter } from 'react-router-dom';
import ReactTable from 'react-table-6';
import GlobalSearchComponent from './GlobalSearchDelivery';
import { errorMessage } from '../../../utils/NotificationMessage';

const DeliveryServices = new DeliveryService();

class Delivery extends Component {

  constructor() {
    super();
    this.fetchData = this.fetchData.bind(this);
    this.state = {
      deliveryList: [],
      filteredData: [],
      columns: [],
      searchInput: '',
      pages: 0,
      loading: true,
    };
  }

  componentDidMount() {
    this.fetchData.bind(this);
    this.getColumns();
  }

  fetchData(state) {
    this.setState({ loading: true });
    // jsonData['keyword'] = state.filtered;
    if (!DeliveryServices.isTokenExpired()) {
      this.getDeliveryList(state);
    } else {
      DeliveryServices.reloadToken().then(() => {
        this.getDeliveryList(state);
      }).catch(err => errorMessage(err.message));
    }
  }

  handleSetData = driverList => {
    console.log(driverList);
    this.setState({ filteredData: driverList });
  };

  getDeliveryList(state) {
    DeliveryServices.getDeliveryList().then(e => {
      this.setState({
        loading: false,
        deliveryList: e.data,
        filteredData: e.data,
      });
    }).catch(err => errorMessage(err.message));
  }

  getColumns = () => {
    const formatter = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    });

    let columns = [
      {
        Header: 'Kode Transaksi',
        id: 'r',
        accessor: d => d,
        Cell: ({ row }) => (<Link to={{ pathname: '/delivery/' + row.r.id }}>{row.r.transaction_code}</Link>),
        // id: 'r',
        // accessor: d => d,
        // Cell: ({ row }) => (
        //     <img src={row.r.photoUrlDefault} alt="photoDefault" style={{width : 60, height: 60}} />
        // ),
        // className: "text-center",
        // sortable: false,
      },
      {
        Header: 'Nama Pengirim',
        accessor: 'sender_name',
        // id: 'name',
        // accessor: d => d,
        // Cell: ({ row }) => (<Link to={{ pathname: '/product/'+row.name.id}}>{row.name.name}</Link>)
      },
      {
        Header: 'Nama Penerima',
        accessor: 'receiver_name',
        // id: 'price',
        // accessor: d => Number(d.price),
        // Cell : ({ row }) => formatter.format(row.price),
      },
      {
        Header: 'Total Harga',
        id: 'r',
        accessor: d => d,
        Cell: ({ row }) => formatter.format(row.r.price),
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Tipe Service',
        accessor: 'service',
      },
    ];
    this.setState({ columns });
  };

  render() {
    let { filteredData, columns } = this.state;

    return (
      <React.Fragment>
        {/* Content Header (Page header) */}
        <section className='content-header'>
          <h1>
            Daftar Delivery
          </h1>
          <ol className='breadcrumb'>
            <li className='active'>Data tables</li>
          </ol>
        </section>
        {/* Main content */}
        <section className='content'>
          <div className='row'>
            <div className='col-xs-12'>
              <div className='box'>
                <div className='box-body'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='input-group pull-left' style={{ marginBottom: 10 }}>
                        <button type='button' className='btn btn-primary' onClick={() => {
                          this.props.history.push('/createDelivery');
                        }}>
                          <i className='fa fa-plus'> Add</i>
                        </button>
                      </div>
                      <div className='input-group pull-right' style={{ width: 300, marginBottom: 10 }}>
                        {/* <span className="input-group-addon"><i className="fa fa-search" /></span> */}
                        {/* <input type="text" className="form-control" name="searchInput" placeholder="Search User" onChange={this.handleChange} value={searchInput || ""}/> */}
                        <GlobalSearchComponent
                          data={this.state.deliveryList}
                          handleSetData={this.handleSetData}
                        />
                      </div>
                    </div>
                  </div>
                  <ReactTable
                    columns={columns}
                    data={filteredData}
                    sortable={false}
                    // filtered={this.state.filter}
                    loading={this.state.loading}
                    onFetchData={this.fetchData}
                    defaultPageSize={10} />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /.content */}
      </React.Fragment>
    );
  }
}

export default withRouter(Delivery);