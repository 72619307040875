import React, { Component } from 'react';
import DeliveryService from '../../../service/DeliveryService';
import withAuth from '../../../service/auth/withAuth';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import { errorMessage } from '../../../utils/NotificationMessage';

const DeliveryServices = new DeliveryService();

class TrackingDetail extends Component {

  // static defaultProps = {
  //     center: {
  //         lat: -2.5385919,
  //         lng: 124.5426618
  //     },
  //     zoom: 4
  // };

  constructor() {
    super();
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.state = {
      trackingId: 0,
      height: 0,
      latitude: null,
      longitude: null,
      driverName: '',
      trackingList: [],
    };
  }

  componentDidMount() {
    const pathname = window.location.pathname;
    const split = pathname.split('/');
    this.updateWindowDimensions();
    this.setState({ trackingId: split[2] });
    window.addEventListener('resize', this.updateWindowDimensions);
    if (!DeliveryServices.isTokenExpired()) {
      this.getDetailTracking(split[2]);
    } else {
      DeliveryServices.reloadToken().then(() => {
        this.getDetailTracking(split[2]);
      }).catch(err => errorMessage(err.message));
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  getDetailTracking(id) {
    DeliveryServices.getTrackingDetail(id).then(e => {
      this.setState({ trackingList: e.data.reverse() });
    });
  }

  modalMap(e, lat, lng, driverName) {
    e.preventDefault();
    this.setState({
      latitude: lat,
      longitude: lng,
      driverName: driverName,
    });
  }

  updateWindowDimensions() {
    this.setState({ height: window.innerHeight * 0.8 });
  }

  render() {
    const { trackingList } = this.state;
    const moment = require('moment-timezone');

    return (
      <React.Fragment>
        <section className='content-header'>
          <h1>
            Tracking Detail
          </h1>
        </section>
        <section className='content'>
          <div className='box box-primary'>
            <div className='box-body box-profile'>
              <ul className='timeline'>
                {trackingList.map((val, index) => [
                  <li key={index}
                      className={index > 0 ? !moment(val.last_update).isSame(moment(trackingList[index - 1].last_update), 'day') ? 'time-label' : 'd-none' : 'time-label'}>
                    <span className='bg-green'>
                        {moment.utc(val.last_update).format("LL")}
                    </span>
                  </li>,
                  <li key={index + index + 1}>
                    <i className='fa fa-user bg-aqua' />
                    <div className='timeline-item'>
                      <span className='time'><i className='fa fa-clock-o' /> {moment.utc(val.last_update).format("LT")}</span>
                      <h3 className='timeline-header'>{val.fullname}</h3>
                      <div className='timeline-body'>
                        Location Update
                        <a href='/#' data-toggle='modal' data-target='#myModal'
                           onClick={(e) => this.modalMap(e, val.lat, val.lng, val.fullname)}>
                          {' ' + val.lat + ', ' + val.lng}
                        </a>
                      </div>
                    </div>
                  </li>,
                ])}
              </ul>
            </div>
          </div>
        </section>
        <div id='myModal' className='modal fade' tabIndex='-1' role='dialog' aria-labelledby='myLargeModalLabel'>
          <div className='modal-dialog modal-lg' role='document'>
            <div className='modal-content'
                 style={{ height: this.state.height, maxHeight: this.state.height, overflowY: 'auto' }}>
              <Map
                google={this.props.google}
                initialCenter={{
                  lat: -2.5385919,
                  lng: 124.5426618,
                }}
                center={{
                  lat: this.state.latitude,
                  lng: this.state.longitude,
                }}
                zoom={15}>
                <Marker
                  title={this.state.driverName}
                  position={{ lat: this.state.latitude, lng: this.state.longitude }} />
              </Map>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

}

export default withAuth(GoogleApiWrapper({
  apiKey: ('AIzaSyDy5thJJOp9kPrbs6UUrzhYVftU3W3eB4g'),
})(TrackingDetail));