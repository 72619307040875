import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter as Router, Route } from 'react-router-dom';

// Our Components
import Login from './components/Login';
import App from './App';
import './index.css';
import { RoleProvider } from './contexts/RoleContext';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import { NotificationContainer } from 'react-notifications';

ReactDOM.render(
  <RoleProvider>
    <React.Fragment>
      <NotificationContainer />
      <Router>
        <Route exact path='/login' component={Login} />
        <Route exact path='/forgetPassword' component={ForgotPassword} />
        <Route exact path='/reset-password' component={ResetPassword} />
        <Route component={App} />
      </Router>
    </React.Fragment>
  </RoleProvider>
  , document.getElementById('root'),
);


registerServiceWorker();
