import React, { Component } from 'react';
import ProductService from '../../service/ProductService';
import { Link, withRouter } from 'react-router-dom';
import ReactTable from 'react-table-6';
import { errorMessage } from '../../utils/NotificationMessage';

const Products = new ProductService();

class Product extends Component {

  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.state = {
      productList: [],
      sort: [],
      filter: '',
      pages: null,
      loading: true,
    };
  }

  fetchData(state) {
    this.setState({ loading: true });
    const sorted = state.sorted.length > 0 ? state.sorted[0].id : 'updatedAt';
    const ascdesc = state.sorted.length > 0 ? state.sorted[0].desc : false;
    const jsonData = {};
    jsonData['page'] = state.page + 1;
    jsonData['limit'] = state.pageSize;
    jsonData['keyword'] = state.filtered;
    jsonData['sortBy'] = sorted;
    jsonData['ascending'] = ascdesc;
    Products.getProductList(jsonData).then(res => {
      this.setState({
        loading: false,
        pages: res.totalPages,
        productList: res.content,
      });
    }).catch(err => errorMessage(err.message));
  }

  handleChange(e) {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
    );
  }

  render() {

    const formatter = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    });

    const columns = [
      {
        Header: 'Gambar Produk',
        id: 'r',
        accessor: d => d,
        Cell: ({ row }) => (
          <img src={row.r.photoUrlDefault} alt='photoDefault' style={{ width: 60, height: 60 }} />
        ),
        className: 'text-center',
        sortable: false,
      },
      {
        Header: 'Nama Produk',
        id: 'name',
        accessor: d => d,
        Cell: ({ row }) => (<Link to={{ pathname: '/product/' + row.name.id }}>{row.name.name}</Link>),
      },
      {
        Header: 'Harga',
        id: 'price',
        accessor: d => Number(d.price),
        Cell: ({ row }) => formatter.format(row.price),
      },
      {
        Header: 'Kategori',
        accessor: 'category.name',
      },
      {
        Header: 'Nama Toko',
        accessor: 'seller.namaToko',
      },
      {
        Header: 'Status Produk',
        accessor: 'isActive',
        Cell: ({ value }) => (value === 1 ? 'Aktif' : 'Tidak'),
      },
    ];

    return (
      <React.Fragment>
        {/* Content Header (Page header) */}
        <section className='content-header'>
          <h1>
            Daftar Produk
          </h1>
          <ol className='breadcrumb'>
            <li className='active'>Data tables</li>
          </ol>
        </section>
        {/* Main content */}
        <section className='content'>
          <div className='row'>
            <div className='col-xs-12'>
              <div className='box'>
                {/* /.box-header */}
                <div className='box-body'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='input-group pull-right' style={{ width: 300, marginBottom: 10 }}>
                        <span className='input-group-addon'><i className='fa fa-search' /></span>
                        <input type='text' className='form-control' name='filter' placeholder='Cari Produk'
                               onChange={this.handleChange} />
                      </div>
                    </div>
                  </div>
                  <ReactTable
                    manual
                    columns={columns}
                    data={this.state.productList}
                    filtered={this.state.filter}
                    loading={this.state.loading}
                    onFetchData={this.fetchData}
                    pages={this.state.pages}
                    defaultPageSize={5} />
                </div>
                {/* /.box-body */}
              </div>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </section>
        {/* /.content */}
      </React.Fragment>
    );
  }
}

export default withRouter(Product);