import React, { Component } from 'react';
import CategoryService from '../../service/CategoryService';
import { Link, withRouter } from 'react-router-dom';
import { errorMessage, successMessage } from '../../utils/NotificationMessage';

const Categorys = new CategoryService();

class Category extends Component {

  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.handleAddCategory = this.handleAddCategory.bind(this);
    this.state = {
      categoryName: '',
      categoryParent: '',
      categoryList: [],
    };
  }

  componentDidMount() {
    this.getCategoryList();
  }

  handleChange(e) {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
    );
  }

  handleAddCategory(e) {
    e.preventDefault();
    const nameCategory = this.state.categoryName;
    const parentCategory = Number(this.state.categoryParent);
    const jsonData = {};
    jsonData['name'] = nameCategory;
    if (parentCategory > 0) {
      jsonData['parentId'] = parentCategory;
    }
    Categorys.addCategory(jsonData).then(_ => {
      successMessage('Berhasil Menambah Category');
      this.setState({
        categoryName: '',
        categoryParent: '',
      });
      this.getCategoryList();
    }).catch(err => {
      errorMessage(err.message);
    });
  }

  getCategoryList() {
    Categorys.getCategoryList().then(res => {
      this.setState({
        categoryList: res,
      });
    }).catch(err => errorMessage(err.message));
  }

  onDelete(id) {
    Categorys.deleteCategoryById(id).then(() => {
      successMessage('Berhasil Dihapus');
      this.getCategoryList();
    }).catch(err => {
      errorMessage(err.message);
    });
  }

  render() {
    return (
      <React.Fragment>
        <section className='content-header'>
          <h1>
            Daftar Category
          </h1>
        </section>
        <section className='content'>
          <div className='row'>
            <div className='col-xs-12'>
              <div className='box'>
                <div className='box-body'>
                  <div className='list-group'>
                    {this.state.categoryList.map((item, index) => (
                      <div key={index} className='categoryList' style={{ marginBottom: 10 }}>
                        <div className='category-group list-group-item'>
                          {item.iconUrl !== null ?
                            <img alt='iconUrl' src={item.iconUrl} style={{ width: 25, marginRight: 10 }} /> :
                            <div style={{ width: 25, marginRight: 10, display: 'inline-block' }} />}
                          <span ref={'name' + index}>{item.name}</span>
                          <a href={item.children.length ? '#sub' + item.id : '#'} data-toggle='collapse'
                             className={item.children.length ? '' : 'd-none'} style={{ marginLeft: 10 }}>
                            <i className='fa fa-chevron-down' />
                          </a>
                          <div className='tools'>
                            <Link to={{ pathname: '/category/' + item.id }}>
                              <i className='fa fa-edit' />
                            </Link>
                            {item.children.length ? '' :
                              <Link to='#' onClick={() => {
                                if (window.confirm('Are you sure you wish to delete this item?')) this.onDelete(item.id);
                              }}>
                                <i className='fa fa-trash-o' />
                              </Link>}
                          </div>
                        </div>
                        <div className='collapse list-group-submenu' id={'sub' + item.id}>
                          {item.children.map((child) => (
                            <div key={child.id} className='category-group list-group-item' style={{ paddingLeft: 30 }}>
                              <span ref={'name' + index}>{child.name}</span>
                              <a href={child.children.length ? '#sub' + child.id : '#'} data-toggle='collapse'
                                 className={child.children.length ? '' : 'd-none'} style={{ marginLeft: 10 }}>
                                <i className='fa fa-chevron-down' />
                              </a>
                              <div className='tools'>
                                <Link to={{ pathname: '/category/' + child.id }}>
                                  <i className='fa fa-edit' />
                                </Link>
                                <Link to='#' onClick={() => {
                                  if (window.confirm('Are you sure you wish to delete this item?')) this.onDelete(child.id);
                                }}>
                                  <i className='fa fa-trash-o' />
                                </Link>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                    <div style={{ marginBottom: 10, marginTop: 20 }}>
                      <div className='category-group list-group-item'>
                        <b>Create Kategori</b>
                        <form className='form-horizontal' onSubmit={this.handleAddCategory}>
                          <div className='form-group'>
                            <label className='col-sm-3 control-label'>Nama Kategori</label>
                            <div className='col-sm-9'>
                              <input type='text' className='form-control' name='categoryName' value={this.state.categoryName}
                                     placeholder='Nama Kategori' onChange={this.handleChange} required />
                            </div>
                          </div>
                          <div className='form-group'>
                            <label className='col-sm-3 control-label'>Parent</label>
                            <div className='col-sm-9'>
                              <select name='categoryParent' value={this.state.categoryParent} className='form-control'
                                      onChange={this.handleChange}>
                                <option value='0'>No Parent</option>
                                {this.state.categoryList.map((item, index) => (
                                  <option key={'parent' + index} value={item.id}>{item.name}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className='form-group text-right'>
                            <div className='col-sm-12'>
                              <button type='submit' className='btn btn-primary'>
                                <i className='fa fa-plus'> Add</i>
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withRouter(Category);