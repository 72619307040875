import { DELIVERY, DRIVER, SERVICES, TRACKING } from '../utils/APIUtil';
import decode from 'jwt-decode';
import axios from 'axios';
import AuthService from './auth/AuthService';

const Auth = new AuthService();
export default class DeliveryService {

  isTokenExpired() {
    try {
      const decoded = decode(Auth.getTokenDelivery());
      return decoded.exp < Date.now() / 1000;
    } catch (err) {
      return false;
    }
  }

  reloadToken() {
    const token = Auth.loginDelivery(Auth.getToken()).then(e => {
      Auth.setTokenDelivery(e.token);
      return e.token;
    });
    return Promise.resolve(token);
  }

  async getDriverList(page, limit) {
    const response = await axios.get(DRIVER + '/list', { headers: { auth: Auth.getTokenDelivery() } });
    const res = response.data;
    if (res.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject('err');
    }
  }

  async addDriver(jsonData) {
    const response = await axios.post(DRIVER + '/save', jsonData, { headers: { auth: Auth.getTokenDelivery() } })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        if (error.response.status === 502) {
          return error.response;
        }
      });
    const res = response.data;
    return Promise.resolve(res);
  }

  async activateDriver(id) {
    const res = await axios.put(DRIVER + '/activation/' + id, {}, { headers: { auth: Auth.getTokenDelivery() } })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        // if (error.response.status === 502) {
        //     return error
        // }
        return error;
      });
    return Promise.resolve(res);
  }

  async editDriver(id, jsonData) {
    const response = await axios.put(DRIVER + '/update/' + id, jsonData, { headers: { auth: Auth.getTokenDelivery() } });
    const res = response.data;
    if (res.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject('err');
    }
  }

  async postPictDriver(formData) {
    const response = await axios.post(DRIVER + '/upload_photo', formData, { headers: { auth: Auth.getTokenDelivery() } });
    const res = response.data;
    if (res.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject('err');
    }
  }

  async deleteDriver(id) {
    const response = await axios.delete(DRIVER + '/delete/' + id, { headers: { auth: Auth.getTokenDelivery() } });
    const res = response.data;
    if (res.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject('err');
    }
  }

  async getDriverDetail(id) {
    const response = await axios.get(DRIVER + '/detail/' + id, { headers: { auth: Auth.getTokenDelivery() } });
    const res = response.data;
    if (res.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject('err');
    }
  }

  async getDeliveryList(page, limit) {
    const response = await axios.get(DELIVERY + '/list', { headers: { auth: Auth.getTokenDelivery() } });
    const res = response.data;
    if (res.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject('err');
    }
  }

  async getDeliveryDetail(id) {
    const response = await axios.get(DELIVERY + '/detail/' + id, { headers: { auth: Auth.getTokenDelivery() } });
    const res = response.data;
    if (res.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject('err');
    }
  }

  async addDelivery(jsonData) {
    const response = await axios.post(DELIVERY + '/order', jsonData, { headers: { auth: Auth.getTokenDelivery() } })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        if (error.response.status === 422) {
          return error.response;
        }
      });
    const res = response.data;
    return Promise.resolve(res);
  }

  async deleteDelivery(id) {
    const response = await axios.delete(DELIVERY + '/delete/' + id, { headers: { auth: Auth.getTokenDelivery() } });
    const res = response.data;
    if (res.status === 200) {
      return Promise.resolve(res);
    } else {
      console.log(res);
      return Promise.reject('err');
    }
  }

  async getServicesList() {
    const response = await axios.get(SERVICES + '/list', { headers: { auth: Auth.getTokenDelivery() } });
    const res = response.data;
    if (res.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject('err');
    }
  }

  async getServicesById(id) {
    const response = await axios.get(SERVICES + '/detail/' + id, { headers: { auth: Auth.getTokenDelivery() } });
    const res = response.data;
    if (res.status === 200) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject('err');
    }
  }

  async updateServices(id, jsonData) {
    const response = await axios.put(SERVICES + '/update/' + id, jsonData, { headers: { auth: Auth.getTokenDelivery() } });
    const res = response.data;
    if (res.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject('err');
    }
  }

  async addServices(jsonData) {
    const response = await axios.post(SERVICES + '/save', jsonData, { headers: { auth: Auth.getTokenDelivery() } })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        if (error.response.status === 502) {
          return error.response;
        }
      });
    const res = response.data;
    return Promise.resolve(res);
  }

  async deleteServices(id) {
    const response = await axios.delete(SERVICES + '/delete/' + id, { headers: { auth: Auth.getTokenDelivery() } });
    const res = response.data;
    if (res.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject('err');
    }
  }

  async calculate_price(jsonData) {
    const response = await axios.post(SERVICES + '/calculate_price/', jsonData, { headers: { auth: Auth.getTokenDelivery() } });
    const res = response.data;
    console.log(response);
    if (response.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject('err');
    }
  }

  async getTrackingList(page, limit) {
    const response = await axios.get(TRACKING + '/list', { headers: { auth: Auth.getTokenDelivery() } });
    const res = response.data;
    if (res.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject('err');
    }
  }

  async getTrackingDetail(id) {
    const response = await axios.get(TRACKING + '/detail/' + id, { headers: { auth: Auth.getTokenDelivery() } });
    const res = response.data;
    if (res.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject('err');
    }
  }
}