const SERVER_URL = 'https://app.bogalakon.proto.chrombit.com/api'
const SERVER_DELIVERY = 'https://driverapp.bogalakon.proto.chrombit.com'

export const LOGIN_URL = SERVER_URL + "/auth/signin"
export const DELIVERY_TOKEN = SERVER_URL + "/delivery/token"
export const AUTH = SERVER_URL + "/auth"
export const GET_ME = SERVER_URL + "/user"
export const UPDATE_PROFILE_PICTURE = SERVER_URL + "/uploadFile/profile"
export const USER = SERVER_URL + "/user"
export const ADD_USER = SERVER_URL + "/add-user"
export const SELLER = SERVER_URL + "/seller"
export const PRODUCT = SERVER_URL + "/product"
export const PRODUCTV2 = SERVER_URL + "/v2/product"
export const ORDER = SERVER_URL + "/order"
export const ORDERID = SERVER_URL + "/orderId"
export const CATEGORY = SERVER_URL + "/category"
export const POINT = SERVER_URL + "/point"
export const BANNER = SERVER_URL + "/banner"
export const PROMO = SERVER_URL + "/promo"
export const PROMOVIEW = SERVER_URL + "/promo-page-webview"
export const DASHBOARD = SERVER_URL + "/dashboard"
export const UPLOADFILE = SERVER_URL + "/uploadFile"
export const TERMS = SERVER_URL + "/terms-condition"
export const CASHOUT = SERVER_URL + "/cashout"
export const ROLEGROUP = SERVER_URL + "/v1/role-group"

export const DRIVER = SERVER_DELIVERY + "/driver"
export const DELIVERY = SERVER_DELIVERY + "/delivery"
export const SERVICES = SERVER_DELIVERY + "/services"
export const TRACKING = SERVER_DELIVERY + "/tracking"
