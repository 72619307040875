import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import UserService from '../../service/UserService';
import DatePicker from 'react-date-picker';
import ReactTable from 'react-table-6';
import { errorMessage, successMessage } from '../../utils/NotificationMessage';
import AuthService from '../../service/auth/AuthService';
import RoleContext from '../../contexts/RoleContext';

const User = new UserService();
const authService = new AuthService();
const moment = require('moment');

class UserDetail extends Component {
  static contextType = RoleContext
  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdateUser = this.handleUpdateUser.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleFilterDate = this.handleFilterDate.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this)
    this.getPointList = this.getPointList.bind(this);
    this.state = {
      userId: 0,
      firstName: '',
      lastName: '',
      userPhoto: '',
      userEmail: '',
      userPhone: '',
      userBirthday: '',
      userGender: '',
      userCash: 0,
      userPoint: 0,
      datePoint: '',
      inputNewPassword: '',
      loading: true,
      pointList: [],
      errors: [],
    };
  }

  componentDidMount() {
    const pathname = window.location.pathname;
    const split = pathname.split('/');
    const userId = Number(split[2]);

    if (userId === authService.getId()) {
      this.props.history.replace('/profile');
    }

    this.setState({
      userId: userId,
    });
    this.getUserById(userId);
    this.getPoint(userId);
  }

  getUserById(userId) {
    User.getUserById(userId).then(res => {
      const script = document.createElement('script');
      script.src = '../js/date.js';
      script.async = true;
      document.body.appendChild(script);
      document.body.removeChild(script);
      let dateString = '';
      let dt = new Date();
      if (res.birthday !== '') {
        dateString = res.birthday;
        const pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        dt = new Date(dateString.replace(pattern, '$3-$2-$1'));
      }
      this.setState({
        firstName: res.firstName,
        lastName: res.lastName,
        userEmail: res.email,
        userPhone: res.phone,
        userBirthday: dt,
        userPhoto: res.photo,
        userGender: '' + res.gender,
      });
    }).catch(err => errorMessage(err.message));
  }

  getPoint(userId) {
    User.getPoint('M', userId).then(res => {
      this.setState({
        userCash: res.balance,
      });
    }).catch(err => errorMessage(err.message));
    User.getPoint('P', userId).then(res => {
      this.setState({
        userPoint: res.balance,
      });
    }).catch(err => errorMessage(err.message));
  }

  getPointList(state) {
    const pathname = window.location.pathname;
    const split = pathname.split('/');
    const userId = Number(split[2]);
    this.setState({ loading: true });
    if (state.filtered !== '') {
      const dateString = 'startDate=' + moment(state.filtered).format('YYYY-MM-DD');
      User.getPointListById(userId, dateString).then(res => {
        this.setState({
          loading: false,
          pointList: res.data,
        });
      }).catch(err => errorMessage(err.message));
    } else {
      User.getPointListById(userId, '').then(res => {
        this.setState({
          loading: false,
          pointList: res.data,
        });
      }).catch(err => errorMessage(err.message));
    }
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleDateChange = date => {
    this.setState({
      userBirthday: date,
    });
  };

  handleFilterDate = date => {
    const newDate = date !== null ? date : '';
    this.setState({
      datePoint: newDate,
    });
  };

  validate(firstName, lastName, email, phone) {
    this.setState({ errors: [] });
    const errors = [];
    firstName = firstName.trimLeft();
    if (firstName.length < 1) {
      errors.push('Nama depan tidak boleh kosong');
    }
    if (firstName.length < 3) {
      errors.push('Nama depan tidak boleh kurang dari 3 karakter');
    }
    if (!!lastName.length < 1) {
      errors.push('Nama belakang tidak boleh kosong');
    }
    if (email.length < 5) {
      errors.push('Email tidak boleh kurang dari 5 karakter');
    }
    if (email.split('').filter(x => x === '@').length !== 1) {
      errors.push('Format email salah, silahkan periksa lagi');
    }
    if (email.indexOf('.') === -1) {
      errors.push('Format email salah, silahkan periksa lagi');
    }
    if (phone.length < 9) {
      errors.push('Nomor telepon harus lebih dari 9 karakter');
    }
    return errors;
  }

  handleUpdateUser(e) {
    e.preventDefault();
    const firstName = this.state.firstName;
    const lastName = this.state.lastName;
    const userEmail = this.state.userEmail;
    const userPhone = this.state.userPhone;

    const errors = this.validate(firstName, lastName, userEmail, userPhone);
    if (errors.length > 0) {
      this.setState({ errors });
      return;
    }
    const jsonData = {};
    jsonData['firstName'] = firstName;
    jsonData['lastName'] = lastName;
    jsonData['email'] = userEmail;
    jsonData['phone'] = userPhone;
    jsonData['birthday'] = moment(this.state.userBirthday).format('DD/MM/YYYY');
    jsonData['gender'] = this.state.userGender;
    User.updateUser(jsonData, this.state.userId).then(() => {
      successMessage('Update Profil Berhasil');
      this.props.history.replace('/user');
    }).catch(() => errorMessage('Password Lama Salah'));
  }

  handleChangePassword(e) {
    e.preventDefault()
    if (this.state.inputNewPassword.length > 0) {
      User.changePasswordSuperAdmin(this.state.userId, this.state.inputNewPassword).then(() => {
        successMessage('Berhasil Mengubah Password User');
        this.setState({ inputNewPassword: '' });
      }).catch(err => errorMessage(err.message));
    }
  }

  render() {
    const formatter = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    });

    const columns = [
      {
        Header: 'Tanggal Transaksi',
        id: 'r1',
        width: 200,
        accessor: d => d,
        Cell: ({ row }) => <span>{moment(row.r1.createdAt).format('DD/MM/YY hh:mm A')}</span>,
      },
      {
        Header: 'Aktivitas',
        id: 'r2',
        accessor: 'event.name',
        Cell: ({ row }) => {
          switch (row.r1.event.code) {
            case 'E00005' :
              return `${row.r2} ${row.r1.sender.phone}`;
            case 'E00006' :
              if (row.r1.bank) {
                return `${row.r2 + ' ' + 'From ' + row.r1.bank.name}`;
              }
              return `${row.r2 + ' ' + 'From Unknown Bank'}`;
            case 'E00008' :
              if (row.r1.checkout) {
                return `${row.r2 + ' ' + row.r1.checkout?.orderDate + '-' + row.r1.checkout?.checkoutId}`;
              }
              return `${row.r2}`;
            case 'E00007' :
              if (row.r1.sender !== null) {
                return <span>{row.r2} <Link to={{ pathname: '/user/' + row.r1.sender.id }}>{row.r1.sender.phone}</Link></span>;
              } else if (row.r1.receiver !== null) {
                return <span>{row.r2} <Link
                  to={{ pathname: '/user/' + row.r1.receiver.id }}>{row.r1.receiver.phone}</Link></span>;
              }
              return `Unknown`;
            case 'E00009' :
            case 'E00010' :
              if (row.r1.orderId !== null) {
                return <span>{row.r2} <Link
                  to={{ pathname: '/order/' + row.r1.orderId.id }}>{row.r1.orderId.receiptId}</Link></span>;
              }
              return <span>{row.r2} From {row.r1.sender.completeName}</span>;
            case 'E00020' :
              return `Driver Commission`;
            default:
              console.log(row.r1);
              if (row.r1.event) {
                return `${row.r2}`;
              }
              return `Unknown`;
          }
        },
      },
      {
        Header: 'Jumlah',
        id: 'r3',
        style: { textAlign: 'right' },
        width: 200,
        accessor: d => (
          d.totalUsePoint > 0 ? Number(d.totalUsePoint) : d.addPoint > 0 ? Number(d.addPoint) : 'Unknown'
        ),
        Cell: ({ row }) => (
          row.r1.totalUsePoint > 0 ?
            <span style={{ color: '#f44336' }}>- {formatter.format(row.r1.totalUsePoint)}</span> : row.r1.addPoint > 0 ?
            <span style={{ color: '#4caf50' }}>+ {formatter.format(row.r1.addPoint)}</span> :
            <span style={{ color: 'black' }}>{formatter.format(row.r1.addPoint)}</span>),
      },
    ];
    return (
      <React.Fragment>
        <section className='content-header'>
          <h1>
            User Profile
          </h1>
        </section>
        <section className='content'>
          <div className='row'>
            <div className='col-md-3'>
              <div className='box box-primary'>
                <div className='box-body box-profile'>
                  {this.state.userPhoto !== '' ?
                    <img className='profile-user-img img-responsive img-circle' src={this.state.userPhoto}
                         alt='user_picture' /> :
                    <div className='profile-user-img img-circle text-center' style={{ lineHeight: 138 + 'px' }}><span>No Photo</span>
                    </div>}
                  <h3 className='profile-username text-center'>{this.state.firstName + ' '
                  + this.state.lastName}</h3>
                  {/* <p className="text-muted text-center">{this.state.userRole}</p> */}
                </div>
              </div>
            </div>
            <div className='col-md-9'>
              <div className='nav-tabs-custom'>
                <ul className='nav nav-tabs'>
                  <li className='active'><a href={'#profile'} data-toggle='tab'>Profile</a></li>
                  {this.context.accessName.some(value => value === 'RoleManagement') ?
                    <li><a href={'#changepassword'} data-toggle='tab'>Change Password</a></li> : ""
                  }
                  <li><a href={'#hollacash'} data-toggle='tab'>Holla Cash</a></li>
                </ul>
                <div className='tab-content'>
                  <div className='active tab-pane' id='profile'>
                    <form className='form-horizontal' onSubmit={this.handleUpdateUser}>
                      <div className='form-group'>
                        <label htmlFor='inputName' className='col-sm-3 control-label'>Nama Depan</label>
                        <div className='col-sm-9'>
                          <input type='text' className='form-control' name='firstName'
                                 placeholder='Nama Depan' value={this.state.firstName}
                                 onChange={this.handleChange} />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label htmlFor='inputName' className='col-sm-3 control-label'>Nama Belakang</label>
                        <div className='col-sm-9'>
                          <input type='text' className='form-control' name='lastName'
                                 placeholder='Nama Belakang' value={this.state.lastName}
                                 onChange={this.handleChange} />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label htmlFor='inputEmail' className='col-sm-3 control-label'>Email</label>
                        <div className='col-sm-9'>
                          <input type='email' className='form-control' id='userEmail' name='userEmail'
                                 placeholder='Email' value={this.state.userEmail}
                                 onChange={this.handleChange} />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label htmlFor='inputPhone' className='col-sm-3 control-label'>Phone</label>
                        <div className='col-sm-9'>
                          <input type='text' maxLength='16' className='form-control' id='userPhone'
                                 name='userPhone' placeholder='+628123456789' value={this.state.userPhone}
                                 onChange={this.handleChange} />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label htmlFor='inputBirthday' className='col-sm-3 control-label'>Birthday</label>
                        <div className='col-sm-9'>
                          <div className='input-group date'>
                            <DatePicker value={this.state.userBirthday} onChange={this.handleDateChange}
                                        format='dd/MM/yyyy' />
                          </div>
                        </div>
                      </div>
                      <div className='form-group'>
                        <label htmlFor='inputSkills' className='col-sm-3 control-label'>Gender</label>
                        <div className='col-sm-9'>
                          <div className='form-group'>
                            <label className='mb-0 margin-r-5'>
                              <input type='radio' className='minimal' name='userGender' value='1'
                                     checked={this.state.userGender === '1'} onChange={this.handleChange} />
                              Pria
                            </label>
                            <label className='mb-0'>
                              <input type='radio' className='minimal' name='userGender' value='0'
                                     checked={this.state.userGender === '0'} onChange={this.handleChange} />
                              Wanita
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='form-group'>
                        <div className='col-sm-offset-2 col-sm-6'>
                          {this.state.errors.map(error => (
                            <p className='help-block' key={error}>Error: {error}</p>
                          ))}
                        </div>
                      </div>
                      <div className='form-group'>
                        <div className='col-sm-offset-3 col-sm-2'>
                          <button type='submit' className='btn btn-success'>Simpan</button>
                        </div>
                      </div>
                    </form>
                  </div>
                  {this.context.accessName.some(value => value === 'RoleManagement') ?
                    <div className='tab-pane' id='changepassword'>
                      <div className='row'>
                        <div className='col-sm-12'>
                          <form className='form-horizontal' onSubmit={this.handleChangePassword}>
                            <div className='form-group'>
                              <label htmlFor='inputName' className='col-sm-3 control-label'>Password Baru</label>
                              <div className='col-sm-9'>
                                <input type='password' className='form-control' name='inputNewPassword' required
                                       placeholder='Masukan Password Baru' value={this.state.inputNewPassword}
                                       onChange={this.handleChange} />
                              </div>
                            </div>
                            <div className='form-group'>
                              <div className='col-sm-offset-3 col-sm-2'>
                                <button type='submit' className='btn btn-success'>Ubah Password</button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div> : ""
                  }
                  <div className='tab-pane' id='hollacash'>
                    <div className='row'>
                      <div className='col-sm-12'>
                        <div className='form-control' style={{ marginBottom: 15 }}>
                          <div className='pull-right' style={{ marginLeft: 10 }}>Point :
                            <span style={{ color: '#ffa000' }}>{' ' + formatter.format(this.state.userPoint)}</span>
                          </div>
                          <div className='pull-right' style={{ marginRight: 10 }}>Cash :
                            <span style={{ color: '#ffa000' }}>{' ' + formatter.format(this.state.userCash)}</span>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-12'>
                        <div className='form-horizontal'>
                          <div className='form-group'>
                            <div className='col-sm-9'>
                              <label className='control-label pull-right'>Filter From Date</label>
                            </div>
                            <div className='col-sm-3'>
                              <div className='customDatePickerWidth'>
                                <DatePicker className='pull-right' value={this.state.datePoint}
                                            onChange={this.handleFilterDate}
                                            format='dd/MM/yyyy' />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-12'>
                        <ReactTable
                          columns={columns}
                          filtered={this.state.datePoint}
                          data={this.state.pointList}
                          onFetchData={this.getPointList}
                          loading={this.state.loading}
                          defaultPageSize={10} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withRouter(UserDetail);