import React, { Component } from 'react';
import withAuth from '../service/auth/withAuth';
import ProfileService from '../service/ProfileService';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import { errorMessage, successMessage } from '../utils/NotificationMessage';
import UserService from '../service/UserService';

// const Auth = new AuthService();
const Profile = new ProfileService();
const User = new UserService();

class Dashboard extends Component {

  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdateProfile = this.handleUpdateProfile.bind(this);
    this.handleUpdateProfilePicture = this.handleUpdateProfilePicture.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  state = {
    firstName: '',
    lastName: '',
    userPhoto: '',
    userEmail: '',
    userPhone: '',
    userBirthday: '',
    userGender: '',
    inputOldPassword: '',
    inputNewPassword: '',
    errors: [],
    selectedFile: '',
  };

  componentDidMount() {
    this.getMe();
  }

  getMe() {
    Profile.getMe().then(res => {
      if (res.result === 'OK') {
        const script = document.createElement('script');
        script.src = 'js/date.js';
        script.async = true;
        document.body.appendChild(script);
        document.body.removeChild(script);
        let dateString = '';
        let dt = new Date();
        if (res.data.birthday !== '') {
          dateString = res.data.birthday;
          const pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
          dt = new Date(dateString.replace(pattern, '$3-$2-$1'));
        }
        this.setState({
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          userEmail: res.data.email,
          userPhone: res.data.phone,
          userBirthday: dt,
          userPhoto: res.data.photo,
          userGender: '' + res.data.gender,
        });
        return;
      }
      errorMessage(res.error, 'Profil User');
    }).catch(err => errorMessage(err.message));
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleDateChange = date => {
    this.setState({
      userBirthday: date,
    });
  };

  onFileChange(e) {
    this.setState({
      selectedFile: e.target.files[0],
    });
  }

  validate(firstName, lastName, email, phone) {
    this.setState({
      errors: [],
    });
    const errors = [];
    firstName = firstName.trimLeft();
    if (firstName.length < 1) {
      errors.push('Nama depan tidak boleh kosong');
    }
    if (firstName.length < 3) {
      errors.push('Nama depan tidak boleh kurang dari 3 karakter');
    }
    if (!!lastName.length < 1) {
      errors.push('Nama belakang tidak boleh kosong');
    }
    if (email.length < 5) {
      errors.push('Email tidak boleh kurang dari 5 karakter');
    }
    if (email.split('').filter(x => x === '@').length !== 1) {
      errors.push('Format email salah, silahkan periksa lagi');
    }
    if (email.indexOf('.') === -1) {
      errors.push('Format email salah, silahkan periksa lagi');
    }
    if (phone.length < 9) {
      errors.push('Nomor telepon harus lebih dari 9 karakter');
    }
    return errors;
  }

  handleUpdateProfile(e) {
    e.preventDefault();
    const firstName = this.state.firstName;
    const lastName = this.state.lastName;
    const userEmail = this.state.userEmail;
    const userPhone = this.state.userPhone;

    const errors = this.validate(firstName, lastName, userEmail, userPhone);
    if (errors.length > 0) {
      this.setState({
        errors,
      });
      return;
    }
    const jsonData = {};
    jsonData['firstName'] = firstName;
    jsonData['lastName'] = lastName;
    jsonData['email'] = userEmail;
    jsonData['phone'] = userPhone;
    jsonData['birthday'] = moment(this.state.userBirthday).format('DD/MM/YYYY');
    jsonData['gender'] = this.state.userGender;
    Profile.updateProfile(jsonData).then(() => {
      successMessage('Update Profil Berhasil');
    }).catch(err => errorMessage(err.message));
  }

  handleUpdateProfilePicture(e) {
    e.preventDefault();
    if (this.state.selectedFile === '') {
      errorMessage('File tidak boleh kosong');
      return;
    }
    const data = new FormData();
    data.append('file', this.state.selectedFile);
    Profile.updateProfilePicture(data).then(res => {
      this.setState({
        userPhoto: res.user.photo,
      });
      successMessage('Update foto berhasil');
    }).catch(err => errorMessage(err.message));
  }

  handleChangePassword(e) {
    e.preventDefault();
    if (this.state.inputNewPassword.length > 0 && this.state.inputOldPassword.length > 0) {
      User.changePassword(this.state.inputOldPassword, this.state.inputNewPassword).then(() => {
        successMessage('Berhasil Mengubah Password');
        this.setState({ inputOldPassword: '', inputNewPassword: '' });
      }).catch(err => errorMessage(err.message));
    }
  }

  render() {
    return (
      <React.Fragment>
        <section className='content-header'>
          <h1>
            User Profile
          </h1>
        </section>
        <section className='content'>
          <div className='row'>
            <div className='col-md-3'>
              <div className='box box-primary'>
                <div className='box-body box-profile'>
                  <img className='profile-user-img img-responsive img-circle' src={this.state.userPhoto}
                       alt='user_picture' />
                  <h3 className='profile-username text-center'>{this.state.userName}</h3>
                  <p className='text-muted text-center'>{this.state.userRole}</p>
                  <form encType='multipart/form-data'>
                    <div className='form-group'>
                      <label htmlFor='exampleInputFile'>Ganti Foto Profile</label>
                      <input type='file' id='file' name='file' onChange={this.onFileChange} />
                    </div>
                    <div className='form-group'>
                      <div className='col-sm-offset-2 col-sm-10'>
                        <button onClick={this.handleUpdateProfilePicture} type='submit'
                                className='btn btn-success'>Simpan
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className='col-md-9'>
              <div className='nav-tabs-custom'>
                <ul className='nav nav-tabs'>
                  <li className='active'><a href={'#profile'} data-toggle='tab'>Profile</a></li>
                  <li><a href={'#changePassword'} data-toggle='tab'>Change Password</a></li>
                </ul>
                <div className='tab-content'>
                  <div className='active tab-pane' id='profile'>
                    <form className='form-horizontal' onSubmit={this.handleUpdateProfile}>
                      <div className='form-group'>
                        <label htmlFor='inputName' className='col-sm-3 control-label'>Nama
                          Depan</label>
                        <div className='col-sm-9'>
                          <input type='text' className='form-control' id='firstName'
                                 name='firstName' placeholder='Nama Depan'
                                 value={this.state.firstName} onChange={this.handleChange} />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label htmlFor='inputName' className='col-sm-3 control-label'>Nama
                          Belakang</label>
                        <div className='col-sm-9'>
                          <input type='text' className='form-control' id='firstName'
                                 name='lastName' placeholder='Nama Belakang'
                                 value={this.state.lastName} onChange={this.handleChange} />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label htmlFor='inputEmail'
                               className='col-sm-3 control-label'>Email</label>
                        <div className='col-sm-9'>
                          <input type='email' className='form-control' id='userEmail'
                                 name='userEmail' placeholder='Email' value={this.state.userEmail}
                                 onChange={this.handleChange} />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label htmlFor='inputPhone'
                               className='col-sm-3 control-label'>Phone</label>
                        <div className='col-sm-9'>
                          <input type='text' maxLength='16' className='form-control'
                                 id='userPhone' name='userPhone' placeholder='+628123456789'
                                 value={this.state.userPhone} onChange={this.handleChange} />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label htmlFor='inputBirthday'
                               className='col-sm-3 control-label'>Birthday</label>
                        <div className='col-sm-9'>
                          <div className='input-group date'>
                            <DatePicker value={this.state.userBirthday}
                                        onChange={this.handleDateChange} format='dd/MM/yyyy' />
                          </div>
                        </div>
                      </div>
                      <div className='form-group'>
                        <label htmlFor='inputSkills'
                               className='col-sm-3 control-label'>Gender</label>
                        <div className='col-sm-9'>
                          {/* radio */}
                          <div className='form-group'>
                            <label>
                              <input type='radio' className='minimal' name='userGender'
                                     value='1' checked={this.state.userGender === '1'}
                                     onChange={this.handleChange} />
                              Pria
                            </label>
                            <label>
                              <input type='radio' className='minimal' name='userGender'
                                     value='0' checked={this.state.userGender === '0'}
                                     onChange={this.handleChange} />
                              Wanita
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='form-group'>
                        <div className='col-sm-offset-2 col-sm-6'>
                          {this.state.errors.map(error => (
                            <p className='help-block' key={error}>Error: {error}</p>
                          ))}
                        </div>
                      </div>
                      <div className='form-group'>
                        <div className='col-sm-offset-3 col-sm-2'>
                          <button type='submit' className='btn btn-success'>Simpan</button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className='tab-pane' id='changePassword'>
                    <div className='row'>
                      <div className='col-sm-12'>
                        <form className='form-horizontal' onSubmit={this.handleChangePassword}>
                          <div className='form-group'>
                            <label htmlFor='inputName' className='col-sm-3 control-label'>Password Lama</label>
                            <div className='col-sm-9'>
                              <input type='password' className='form-control' name='inputOldPassword' required
                                     placeholder='Password Lama' value={this.state.inputOldPassword}
                                     onChange={this.handleChange} />
                            </div>
                          </div>
                          <div className='form-group'>
                            <label htmlFor='inputName' className='col-sm-3 control-label'>Password Baru</label>
                            <div className='col-sm-9'>
                              <input type='password' className='form-control' name='inputNewPassword' required
                                     placeholder='Masukan Password Baru' value={this.state.inputNewPassword}
                                     onChange={this.handleChange} />
                            </div>
                          </div>
                          <div className='form-group'>
                            <div className='col-sm-offset-3 col-sm-2'>
                              <button type='submit' className='btn btn-success'>Ubah Password</button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withAuth(Dashboard);