import React, { Component } from 'react';
import DeliveryService from '../../../service/DeliveryService';
import withAuth from '../../../service/auth/withAuth';
import { errorMessage, successMessage } from '../../../utils/NotificationMessage';

const DeliveryServices = new DeliveryService();

class DeliveryDetail extends Component {

  constructor() {
    super();
    this.onDelete = this.onDelete.bind(this);
    this.state = {
      deliveryId: 0,
      deliveryData: {},
      driverData: {},
      isHavePic: false,
      isLoading: true,
    };
  }

  componentDidMount() {
    const pathname = window.location.pathname;
    const split = pathname.split('/');
    this.setState({ deliveryId: split[2] });
    if (!DeliveryServices.isTokenExpired()) {
      this.getDeliveryDetail(split[2]);
    } else {
      DeliveryServices.reloadToken().then(() => {
        this.getDeliveryDetail(split[2]);
      }).catch(err => errorMessage(err.message));
    }
  }

  getDeliveryDetail(id) {
    this.setState({ isLoading: true });
    DeliveryServices.getDeliveryDetail(id).then(e => {
      this.setState({ deliveryData: e.data });
      if (e.data.driver_id !== 0) {
        this.getDriverDetail(e.data.driver_id);
      }
    }).catch(err => errorMessage(err.message)).finally(() => this.setState({ isLoading: false }));
  }

  getDriverDetail(driver_id) {
    this.setState({ isLoading: true });
    DeliveryServices.getDriverDetail(driver_id).then(e => {
      console.log(driver_id)
      this.setState({
        driverData: e.data,
        isHavePic: e.data.profile_pic.substr(e.data.profile_pic.length - 4) !== 'null',
      });
    }).catch(err => {
      errorMessage(err.message);
    }).finally(() => this.setState({ isLoading: false }));
  }

  onDelete() {
    if (!DeliveryServices.isTokenExpired()) {
      DeliveryServices.deleteDelivery(this.state.deliveryId).then(() => {
        successMessage('Delivery berhasil di hapus');
        this.props.history.push('/delivery');
      }).catch(err => errorMessage(err.message));
    } else {
      alert('Token Expired, silakan refresh page ini');
    }
  }

  render() {
    const { deliveryData, driverData } = this.state;

    if (!this.state.isLoading) {
      return (
        <React.Fragment>
          <section className='content-header'>
            <h1>
              Delivery Detail
            </h1>
          </section>
          <section className='content'>
            <div className='box box-primary'>
              <div className='box-body box-profile'>
                <section className='invoice'>
                  <div className='row'>
                    <div className='col-xs-12'>
                      <h2 className='page-header'>
                        <i className='fa fa-globe' /> Delivery {deliveryData.service}
                        <small className='pull-right'>Date: {deliveryData.create_date}</small>
                      </h2>
                    </div>
                  </div>

                  <div className='row invoice-info'>
                  <span className='fa-stack fa-lg icon-delete' title='Delete Delivery' style={{ zIndex: 1 }}
                        onClick={() => {
                          if (window.confirm('Are you sure want to delete this driver?')) this.onDelete();
                        }}>
                    <i className='fa fa-circle fa-stack-2x text-danger' />
                    <i className='fa fa-trash-o fa-stack-1x fa-inverse' />
                  </span>
                    <div className='col-sm-6 invoice-col'>
                      <ul className='timeline'>
                        <li>
                          <i className='fa fa-send bg-yellow' />
                          <div className='timeline-item'>
                            <h3 className='timeline-header'>Dari :</h3>
                            <div className='timeline-body'>
                              <strong>{deliveryData.sender_name}</strong><br />
                              {deliveryData.origin_detail}<br />
                              {deliveryData.origin_address}<br />
                              Phone: {deliveryData.sender_phone}
                            </div>
                          </div>
                        </li>
                        <li>
                          <i className='fa fa-user bg-green' />
                          <div className='timeline-item'>
                            <h3 className='timeline-header'>Untuk :</h3>
                            <div className='timeline-body'>
                              <strong>{deliveryData.receiver_name}</strong><br />
                              {deliveryData.destination_detail}<br />
                              {deliveryData.destination_address}<br />
                              Phone: {deliveryData.receiver_phone}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className='col-sm-6 invoice-col'>
                      <p style={{ marginBottom: 5 }}><b>Kode Transaksi : </b>{deliveryData.transaction_code}</p>
                      <p style={{ marginBottom: 5 }}><b>Layanan : </b>{deliveryData.service}</p>
                      <p style={{ marginBottom: 5 }}><b>Status Order : </b>{deliveryData.status}</p>
                      <p style={{ marginBottom: 5 }}><b>Deskripsi Item : </b>{deliveryData.description}</p>
                      <p style={{ marginBottom: 5 }}><b>Catatan : </b>{deliveryData.note}</p>
                      <b>Driver : </b>
                      {deliveryData.driver_id === 0 ? '-' :
                        <div className='info-box bg-aqua' style={{ marginTop: 10 }}>
                          <span className='info-box-icon'>
                            {this.state.isHavePic ?
                              <img className='img-responsive' style={{ height: '100%', width: '100%', objectFit: 'cover' }} src={driverData.profile_pic}
                                   alt='driver_pic' /> :
                              <i className='fa fa-user' />}
                          </span>
                          <div className='info-box-content'>
                            <span className='info-box-number'>{driverData.fullname}</span>
                            <span className='info-box-text'>{driverData.vehicle_number}</span>
                            <span className='info-box-text'>{driverData.phone}</span>
                          </div>
                        </div>}
                      {deliveryData.pod.length > 0 ?
                        <div className='row'>
                          <div className='col-sm-12'>
                            <p style={{ marginBottom: 5 }}><b>POD : </b></p>
                          </div>
                          {deliveryData.pod.map((val, index) => (
                            <div key={index} className='col-sm-6'>
                              <img className='img-responsive' src={val.file_url} alt={'pod_type_' + val.type} />
                            </div>
                          ))}
                        </div> :
                        ''}
                    </div>
                  </div>
                  <hr />
                  {/* <button type='button' className='btn btn-danger' onClick={() => { if (window.confirm('Are you sure you wish to delete this delivery?')) this.onDelete(item.id) } }>Delete</button> */}
                </section>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <section className='content-header'>
            <h1>
              Delivery Detail
            </h1>
          </section>
          <section className='content'>
            <div className='box box-primary'>
              <div className='box-body box-profile text-center'>
                <i className='fa fa-spinner fa-pulse fa-3x fa-fw' />
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    }
  }
}

export default withAuth(DeliveryDetail);