import React, { Component } from 'react';
import DeliveryService from '../../../service/DeliveryService';
import { Link, withRouter } from 'react-router-dom';
import ReactTable from 'react-table-6';
import GlobalSearchComponent from './GlobalSearchDriver';
import { errorMessage } from '../../../utils/NotificationMessage';

const Delivery = new DeliveryService();

class Driver extends Component {

  constructor() {
    super();
    this.fetchData = this.fetchData.bind(this);
    this.state = {
      driverList: [],
      filteredData: [],
      columns: [],
      searchInput: '',
      loading: true,
    };
  }

  componentDidMount() {
    this.fetchData.bind(this);
    this.getColumns();
  }

  fetchData(state) {
    this.setState({ loading: true });
    if (!Delivery.isTokenExpired()) {
      this.getDriverList();
    } else {
      Delivery.reloadToken().then(() => {
        this.getDriverList();
      }).catch(err => errorMessage(err.message));
    }
  }

  getDriverList(state) {
    Delivery.getDriverList().then(e => {
      this.setState({
        loading: false,
        driverList: e.data,
        filteredData: e.data,
      });
    }).catch(err => errorMessage(err.message));
  }

  handleSetData = driverList => {
    this.setState({ filteredData: driverList });
  };

  getColumns = () => {
    let columns = [
      {
        Header: 'Nama Driver',
        id: 'r',
        accessor: d => d,
        Cell: ({ row }) => (<Link to={{ pathname: '/driver/' + row.r.id }}>{row.r.fullname}</Link>),
        // id: 'r',
        // accessor: d => d,
        // Cell: ({ row }) => (
        //     <img src={row.r.photoUrlDefault} alt='photoDefault' style={{width : 60, height: 60}} />
        // ),
        // className: 'text-center',
        // sortable: false,
      },
      {
        Header: 'Email',
        accessor: 'email',
        // id: 'name',
        // accessor: d => d,
        // Cell: ({ row }) => (<Link to={{ pathname: '/product/'+row.name.id}}>{row.name.name}</Link>)
      },
      {
        Header: 'Nomor Telepon',
        accessor: 'phone',
        // id: 'price',
        // accessor: d => Number(d.price),
        // Cell : ({ row }) => formatter.format(row.price),
      },
      {
        Header: 'Lokasi',
        accessor: 'address',
      },
      {
        Header: 'Service',
        id: 'r',
        accessor: d => d,
        Cell: ({ row }) => row.r.services.length > 0 ? row.r.services[0].service : "Belum Ada Service",
      },
      {
        Header: 'Status',
        id: 'r',
        accessor: d => d,
        Cell: ({ row }) => row.r.is_active === 1 ? 'Active' : 'Pending',
        // id: 'r',
        // accessor: d => d,
        // Cell: ({ row }) => (
        //     <img src={row.r.photoUrlDefault} alt='photoDefault' style={{width : 60, height: 60}} />
        // ),
        // className: 'text-center',
        // sortable: false,
      },
    ];
    this.setState({ columns });
  };

  render() {
    let { filteredData, columns } = this.state;
    return (
      <React.Fragment>
        {/* Content Header (Page header) */}
        <section className='content-header'>
          <h1>
            Daftar Driver
          </h1>
          <ol className='breadcrumb'>
            <li className='active'>Data tables</li>
          </ol>
        </section>
        {/* Main content */}
        <section className='content'>
          <div className='row'>
            <div className='col-xs-12'>
              <div className='box'>
                <div className='box-body'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='input-group pull-right' style={{ width: 300, marginBottom: 10 }}>
                        <GlobalSearchComponent
                          data={this.state.driverList}
                          handleSetData={this.handleSetData}
                        />
                      </div>
                      {/*<div className='input-group pull-left'>*/}
                      {/*  <button type='button' className='btn btn-primary' onClick={() => {*/}
                      {/*    this.props.history.push('/createdriver');*/}
                      {/*  }}>*/}
                      {/*    <i className='fa fa-plus'> Tambah Driver</i>*/}
                      {/*  </button>*/}
                      {/*</div>*/}
                    </div>
                  </div>
                  <ReactTable
                    columns={columns}
                    data={filteredData}
                    sortable={false}
                    filtered={this.state.filter}
                    loading={this.state.loading}
                    onFetchData={this.fetchData}
                    defaultPageSize={10} />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /.content */}
      </React.Fragment>
    );
  }
}

export default withRouter(Driver);