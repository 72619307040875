import { TERMS } from '../utils/APIUtil';
import axios from 'axios';
import AuthService from './auth/AuthService';

const Auth = new AuthService();
export default class PromoService {

  async getList() {
    const response = await axios.get(TERMS, { headers: { Authorization: 'Bearer ' + Auth.getToken() } });
    const res = response.data;
    if (response.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject(res.error);
    }
  }

  async getById(id) {
    const response = await axios.get(TERMS + '/' + id, { headers: { Authorization: 'Bearer ' + Auth.getToken() } });
    console.log(response);
    const res = response.data;
    if (response.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject(res.error);
    }
  }

  async edit(formData) {
    const response = await axios.put(TERMS + '/', formData, { headers: { Authorization: 'Bearer ' + Auth.getToken() } });
    const res = response.data;
    console.log(response);
    if (res.result === 'OK') {
      return Promise.resolve(res);
    } else {
      return Promise.reject(res.error);
    }
  }

  async add(formData) {
    const response = await axios.post(TERMS, formData, { headers: { Authorization: 'Bearer ' + Auth.getToken() } });
    const res = response.data;
    console.log(response);
    if (res.result === 'OK') {
      return Promise.resolve(res);
    } else {
      return Promise.reject(res.error);
    }
  }

  async deletee(id) {
    const response = await axios.delete(TERMS, {
      data: { id: id },
      headers: { Authorization: 'Bearer ' + Auth.getToken() },
    });
    const res = response.data;
    if (response.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject(res.error);
    }
  }

}    