import React, { useEffect, useState } from 'react';
import RoleService from '../../service/RoleService';
import { RoleAccessDeliveryList, RoleAccessList } from '../../utils/RoleUtil';
import { errorMessage, successMessage } from '../../utils/NotificationMessage';
import { useHistory } from 'react-router-dom';

const RoleGroupManagement = () => {
  const history = useHistory();
  const roleService = new RoleService();
  const [isLoading, setIsLoading] = useState(false);
  const [roleGroupName, setRoleGroupName] = useState('');
  const [roleGroupList, setRoleGroupList] = useState([]);
  const [selectedAccessName, setSelectedAccessName] = useState([]);
  const [selectedRoleGroup, setSelectedRoleGroup] = useState({ id: 0, name: '', roleAccessList: [] });

  useEffect(() => {
    getListRoleGroup()
  }, []);

  function getListRoleGroup() {
    setIsLoading(true);
    roleService.getRoleGroupList().then(value => {
      setRoleGroupList(value.roleGroups);
      if (value.roleGroups.length > 0) {
        setSelectedRoleGroup(value.roleGroups[0]);
        setSelectedAccessName(value.roleGroups[0].roleAccessList);
      }
    }).catch(err => errorMessage(err.message)).finally(() => setIsLoading(false));
  }

  function updateAccessRole(e) {
    e.preventDefault();
    setIsLoading(true)
    const roleAccessList = [];
    for (let i = 0; i < selectedAccessName.length; i++) {
      roleAccessList.push({ accessName: selectedAccessName[i].accessName });
    }
    const data = { roleGroupId: selectedRoleGroup.id, roleAccessList };
    roleService.updateAccessRole(data).then(() => {
      successMessage('Berhasil update role access ' + selectedRoleGroup.name, 'Success');
    }).catch(err => errorMessage(err.message)).finally(() => history.go(0))
  }

  function handleCreateRoleGroup(e) {
    e.preventDefault();
    setIsLoading(true)
    roleService.addRoleGroup(roleGroupName).then(() => {
      getListRoleGroup()
      setRoleGroupName('')
      successMessage('Berhasil menambah role group', 'Success');
    }).catch(err => errorMessage(err.message)).finally(() => setIsLoading(false))
  }

  function onChangeSelect(e) {
    setSelectedRoleGroup(roleGroupList[e.target.value]);
    setSelectedAccessName(roleGroupList[e.target.value].roleAccessList);
  }

  function onChangeChecked(e, value) {
    if (e.target.checked) {
      if (!selectedAccessName.some(e => e.accessName.toLowerCase() === value.toLowerCase())) {
        setSelectedAccessName((prev) => [...prev, { id: 0, accessName: value }]);
        return;
      }
    }
    setSelectedAccessName(selectedAccessName.filter((v) => v.accessName.toLowerCase() !== value.toLowerCase()));
  }

  return (
    <React.Fragment>
      <section className='content-header'>
        <h1>
          Role Group Management
        </h1>
      </section>
      {!isLoading ?
        <section className='content'>
          <div className='row'>
            <div className='col-xs-12'>
              <div className='box box-primary'>
                <div className='box-body'>
                  <div style={{ marginBottom: 10, marginTop: 20 }}>
                    <div className='category-group list-group-item'>
                      <b>Create Role Group</b>
                      <form className='form-horizontal' onSubmit={handleCreateRoleGroup}>
                        <div className='form-group'>
                          <div className='col-sm-12' style={{marginTop: 10}}>
                            <input type='text' className='form-control' name='roleGroupName'
                                   value={roleGroupName} placeholder='Role Group Name'
                                   onChange={(e) => setRoleGroupName(e.target.value)} required />
                          </div>
                        </div>
                        <div className='form-group text-right'>
                          <div className='col-sm-12'>
                            <button type='submit' className='btn btn-primary'>
                              <i className='fa fa-plus'> Create</i>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  {roleGroupList.length > 0 ?
                    <div style={{ marginBottom: 10, marginTop: 20 }}>
                      <div className='category-group list-group-item'>
                        <b>Edit Role Group</b>
                        <div style={{ marginBottom: 10, marginTop: 20 }}>
                          <form className='form-horizontal' onSubmit={updateAccessRole}>
                            <select name='typeName' className='form-control' onChange={onChangeSelect}>
                              {roleGroupList.map((item, index) => (
                                <option key={item.id} value={index}>{item.name}</option>
                              ))}
                            </select>
                            <div className='row'>
                              {RoleAccessList.map((value, index) =>
                                <div className='col-md-4' key={index} style={{ marginTop: 16 }}>
                                  <b><i className='fa fa-th' /><span>{` ${value.nameCategory}`}</span></b>
                                  <div style={{ paddingLeft: 16 }}>
                                    {value.categoryList.map((value, index) =>
                                      <div className='checkbox' key={index}>
                                        <label>
                                          <input type='checkbox' onChange={(e) => onChangeChecked(e, value)}
                                                 checked={selectedAccessName.some(e => e.accessName.toLowerCase() === value.toLowerCase())} />
                                          {value}
                                        </label>
                                      </div>,
                                    )}
                                  </div>
                                </div>,
                              )}
                            </div>
                            <hr style={{marginBottom: 0}} />
                            <div className='row'>
                              {RoleAccessDeliveryList.map((value, index) =>
                                <div className='col-md-4' key={index} style={{ marginTop: 16 }}>
                                  <b><i className='fa fa-th' /><span>{` ${value.nameCategory}`}</span></b>
                                  <div style={{ paddingLeft: 16 }}>
                                    {value.categoryList.map((value, index) =>
                                      <div className='checkbox' key={index}>
                                        <label>
                                          <input type='checkbox' onChange={(e) => onChangeChecked(e, value)}
                                                 checked={selectedAccessName.some(e => e.accessName.toLowerCase() === value.toLowerCase())} />
                                          {value}
                                        </label>
                                      </div>,
                                    )}
                                  </div>
                                </div>,
                              )}
                            </div>
                            <div className='form-group text-right'>
                              <div className='col-sm-12'>
                                <button type='submit' className='btn btn-primary'>
                                  Update Access
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div> : ""
                  }
                </div>
              </div>
            </div>
          </div>
        </section> :
        <section className='content'>
          <div className='box box-primary'>
            <div className='box-body box-profile text-center'>
              <i className='fa fa-spinner fa-pulse fa-3x fa-fw' />
            </div>
          </div>
        </section>
      }
    </React.Fragment>
  );
};

export default RoleGroupManagement;