import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

import Header from './components/Header';
import Menu from './components/Menu';
import Footer from './components/Footer';
import Profile from './components/Profile';
import AuthService from './service/auth/AuthService';
import Dashboard from './components/Dashboard';
import User from './components/User/User';
import UserDetail from './components/User/UserDetail';
import Seller from './components/Seller/Seller';
import SellerDetail from './components/Seller/SellerDetail';
import Product from './components/Product/Product';
import ProductDetail from './components/Product/ProductDetail';
import Order from './components/Order/Order';
import OrderDetail from './components/Order/OrderDetail';
import Category from './components/Category/Category';
import CategoryDetail from './components/Category/CategoryDetail';
import Driver from './components/Delivery/Driver/Driver';
import DriverDetail from './components/Delivery/Driver/DriverDetail';
import Delivery from './components/Delivery/Delivery/Delivery';
import DeliveryAdd from './components/Delivery/Delivery/DeliveryAdd';
import DeliveryDetail from './components/Delivery/Delivery/DeliveryDetail';
import Services from './components/Delivery/Services/Services';
import ServicesDetail from './components/Delivery/Services/ServicesDetail';
import Tracking from './components/Delivery/Tracking/Tracking';
import TrackingDetail from './components/Delivery/Tracking/TrackingDetail';
import Promo from './components/Promo/Promo';
import PromoWV from './components/Promo/PromoWebView';
import TermCondition from './components/Termcondition/Termcondition';
import Cashout from './components/Cashout/Cashout';

import 'react-notifications/lib/notifications.css';
import 'react-table-6/react-table.css';
import NotFound from './components/NotFound';
import RoleGroupManagement from './components/Roles/RoleGroupManagement';
import { useRoleContext } from './contexts/RoleContext';
import RoleService from './service/RoleService';
import NotAllowed from './components/NotAllowed';
import RoleUserManagement from './components/Roles/RoleUserManagement';
import CreateAdminUser from './components/User/CreateAdminUser';

const Auth = new AuthService();
const roleService = new RoleService();
const App = () => {
  const [isLoading, setLoading] = useState(true);
  const roleContext = useRoleContext();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (Auth.loggedIn()) {
      setLoading(true)
      roleService.getMyAccessRole().then(value => {
        const accessName = [];
        for (let i = 0; i < value.roleGroup.roleAccessList.length; i++) {
          accessName.push(value.roleGroup.roleAccessList[i].accessName);
        }
        roleContext.updateAccessName(accessName);
        roleContext.updateGroupName(value.roleGroup.name);
      }).catch((e) => console.error(e)).finally(() => setLoading(false));
    } else {
      const pathName = location.pathname;
      if (pathName !== '/reset-password' && pathName !== '/forgetPassword' && pathName !== '/login') {
        history.replace('/login')
      }
    }
  }, []);

  function handleLogout() {
    Auth.logout();
    history.replace('/login');
  }

  return (
    <React.Fragment>
      {Auth.loggedIn() ?
        <div className='wrapper'>
          <Header handleLogout={handleLogout} />
          <Menu />
          <div className='content-wrapper'>
            {!isLoading ? roleContext.accessName.length > 0 ?
              <Switch>
                <Route exact path='/' component={Dashboard} />
                <Route exact path='/profile' component={Profile} />
                <Route exact path='/user'>
                  {roleContext.accessName.some(value => value === 'UserList') ?
                    <User /> : <NotAllowed />
                  }
                </Route>
                <Route path='/user/:userId'>
                  {roleContext.accessName.some(value => value === 'UserManagement') ?
                    <UserDetail /> : <NotAllowed />
                  }
                </Route>
                <Route exact path='/seller'>
                  {roleContext.accessName.some(value => value === 'SellerList') ?
                    <Seller /> : <NotAllowed />
                  }
                </Route>
                <Route path='/seller/:sellerId'>
                  {roleContext.accessName.some(value => value === 'SellerManagement') ?
                    <SellerDetail /> : <NotAllowed />
                  }
                </Route>
                <Route exact path='/product'>
                  {roleContext.accessName.some(value => value === 'ProductList') ?
                    <Product /> : <NotAllowed />
                  }
                </Route>
                <Route path='/product/:productId'>
                  {roleContext.accessName.some(value => value === 'ProductManagement') ?
                    <ProductDetail /> : <NotAllowed />
                  }
                </Route>
                <Route exact path='/order'>
                  {roleContext.accessName.some(value => value === 'OrderList') ?
                    <Order /> : <NotAllowed />
                  }
                </Route>
                <Route path='/order/:orderId'>
                  {roleContext.accessName.some(value => value === 'OrderManagement') ?
                    <OrderDetail /> : <NotAllowed />
                  }
                </Route>
                <Route exact path='/category'>
                  {roleContext.accessName.some(value => value === 'CategoryList') ?
                    <Category /> : <NotAllowed />
                  }
                </Route>
                <Route path='/category/:categoryId'>
                  {roleContext.accessName.some(value => value === 'CategoryManagement') ?
                    <CategoryDetail /> : <NotAllowed />
                  }
                </Route>
                <Route exact path='/promo'>
                  {roleContext.accessName.some(value => value === 'PromoManagement') ?
                    <Promo /> : <NotAllowed />
                  }
                </Route>
                {/*<Route path='/promo/:promoId' component={PromoDetail}>*/}
                {/*  {roleContext.accessName.some(value => value === 'CategoryManagement') ?*/}
                {/*    <CategoryDetail /> : <NotAllowed />*/}
                {/*  }*/}
                {/*</Route>*/}
                <Route exact path='/promowv'>
                  {roleContext.accessName.some(value => value === 'PromoContentManagement') ?
                    <PromoWV /> : <NotAllowed />
                  }
                </Route>
                <Route exact path='/termcondition'>
                  {roleContext.accessName.some(value => value === 'TermConditionManagement') ?
                    <TermCondition /> : <NotAllowed />
                  }
                </Route>
                <Route exact path='/cashout'>
                  {roleContext.accessName.some(value => value === 'CashOutManagement') ?
                    <Cashout /> : <NotAllowed />
                  }
                </Route>
                <Route exact path='/rolegroup'>
                  {roleContext.accessName.some(value => value === 'RoleManagement') ?
                    <RoleGroupManagement /> : <NotAllowed />
                  }
                </Route>
                <Route exact path='/roleuser'>
                  {roleContext.accessName.some(value => value === 'RoleManagement') ?
                    <RoleUserManagement /> : <NotAllowed />
                  }
                </Route>
                <Route exact path='/createadmin'>
                  {roleContext.accessName.some(value => value === 'RoleManagement') ?
                    <CreateAdminUser /> : <NotAllowed />
                  }
                </Route>

                <Route exact path='/driver'>
                  {roleContext.accessName.some(value => value === 'HollaManDriverList') ?
                    <Driver /> : <NotAllowed />
                  }
                </Route>
                <Route path='/driver/:driverId'>
                  {roleContext.accessName.some(value => value === 'HollaManDriverManagement') ?
                    <DriverDetail /> : <NotAllowed />
                  }
                </Route>
                <Route exact path='/delivery'>
                  {roleContext.accessName.some(value => value === 'HollaManDeliveryList') ?
                    <Delivery /> : <NotAllowed />
                  }
                </Route>
                <Route exact path='/createdelivery'>
                  {roleContext.accessName.some(value => value === 'HollaManDeliveryManagement') ?
                    <DeliveryAdd /> : <NotAllowed />
                  }
                </Route>
                <Route path='/delivery/:deliveryId'>
                  {roleContext.accessName.some(value => value === 'HollaManDeliveryManagement') ?
                    <DeliveryDetail /> : <NotAllowed />
                  }
                </Route>
                <Route exact path='/services'>
                  {roleContext.accessName.some(value => value === 'HollaManServiceList') ?
                    <Services /> : <NotAllowed />
                  }
                </Route>
                <Route exact path='/services/:serviceId'>
                  {roleContext.accessName.some(value => value === 'HollaManServiceManagement') ?
                    <ServicesDetail /> : <NotAllowed />
                  }
                </Route>
                <Route exact path='/tracking'>
                  {roleContext.accessName.some(value => value === 'HollaManTrackingList') ?
                    <Tracking /> : <NotAllowed />
                  }
                </Route>
                <Route path='/tracking/:trackingId'>
                  {roleContext.accessName.some(value => value === 'HollaManTrackingDetail') ?
                    <TrackingDetail /> : <NotAllowed />
                  }
                </Route>

                <Route component={NotFound} />
              </Switch> : <NotAllowed /> :
              <section className='content'>
                <div className='box box-primary'>
                  <div className='box-body box-profile text-center'>
                    <i className='fa fa-spinner fa-pulse fa-3x fa-fw' />
                  </div>
                </div>
              </section>
            }
          </div>
          <Footer />
        </div> : <div />
      }
    </React.Fragment>
  );
};

export default App;
