import decode from 'jwt-decode';
import { AUTH, DELIVERY_TOKEN, LOGIN_URL, ROLEGROUP } from '../../utils/APIUtil';
import Axios from 'axios';
import axios from 'axios';

export default class AuthService {
  constructor(domain) {
    this.domain = domain || LOGIN_URL;
    this.fetch = this.fetch.bind(this);
    this.login = this.login.bind(this);
  }

  async login(emailOrPhone, password) {
    // Get a token
    const res = await this.fetch(`${this.domain}`, {
      method: 'POST',
      body: JSON.stringify({
        'emailOrPhone': emailOrPhone,
        'password': password,
      }),
    });
    if (res.result === 'NOK') {
      return Promise.reject(res.error);
    }
    let admin = false;
    for (let i = 0; i < res.user.roles.length; i++) {
      if (res.user.roles[i].name === 'ROLE_ADMIN') {
        admin = 'Admin';
      }
    }
    if (admin === false) {
      return Promise.reject('Anda tidak diijinkan mengakses halaman Admin');
    }
    this.setToken(res);
    return Promise.resolve(res);
  }

  async loginDelivery(token) {
    const response = await Axios.get(DELIVERY_TOKEN, { headers: { Authorization: 'Bearer ' + token } });
    const res = response.data;
    if (res.result === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject(response);
    }
  }

  async sendLinkResetPassword(email) {
    const response = await axios.post(AUTH + '/admin/forgot-password', { email });
    const res = response.data;
    if (response.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject(res.error);
    }
  }

  async resetPassword(email, hashString, newPassword) {
    const response = await axios.post(AUTH + '/admin/forgot-password/apply', { email, hashString, newPassword });
    const res = response.data;
    if (response.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject(res.error);
    }
  }


  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken();
    return !!token && !this.isTokenExpired(token); // handwaiving here
  }

  isTokenExpired(token) {
    try {
      const decoded = decode(token);
      return decoded.exp < Date.now() / 1000;
    } catch (err) {
      return false;
    }
  }

  setToken(res) {
    localStorage.setItem('boga.access_token', res.authentication.accessToken);
    const userData = { id: res.user.id, fullName: res.user.completeName, profilePict: res.user.photo };
    this.setUserData(JSON.stringify(userData));
  }

  logout() {
    // Clear user token and this data from localStorage
    localStorage.removeItem('boga.access_token');
    localStorage.removeItem('boga.access_delivery');
    localStorage.removeItem('boga.userData');
  }

  fetch(url, options) {
    // performs api calls sending the required authentication headers
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };

    if (this.loggedIn()) {
      headers['Authorization'] = 'Bearer ' + this.getToken();
    }

    return fetch(url, {
      headers,
      ...options,
    })
      .then(this._checkStatus)
      .then(response => response.json());
  }

  _checkStatus(response) {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      const error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  }

  setUserData(userData) {
    localStorage.setItem('boga.userData', userData);
  }

  setTokenDelivery(token) {
    localStorage.setItem('boga.access_delivery', token);
  }

  getToken() {
    return localStorage.getItem('boga.access_token');
  }

  getTokenDelivery() {
    return localStorage.getItem('boga.access_delivery');
  }

  getUserData() {
    return JSON.parse(localStorage.getItem('boga.userData'));
  }

  getId() {
    const userData = this.getUserData();
    if (userData != null) {
      return userData.id;
    }
    return 0;
  }


  getName() {
    const userData = this.getUserData();
    if (userData != null) {
      return userData.fullName;
    }
    return '';
  }

  getUserPhoto() {
    const userData = this.getUserData();
    if (userData != null) {
      return userData.profilePict;
    }
    return '';
  }
}