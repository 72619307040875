import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AuthService from '../service/auth/AuthService';
import { errorMessage } from '../utils/NotificationMessage';

const ResetPassword = () => {
  const history = useHistory();
  const authService = new AuthService();
  const [email, setEmail] = useState('');
  const [hashString, setHashString] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    checkParams();
  });

  function checkParams() {
    const searchObject = searchToObject();
    if (searchObject.hasOwnProperty('email') && searchObject.hasOwnProperty('hashString')) {
      setEmail(searchObject.email);
      setHashString(searchObject.hashString);
      return;
    }
    history.replace('/login');
  }

  function searchToObject() {
    let pairs = window.location.search.substring(1).split('&'), obj = {}, pair;

    for (let i = 0; i < pairs.length; i++) {
      if (pairs[i] === '') continue;

      pair = pairs[i].split('=');
      obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }

    return obj;
  }

  function resetPassword(e) {
    e.preventDefault();
    setLoading(true);
    authService.resetPassword(email, hashString, newPassword).then(() => {
      alert('Password berhasil diubah, silakan login kembali');
      history.replace('/login');
    }).catch(err => {
      errorMessage(err.message);
      setLoading(false);
    });
  }

  return (
    <React.Fragment>
      {!authService.loggedIn() ?
        <React.Fragment>
          <div className='login-page' style={{ display: 'flex', placeItems: 'center', height: '100vh' }}>
            <div className='login-box'>
              <div className='login-logo'>
                <b>Boga</b> Dashboard
              </div>
              {/* /.login-logo */}
              <div className='login-box-body'>
                <p className='login-box-msg'>Reset Password</p>
                <form onSubmit={resetPassword}>
                  <div className='form-group has-feedback'>
                    <input type='email' className='form-control' placeholder='Email' defaultValue={email} disabled />
                    <span className='glyphicon glyphicon-envelope form-control-feedback' />
                  </div>
                  <div className='form-group has-feedback'>
                    <input type='password' className='form-control' placeholder='Masukan Password Baru'
                           value={newPassword}
                           onChange={(e) => setNewPassword(e.target.value)} required />
                    <span className='glyphicon glyphicon-lock form-control-feedback' />
                  </div>
                  <div className='row'>
                    {/* /.col */}
                    <div className='col-xs-12'>
                      <button type='submit' className='btn btn-primary btn-block btn-flat' disabled={isLoading}>
                        Ubah Password
                      </button>
                    </div>
                    {/* /.col */}
                  </div>
                </form>
              </div>
              {/* /.login-box-body */}
              <p>&nbsp;</p>
            </div>
          </div>
        </React.Fragment> : <div />
      }
    </React.Fragment>
  );
};

export default ResetPassword;