import React, { Component } from 'react';
import DeliveryService from '../../../service/DeliveryService';
import withAuth from '../../../service/auth/withAuth';
import { GoogleApiWrapper } from 'google-maps-react';
import { errorMessage, successMessage, warningMessage } from '../../../utils/NotificationMessage';

const DeliveryServices = new DeliveryService();

class DeliveryAdd extends Component {
  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.handleAddDelivery = this.handleAddDelivery.bind(this);
    this.state = {
      codetransaction: '',
      senderName: '',
      senderPhone: '',
      receiverName: '',
      receiverPhone: '',
      description: '',
      note: '',
      destination_address: '',
      destination_detail: '',
      destination_lat: '',
      destination_lng: '',
      origin_address: '',
      origin_detail: '',
      origin_lat: '',
      origin_lng: '',
      price: 0,
      service_id: 0,
      user_id: 1,
      serviceList: [],
      isLoading: true,
      isCalculate: false,
      fields: {
        location: {
          lat: 0,
          lng: 0,
        },
      },
    };
  }

  componentDidMount() {
    if (!DeliveryServices.isTokenExpired()) {
      this.getServiceList();
    } else {
      DeliveryServices.reloadToken().then(() => {
        this.getServiceList();
      }).catch(err => errorMessage(err.message));
    }
  }

  getServiceList() {
    this.setState({ isLoading: true });
    DeliveryServices.getServicesList().then(e => {
      this.setState({
        serviceList: e.data.reverse(),
        service_id: Number(e.data.reverse()[0].id),
        isLoading: false,
      });
    }).catch(err => errorMessage(err.message));
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });

  }

  handleAddDelivery(e) {
    e.preventDefault();
    const jsonData = {};
    this.setState({ isLoading: true });
    jsonData['transaction_code'] = this.state.codetransaction;
    jsonData['sender_name'] = this.state.senderName;
    jsonData['sender_phone'] = this.state.senderPhone;
    jsonData['destination_address'] = this.state.destination_address;
    jsonData['destination_detail'] = this.state.destination_detail;
    jsonData['destination_lat'] = this.state.destination_lat;
    jsonData['destination_lng'] = this.state.destination_lng;
    jsonData['receiver_name'] = this.state.receiverName;
    jsonData['receiver_phone'] = this.state.receiverPhone;
    jsonData['origin_address'] = this.state.origin_address;
    jsonData['origin_detail'] = this.state.origin_detail;
    jsonData['origin_lat'] = this.state.origin_lat;
    jsonData['origin_lng'] = this.state.origin_lng;
    jsonData['description'] = this.state.description;
    jsonData['delivery_time'] = '2019-09-06 09:00:00';
    jsonData['service_id'] = Number(this.state.service_id);
    jsonData['note'] = '';
    jsonData['price'] = this.state.price;
    jsonData['user_id'] = this.state.user_id;
    console.log(JSON.stringify(jsonData));
    if (!DeliveryServices.isTokenExpired()) {
      this.postDelivery(jsonData);
    } else {
      DeliveryServices.reloadToken().then(() => {
        this.postDelivery(jsonData);
      }).catch(err => errorMessage(err.message));
    }
  }

  postDelivery(jsonData) {
    DeliveryServices.addDelivery(jsonData).then(e => {
      if (e.status === 200) {
        this.setState({
          codetransaction: '',
          senderName: '',
          senderPhone: '',
          receiverName: '',
          receiverPhone: '',
          description: '',
          note: '',
          destination_address: '',
          destination_detail: '',
          destination_lat: '',
          destination_lng: '',
          origin_address: '',
          origin_detail: '',
          origin_lat: '',
          origin_lng: '',
          price: 0,
        });
        successMessage('Delivery berhasil ditambahkan');
        return;
      }
      warningMessage(e.message);
    }).catch(err => errorMessage(err.message)).finally(() => {
      this.setState({ isLoading: false });
    });
  }

  checkboolean(serviceO, originLatO, originLngO, destinationLatO, destinationLngO) {
    const { service_id, origin_lat, origin_lng, destination_lat, destination_lng } = this.state;
    if (service_id > 0 && origin_lat.length > 0 && origin_lng.length > 0 && destination_lat.length > 0 && destination_lng.length > 0) {
      const isService = service_id !== serviceO;
      const isOriginLat = origin_lat !== originLatO;
      const isOriginLng = origin_lng !== originLngO;
      const isDestinationLat = destination_lat !== destinationLatO;
      const isDestinationLng = destination_lng !== destinationLngO;
      return isService || isOriginLat || isOriginLng || isDestinationLat || isDestinationLng;
    } else {
      return false;
    }
  }

  calculatePrice() {
    this.setState({ isCalculate: true });
    const jsonData = {};
    jsonData['origin_lat'] = Number(this.state.origin_lat);
    jsonData['origin_lng'] = Number(this.state.origin_lng);
    jsonData['destination_lat'] = Number(this.state.destination_lat);
    jsonData['destination_lng'] = Number(this.state.destination_lng);
    jsonData['service_id'] = this.state.service_id.toString();
    DeliveryServices.calculate_price(jsonData).then(e => {
      this.setState({
        price: e.price,
        isCalculate: false,
      });
    }).catch(err => errorMessage(err.message));
  }

  componentDidUpdate(_prevProps, prevState) {
    if (this.checkboolean(prevState.service_id, prevState.origin_lat, prevState.origin_lng, prevState.destination_lat, prevState.destination_lng)) {
      this.calculatePrice();
    }
  }

  // addmarker(location, map){
  //     console.log(location)
  //     console.log(map)
  //     this.setState({
  //         fields : {
  //             location
  //         }
  //     })
  //     map.panTo(location);
  // }

  render() {
    const { service_id, origin_lat, origin_lng, destination_lat, destination_lng } = this.state;
    const isPrice = service_id > 0 && origin_lat.length > 0 && origin_lng.length > 0 && destination_lat.length > 0 && destination_lng.length > 0;
    const formatter = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    });

    if (!this.state.isLoading) {
      return (
        <React.Fragment>
          <section className='content-header'>
            <h1>
              Create Delivery
            </h1>
          </section>
          <section className='content'>
            <div className='row'>
              <div className='col-xs-12'>
                <div className='box box-primary'>
                  <div className='box-body box-profile'>
                    <form className='form-horizontal' onSubmit={this.handleAddDelivery}>
                      <div className='form-group'>
                        <label className='col-sm-3 control-label'>Kode Transaksi</label>
                        <div className='col-sm-9'>
                          <input type='text' className='form-control' name='codetransaction'
                                 placeholder='Kode Transaksi' value={this.state.codetransaction}
                                 onChange={this.handleChange} required />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label className='col-sm-3 control-label'>Pengirim</label>
                        <div className='col-sm-5'>
                          <input type='text' className='form-control' name='senderName'
                                 placeholder='Nama Pengirim' value={this.state.senderName}
                                 onChange={this.handleChange} required />
                        </div>
                        <div className='col-sm-4'>
                          <input type='tel' className='form-control' name='senderPhone'
                                 placeholder='No. Pengirim' value={this.state.senderPhone}
                                 onChange={this.handleChange} required />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label className='col-sm-3 control-label'>Penerima</label>
                        <div className='col-sm-5'>
                          <input type='text' className='form-control' name='receiverName'
                                 placeholder='Nama Penerima' value={this.state.receiverName}
                                 onChange={this.handleChange} required />
                        </div>
                        <div className='col-sm-4'>
                          <input type='tel' className='form-control' name='receiverPhone'
                                 placeholder='No. Penerima' value={this.state.receiverPhone}
                                 onChange={this.handleChange} required />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label className='col-sm-3 control-label'>Deskripsi Barang</label>
                        <div className='col-sm-9'>
                                                    <textarea className='form-control' name='description' rows='2'
                                                              cols='50'
                                                              placeholder='Deskripsi Barang'
                                                              value={this.state.description}
                                                              onChange={this.handleChange} required />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label className='col-sm-3 control-label'>Detail Alamat Pengirim</label>
                        <div className='col-sm-9'>
                          <input type='text' className='form-control' name='origin_detail'
                                 placeholder='Detail Alamat Pengirim Barang' value={this.state.origin_detail}
                                 onChange={this.handleChange} required />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label className='col-sm-3 control-label'>Alamat Lengkap Pengirim</label>
                        <div className='col-sm-9'>
                                                    <textarea className='form-control' name='origin_address' rows='3'
                                                              cols='50'
                                                              placeholder='Alamat Pengirim Barang'
                                                              value={this.state.origin_address}
                                                              onChange={this.handleChange} required />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label className='col-sm-3 control-label'>LatLng Pengirim</label>
                        {/* <div className="col-sm-9">
                                                    <a href='/#' data-toggle="modal" data-target="#myModal" onClick={(e) => {e.preventDefault()}}>
                                                        {this.state.origin_lat.length > 1 ? 
                                                        <input type="text" style={{cursor : 'pointer'}} className="form-control" name="origin_lat" placeholder={this.state.origin_lat+', '+this.state.origin_lng} value='' disabled/> :
                                                        <input type="text" style={{cursor : 'pointer'}} className="form-control" placeholder="Select on Map" value='' disabled/> }
                                                    </a>
                                                </div> */}
                        <div className='col-sm-4'>
                          <input type='text' className='form-control' name='origin_lat'
                                 placeholder='Latitude Pengirim' value={this.state.origin_lat}
                                 onChange={this.handleChange} required />
                        </div>
                        <div className='col-sm-4'>
                          <input type='text' className='form-control' name='origin_lng'
                                 placeholder='Longitude Pengirim' value={this.state.origin_lng}
                                 onChange={this.handleChange} required />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label className='col-sm-3 control-label'>Detail Alamat Penerima</label>
                        <div className='col-sm-9'>
                          <input type='text' className='form-control' name='destination_detail'
                                 placeholder='Detail Alamat Penerima Barang' value={this.state.destination_detail}
                                 onChange={this.handleChange} required />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label className='col-sm-3 control-label'>Alamat Lengkap Penerima</label>
                        <div className='col-sm-9'>
                                                    <textarea className='form-control' name='destination_address'
                                                              rows='3' cols='50'
                                                              placeholder='Alamat Lengkap Penerima Barang'
                                                              value={this.state.destination_address}
                                                              onChange={this.handleChange} required />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label className='col-sm-3 control-label'>LatLng Penerima</label>
                        {/* <div className="col-sm-9">
                                                    <a href='/#' data-toggle="modal" data-target="#myModal" onClick={(e) => {e.preventDefault()}}>
                                                        {this.state.destination_lat.length > 1 ? 
                                                        <input type="text" style={{cursor : 'pointer'}} className="form-control" placeholder={this.state.destination_lat+', '+this.state.destination_lng} value='' disabled/> :
                                                        <input type="text" style={{cursor : 'pointer'}} className="form-control" placeholder="Select on Map" value='' disabled/> }
                                                    </a>
                                                </div> */}
                        <div className='col-sm-4'>
                          <input type='text' className='form-control' name='destination_lat'
                                 placeholder='Latitude Penerima' value={this.state.destination_lat}
                                 onChange={this.handleChange} required />
                        </div>
                        <div className='col-sm-4'>
                          <input type='text' className='form-control' name='destination_lng'
                                 placeholder='Longitude Penerima' value={this.state.destination_lng}
                                 onChange={this.handleChange} required />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label className='col-sm-3 control-label'>Service Delivery</label>
                        <div className='col-sm-9'>
                          <select name='service_id' value={this.state.service_id} className='form-control'
                                  onChange={this.handleChange}>
                            {this.state.serviceList.map((item, index) => (
                              <option key={index} value={item.id}>{item.service}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className='form-group'>
                        <label className='col-sm-3 control-label'>Total Harga</label>
                        <div className='col-sm-9'>
                          {this.state.isCalculate ?
                            <input type='text' className='form-control' value='Calculating...' disabled /> :
                            <input type='text' className='form-control'
                                   value={isPrice ? formatter.format(this.state.price) : formatter.format(0)}
                                   disabled />}
                        </div>
                      </div>
                      <div className='form-group'>
                        <div className='col-sm-offset-3 col-sm-2'>
                          <button type='submit' className='btn btn-success'>Create Delivery</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <div id="myModal" className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content" style={{height : this.state.height, maxHeight : this.state.height, overflowY : 'auto'}}>
                                <Map 
                                    google={this.props.google}
                                    initialCenter={{
                                        lat: -2.8083818,
                                        lng: 112.5482446
                                    }}
                                    zoom={5}
                                    onClick={(_t, map, e) => this.addmarker(e.latLng, map)}>
                                        <Marker position={this.state.fields.location} />
                                </Map>
                            </div>
                            <div style={{backgroundColor : '#fff', padding : 10}}>
                                <span>{'Latitude, Longitude : '+this.state.fields.location}</span>
                            </div>
                        </div>
                    </div> */}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <section className='content-header'>
            <h1>
              Create Delivery
            </h1>
          </section>
          <section className='content'>
            <div className='box box-primary'>
              <div className='box-body box-profile text-center'>
                <i className='fa fa-spinner fa-pulse fa-3x fa-fw'/>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    }
  }
}

export default withAuth(GoogleApiWrapper({
  apiKey: ('AIzaSyDy5thJJOp9kPrbs6UUrzhYVftU3W3eB4g'),
})(DeliveryAdd));