import AuthService from './auth/AuthService';
import axios from 'axios';
import { ROLEGROUP } from '../utils/APIUtil';

const Auth = new AuthService();
export default class RoleService {

  async getRoleGroupList() {
    const response = await axios.get(ROLEGROUP + '/list', { headers: { Authorization: 'Bearer ' + Auth.getToken() } });
    const res = response.data;
    if (response.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject(res.error);
    }
  }

  async getUserRoleList() {
    const response = await axios.get(ROLEGROUP + '/user-role-groups', { headers: { Authorization: 'Bearer ' + Auth.getToken() } });
    const res = response.data;
    if (response.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject(res.error);
    }
  }

  async getMyAccessRole() {
    const response = await axios.get(ROLEGROUP + '/my-group', { headers: { Authorization: 'Bearer ' + Auth.getToken() } });
    const res = response.data;
    if (response.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject(res.error);
    }
  }

  async addRoleGroup(name) {
    const response = await axios.post(ROLEGROUP + '/add-group', { name }, { headers: { Authorization: 'Bearer ' + Auth.getToken()}});
    const res = response.data;
    if (response.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject(res.error);
    }
  }

  async addUserToRoleGroup(roleGroupId, userId) {
    const response = await axios.post(ROLEGROUP + '/assign-user', { roleGroupId, userId }, { headers: { Authorization: 'Bearer ' + Auth.getToken()}});
    const res = response.data;
    if (response.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject(res.error);
    }
  }

  async removeUserToRoleGroup(roleGroupId, userId) {
    const response = await axios.post(ROLEGROUP + '/deassign-user', { roleGroupId, userId }, { headers: { Authorization: 'Bearer ' + Auth.getToken()}});
    const res = response.data;
    if (response.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject(res.error);
    }
  }

  async updateAccessRole(data) {
    const response = await axios.post(ROLEGROUP + '/add-access', data, { headers: { Authorization: 'Bearer ' + Auth.getToken()}});
    const res = response.data;
    if (response.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject(res.error);
    }
  }
}