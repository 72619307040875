import { NotificationManager } from 'react-notifications';

const successMessage = (message, title) => {
  if (title) {
    NotificationManager.success(message, title);
    return;
  }
  NotificationManager.success(message, 'Success');
};

const errorMessage = (message, title) => {
  if (title) {
    NotificationManager.error(message, title);
    return;
  }
  NotificationManager.error(message, 'Error');
};

const warningMessage = (message, title) => {
  if (title) {
    NotificationManager.warning(message, title);
    return;
  }
  NotificationManager.warning(message, 'Warning');
};

export { successMessage, errorMessage, warningMessage };