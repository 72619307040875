import React, { Component } from 'react';
import DashboardService from '../service/DashboardService';
import { Link, withRouter } from 'react-router-dom';

const Dash = new DashboardService();

class Dashboard extends Component {
  state = {
    totalSeller: 0,
    totalUser: 0,
    totalProduct: 0,
    totalOrder: 0,
  };

  componentDidMount() {
    this.getDashboard();
  }

  getDashboard() {
    Dash.getDashboard().then(res => {
      if (res.result === 'OK') {
        this.setState({
          totalSeller: res.totalSeller,
          totalUser: res.totalUser,
          totalProduct: res.totalProduct,
          totalOrder: res.totalOrder,
        });
      } else {
        alert(res.error);
      }
    }).catch(err => {
      alert(err);
    });
  }

  render() {
    return (
      <React.Fragment>
        {/* Content Header (Page header) */}
        <section className='content-header'>
          <h1>
            Dashboard
            <small>Control panel</small>
          </h1>
        </section>
        {/* Main content */}
        <section className='content'>
          {/* Small boxes (Stat box) */}
          <div className='row'>
            <div className='col-lg-3 col-xs-6'>
              {/* small box */}
              <div className='small-box bg-aqua'>
                <div className='inner'>
                  <h2>Rp {this.state.totalOrder.toLocaleString()}</h2>
                  <p>Total Penjualan</p>
                </div>
                <div className='icon'>
                  <i className='ion ion-bag' />
                </div>
                <Link className='small-box-footer' to={{ pathname: '/order' }}>Info Lebih Lanjut <i
                  className='fa fa-arrow-circle-right' /></Link>
              </div>
            </div>
            {/* ./col */}
            <div className='col-lg-3 col-xs-6'>
              {/* small box */}
              <div className='small-box bg-green'>
                <div className='inner'>
                  <h2>{this.state.totalSeller.toLocaleString()}</h2>
                  <p>Total Toko Penjual</p>
                </div>
                <div className='icon'>
                  <i className='ion ion-stats-bars' />
                </div>
                <Link className='small-box-footer' to={{ pathname: '/seller' }}>Info Lebih Lanjut <i
                  className='fa fa-arrow-circle-right' /></Link>
              </div>
            </div>
            {/* ./col */}
            <div className='col-lg-3 col-xs-6'>
              {/* small box */}
              <div className='small-box bg-yellow'>
                <div className='inner'>
                  <h2>{this.state.totalUser.toLocaleString()}</h2>
                  <p>Total User</p>
                </div>
                <div className='icon'>
                  <i className='ion ion-person-add' />
                </div>
                <Link className='small-box-footer' to={{ pathname: '/user' }}>Info Lebih Lanjut <i
                  className='fa fa-arrow-circle-right' /></Link>
              </div>
            </div>
            {/* ./col */}
            <div className='col-lg-3 col-xs-6'>
              {/* small box */}
              <div className='small-box bg-red'>
                <div className='inner'>
                  <h2>{this.state.totalProduct.toLocaleString()}</h2>
                  <p>Total Produk</p>
                </div>
                <div className='icon'>
                  <i className='ion ion-pie-graph' />
                </div>
                <Link className='small-box-footer' to={{ pathname: '/product' }}>Info Lebih Lanjut <i
                  className='fa fa-arrow-circle-right' /></Link>
              </div>
            </div>
            {/* ./col */}
          </div>
          {/* /.row */}
        </section>
        {/* /.content */}
      </React.Fragment>
    );
  }
}

export default withRouter(Dashboard);