import React from 'react';

const NotFound = () => {
  return (
    <div style={{display: 'grid', placeItems: 'center'}}>
      <h1>404 Page</h1>
    </div>
  );
};

export default NotFound;