import React, { Component } from 'react';
import ProductService from '../../service/ProductService';
import { errorMessage, successMessage } from '../../utils/NotificationMessage';

const Product = new ProductService();

class ProductDetail extends Component {

  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdateProduct = this.handleUpdateProduct.bind(this);
    this.state = {
      productId: 0,
      nameProduct: '',
      descProduct: '',
      price: 0,
      stock: 0,
      photoUrlDefault: '',
      isNew: 0,
      isActive: 0,
      weight: 0,
      weightType: '',
      photoUrl: [],
      errors: [],
    };
  }

  componentDidMount() {
    const pathname = window.location.pathname;
    const split = pathname.split('/');
    const productId = Number(split[2]);
    this.setState({
      productId: productId,
    });
    this.getProductById(productId);
  }

  getProductById(productId) {
    Product.getProductById(productId).then(res => {
      console.log(res);
      this.setState({
        nameProduct: res.name,
        descProduct: res.description,
        photoUrlDefault: res.photoUrlDefault,
        price: res.price,
        isNew: res.isNew,
        isActive: res.isActive,
        stock: res.stock,
        weight: res.weight,
        weightType: res.weightType,
        photoUrl: res.photoUrl?.split('**').filter(function(el) {
          return el !== '';
        }),
      });
    }).catch(err => errorMessage(err.message));
  }

  handleChange(e) {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
    );
  }

  validate(nameProduct, descProduct, price, weight) {
    this.setState({ errors: [] });
    const errors = [];
    if (nameProduct.length < 1) {
      errors.push('Nama Product tidak boleh kosong');
    }
    if (descProduct.length < 1) {
      errors.push('tidak boleh kosong');
    }
    if (price.length < 1) {
      errors.push('Harga product tidak boleh kosong');
    }
    if (weight.length < 1) {
      errors.push('Berat product tidak boleh kosong');
    }
    return errors;
  }

  handleUpdateProduct(e) {
    e.preventDefault();
    const photoUrlArray = this.state.photoUrl.map(item => item + '**');
    const nameProduct = this.state.nameProduct;
    const descProduct = this.state.descProduct;
    const price = Number(this.state.price);
    const isNew = Number(this.state.isNew);
    const isActive = Number(this.state.isActive);
    const stock = Number(this.state.stock);
    const weight = Number(this.state.weight);
    const weightType = this.state.weightType;
    const photoUrlDefault = this.state.photoUrlDefault;
    const photoUrl = photoUrlArray.join('');
    const errors = this.validate(nameProduct, descProduct, this.state.price, this.state.weight);
    if (errors.length > 0) {
      this.setState({ errors });
      return;
    }
    const jsonData = {};
    jsonData['name'] = nameProduct;
    jsonData['description'] = descProduct;
    jsonData['price'] = price;
    jsonData['isNew'] = isNew;
    jsonData['isActive'] = isActive;
    jsonData['stock'] = stock;
    jsonData['weight'] = weight;
    jsonData['weightType'] = weightType;
    jsonData['photoUrlDefault'] = photoUrlDefault;
    jsonData['photoUrl'] = photoUrl;
    Product.updateProduct(jsonData, this.state.productId).then(() => {
      successMessage('Update Product Berhasil');
      this.props.history.replace('/product');
    }).catch(err => errorMessage(err.message));
  }

  render() {
    return (
      <React.Fragment>
        <section className='content-header'>
          <h1>
            Detail Produk
          </h1>
        </section>
        <section className='content'>
          <div className='box box-primary'>
            <div className='box-body box-profile'>
              <div className='active tab-pane' id='profile'>
                <div className='timeline-body margin-bottom'>
                  <div className='flexrow'>
                    <div className='col-md-3 text-center'>
                      <a href={this.state.photoUrlDefault} data-fancybox='photoUrl'
                         data-caption='Default Photo Product'>
                        <img src={this.state.photoUrlDefault} alt='photoDefault' style={{ width: 200, height: 200 }} />
                      </a>
                    </div>
                    <div className='col-md-9'>
                      <div>
                        {this.state.photoUrl?.map((item, index) => (
                          <a href={item} data-fancybox='photoUrl' key={index}>
                            <img src={item} alt='photoUrl' style={{ width: 100, height: 100 }} className='margin' />
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <form className='form-horizontal' onSubmit={this.handleUpdateProduct}>
                  <div className='form-group'>
                    <label htmlFor='inputName' className='col-sm-3 control-label'>Nama Produk</label>
                    <div className='col-sm-9'>
                      <input type='text' className='form-control' name='nameProduct'
                             placeholder='Nama Product' value={this.state.nameProduct}
                             onChange={this.handleChange} />
                    </div>
                  </div>
                  <div className='form-group'>
                    <label htmlFor='inputName' className='col-sm-3 control-label'>Deskripsi Produk</label>
                    <div className='col-sm-9'>
											<textarea className='form-control' name='descProduct' rows='4' cols='50'
                                placeholder='Description Product' value={this.state.descProduct}
                                onChange={this.handleChange} />
                    </div>
                  </div>
                  <div className='form-group'>
                    <label htmlFor='inputName' className='col-sm-3 control-label'>Harga</label>
                    <div className='col-sm-9'>
                      <div className='input-group'>
                        <span className='input-group-addon'>Rp.</span>
                        <input type='number' className='form-control' name='price'
                               placeholder='Harga' value={this.state.price}
                               onChange={this.handleChange} required />
                      </div>
                    </div>
                  </div>
                  <div className='form-group'>
                    <label htmlFor='inputName' className='col-sm-3 control-label'>Status Produk</label>
                    <div className='col-sm-9'>
                      <select name='isNew' className='form-control' value={this.state.isNew}
                              onChange={this.handleChange}>
                        <option value='1'>Baru</option>
                        <option value='0'>Bekas</option>
                      </select>
                    </div>
                  </div>
                  <div className='form-group'>
                    <label htmlFor='inputName' className='col-sm-3 control-label'>Berat Produk</label>
                    <div className='col-sm-9'>
                      <div className='input-group'>
                        <input type='number' className='form-control' name='weight'
                               placeholder='Berat Product' value={this.state.weight}
                               onChange={this.handleChange} required />
                        <span className='input-group-addon'>
													<label className='mb-0 margin-r-5'>
														<input type='radio' className='minimal' name='weightType' value='gram'
                                   checked={this.state.weightType === 'grams' || this.state.weightType === 'gram'}
                                   onChange={this.handleChange} />
														gram
													</label>
													<label className='mb-0'>
														<input type='radio' className='minimal' name='weightType' value='kilogram'
                                   checked={this.state.weightType === 'kilograms' || this.state.weightType === 'kilogram'}
                                   onChange={this.handleChange} />
														kilogram
													</label>
												</span>
                      </div>
                    </div>
                  </div>
                  <div className='form-group'>
                    <label htmlFor='inputName' className='col-sm-3 control-label'>Stock Barang</label>
                    <div className='col-sm-9'>
                      <input type='number' className='form-control' name='stock' minLength='0'
                             placeholder='Harga' value={this.state.stock}
                             onChange={this.handleChange} required />
                    </div>
                  </div>
                  <div className='form-group'>
                    <label htmlFor='inputName' className='col-sm-3 control-label'>Status Barang</label>
                    <div className='col-sm-9'>
                      <label className='mb-0 margin-r-5'>
                        <input type='radio' className='minimal' name='isActive' value='1'
                               checked={this.state.isActive === 1 || this.state.isActive === '1'}
                               onChange={this.handleChange} />
                        Aktif
                      </label>
                      <label className='mb-0'>
                        <input type='radio' className='minimal' name='isActive' value='0'
                               checked={this.state.isActive === 0 || this.state.isActive === '0'}
                               onChange={this.handleChange} />
                        Tidak Aktif
                      </label>
                    </div>
                  </div>
                  <div className='form-group'>
                    <div className='col-sm-offset-3 col-sm-2'>
                      <button type='submit' className='btn btn-success'>Simpan</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default ProductDetail;