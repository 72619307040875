import React, { useState } from 'react';
import UserService from '../../service/UserService';
import { errorMessage, successMessage } from '../../utils/NotificationMessage';
import { useHistory } from 'react-router-dom';

const CreateAdminUser = () => {
  const history = useHistory()
  const userService = new UserService();
  const [isLoading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');


  function createAdminUser(e) {
    e.preventDefault();
    setLoading(true)
    const password = generatePassword()
    const username = new Date().getTime().toString()
    const dataJSON = { email, firstName, lastName, isActive: 1, phone: phoneNumber, username, password }
    userService.createAdminUser(dataJSON).then(() => {
      alert(`Berhasil membuat user admin \nemail : ${email} \npassword : ${password}`)
      successMessage("Berhasil Membuat User Admin")
      history.push('/roleuser')
    }).catch((e) => errorMessage(e.message)).finally(() => setLoading(false))
  }

  function generatePassword() {
    let retVal = "";
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
    for (let i = 0, n = charset.length; i < 10; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  return (
    <React.Fragment>
      <section className='content-header'>
        <h1>
          Create Admin User
        </h1>
      </section>
      {!isLoading ?
        <section className='content'>
          <div className='row'>
            <div className='col-xs-12'>
              <div className='box box-primary'>
                <div className='box-body'>
                  <form className='form-horizontal' onSubmit={createAdminUser}>
                    <div className='form-group'>
                      <label htmlFor='inputName' className='col-sm-3 control-label'>Nama Depan</label>
                      <div className='col-sm-9'>
                        <input type='text' className='form-control' name='firstName'
                               placeholder='Nama Depan' value={firstName} required
                               onChange={(e) => setFirstName(e.target.value)} />
                      </div>
                    </div>
                    <div className='form-group'>
                      <label htmlFor='inputName' className='col-sm-3 control-label'>Nama Belakang</label>
                      <div className='col-sm-9'>
                        <input type='text' className='form-control' name='lastName'
                               placeholder='Nama Belakang' value={lastName} required
                               onChange={(e) => setLastName(e.target.value)} />
                      </div>
                    </div>
                    <div className='form-group'>
                      <label htmlFor='inputName' className='col-sm-3 control-label'>Email</label>
                      <div className='col-sm-9'>
                        <input type={'email'} className='form-control' name='email'
                               placeholder='Email' value={email} required
                               onChange={(e) => setEmail(e.target.value)} />
                      </div>
                    </div>
                    <div className='form-group'>
                      <label htmlFor='inputName' className='col-sm-3 control-label'>No Telepon</label>
                      <div className='col-sm-9'>
                        <input type='tel' className='form-control' name='firstName'
                               placeholder='No Telepon' value={phoneNumber} required
                               onChange={(e) => setPhoneNumber(e.target.value)} />
                      </div>
                    </div>
                    <div className='form-group text-right'>
                      <div className='col-sm-12'>
                        <button type='submit' className='btn btn-primary'>
                          Create Admin
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section> :
        <section className='content'>
          <div className='box box-primary'>
            <div className='box-body box-profile text-center'>
              <i className='fa fa-spinner fa-pulse fa-3x fa-fw' />
            </div>
          </div>
        </section>
      }
    </React.Fragment>
  );
};

export default CreateAdminUser;