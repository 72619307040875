import React from 'react';

const NotAllowed = () => {
  return (
    <div style={{display: 'grid', placeItems: 'center'}}>
      <h1>401 Unauthorized</h1>
    </div>
  );
};

export default NotAllowed;