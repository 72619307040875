import React, { useEffect, useState } from 'react';
import UserService from '../../service/UserService';
import { Link, withRouter } from 'react-router-dom';
import GlobalSearchComponent from './GlobalSearchUser';
import ReactTable from 'react-table-6';
import { errorMessage } from '../../utils/NotificationMessage';

const Users = new UserService();

const User = () => {
  const [userList, setUserList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUserList();
    getColumns();
  }, []);

  function getUserList() {
    Users.getUserList().then(e => {
      setUserList(e);
      setFilteredData(e);
    }).catch(err => errorMessage(err.message)).finally(() => {
      setLoading(false);
    });
  }

  const handleSetData = userList => {
    setFilteredData(userList);
  };

  const getColumns = () => {
    let columns = [
      {
        Header: 'Nama User',
        id: 'r',
        accessor: d => d,
        Cell: ({ row }) => (<Link to={{ pathname: '/user/' + row.r.id }}>{row.r.firstName} {row.r.lastName}</Link>),
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Nomor Telepon',
        accessor: 'phone',
      },
      {
        Header: 'Tanggal Ulang Tahun',
        accessor: 'birthday',
      },
    ];
    setColumns(columns);
  };

  return (
    <React.Fragment>
      <section className='content-header'>
        <h1>
          Daftar User
        </h1>
        <ol className='breadcrumb'>
          <li className='active'>Data tables</li>
        </ol>
      </section>
      <section className='content'>
        <div className='row'>
          <div className='col-xs-12'>
            <div className='box'>
              <div className='box-body'>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='input-group pull-right' style={{ width: 300, marginBottom: 10 }}>
                      {/* <span className="input-group-addon"><i className="fa fa-search" /></span> */}
                      {/* <input type="text" className="form-control" name="searchInput" placeholder="Search User" onChange={this.handleChange} value={searchInput || ""}/> */}
                      <GlobalSearchComponent
                        data={userList}
                        handleSetData={handleSetData}
                      />
                    </div>
                  </div>
                </div>
                <ReactTable
                  columns={columns}
                  data={filteredData}
                  loading={loading}
                  defaultPageSize={10}
                  className='-striped -highlight'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default withRouter(User);