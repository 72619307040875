import React, { useState } from 'react';
import AuthService from '../service/auth/AuthService';
import { errorMessage, successMessage } from '../utils/NotificationMessage';
import { useHistory } from 'react-router-dom';

const ForgotPassword = () => {
  const history = useHistory()
  const authService = new AuthService();
  const [email, setEmail] = useState('');
  const [isLoading, setLoading] = useState(false)

  function sendLinkResetPassword(e) {
    e.preventDefault();
    setLoading(true)
    authService.sendLinkResetPassword(email).then(() => {
      successMessage('Cek Email, untuk mendapatkan link reset password');
      history.replace('/login')
    }).catch(err => errorMessage(err.message)).finally(() => setLoading(false));
  }

  return (
    <React.Fragment>
      {!authService.loggedIn() ?
        <React.Fragment>
          <div className='login-page' style={{ display: 'flex', placeItems: 'center', height: '100vh' }}>
            <div className='login-box'>
              <div className='login-logo'>
                <b>Boga</b> Dashboard
              </div>
              {/* /.login-logo */}
              <div className='login-box-body'>
                <p className='login-box-msg'>Forget Password</p>
                <form onSubmit={sendLinkResetPassword}>
                  <div className='form-group has-feedback'>
                    <input type='email' className='form-control' placeholder='Email' value={email} required
                           onChange={(e) => setEmail(e.target.value)} />
                    <span className='glyphicon glyphicon-envelope form-control-feedback' />
                  </div>
                  <div className='row'>
                    {/* /.col */}
                    <div className='col-xs-4'>
                      <button type='submit' className='btn btn-primary btn-block btn-flat' disabled={isLoading}>
                        Kirim Link
                      </button>
                    </div>
                    {/* /.col */}
                  </div>
                </form>
              </div>
              {/* /.login-box-body */}
              <p>&nbsp;</p>
            </div>
          </div>
        </React.Fragment> : <div />
      }
    </React.Fragment>
  );
};

export default ForgotPassword;