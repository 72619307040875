import React, { Component } from 'react';
import SellerService from '../../service/SellerService';
import ProductService from '../../service/ProductService';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table-6';
import { errorMessage, successMessage } from '../../utils/NotificationMessage';

const Seller = new SellerService();
const Product = new ProductService();

class SellerDetail extends Component {

  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdateSeller = this.handleUpdateSeller.bind(this);
    this.handleMakeOfficial = this.handleMakeOfficial.bind(this);
    this.handleRevokeOfficial = this.handleRevokeOfficial.bind(this);
    this.state = {
      userId: 0,
      sellerId: 0,
      nameSeller: '',
      descSeller: '',
      photoSeller: '',
      statusSeller: '',
      addressSeller: '',
      isOfficial: false,
      user: [],
      productList: [],
      errors: [],
    };
  }

  componentDidMount() {
    const pathname = window.location.pathname;
    const split = pathname.split('/');
    const userId = Number(split[2]);
    this.setState({
      userId: userId,
    });
    this.getSellerById(userId);
  }

  getSellerById(userId) {
    Seller.getSellerById(userId).then(res => {
      console.log(res);
      this.setState({
        sellerId: res.id,
        nameSeller: res.namaToko,
        descSeller: res.deskripsi,
        photoSeller: res.photo,
        statusSeller: '' + res.isActive,
        isOfficial: res.officialSeller,
        user: res.user,
        addressSeller: res.address.address + ', ' + res.address.kodepos.desa + ', ' + res.address.kodepos.kecamatan + ', ' + res.address.kodepos.daerahTingkatDua + ' ' + res.address.kodepos.kabupatenKota + ', ' + res.address.kodepos.provinsi + ', ' + res.address.kodepos.kodePos,
      });
      this.getProductBySellerId(res.id);
    }).catch(err => errorMessage(err.message));
  }

  getProductBySellerId(sellerId) {
    Product.getProductBySellerId(sellerId).then(res => {
      this.setState({
        productList: res,
      });
    }).catch(err => errorMessage(err.message));
  }

  handleChange(e) {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
    );
  }

  validate(nameSeller, descSeller) {
    this.setState({ errors: [] });
    const errors = [];
    nameSeller = nameSeller.trimLeft();
    if (nameSeller.length < 1) {
      errors.push('Nama Seller tidak boleh kosong');
    }
    if (nameSeller.length < 3) {
      errors.push('Nama Seller tidak boleh kurang dari 3 karakter');
    }
    if (!!descSeller.length < 1) {
      errors.push('tidak boleh kosong');
    }
    return errors;
  }

  handleUpdateSeller(e) {
    e.preventDefault();
    const nameSeller = this.state.nameSeller;
    const descSeller = this.state.descSeller;
    const statusSeller = this.state.statusSeller;
    const errors = this.validate(nameSeller, descSeller);
    if (errors.length > 0) {
      this.setState({ errors });
      return;
    }
    const jsonData = {};
    jsonData['namaToko'] = nameSeller;
    jsonData['deskripsi'] = descSeller;
    jsonData['isActive'] = statusSeller;
    Seller.updateSeller(jsonData, this.state.userId).then(_ => {
      successMessage('Update Seller Berhasil');
      this.props.history.replace('/seller');
    }).catch(err => errorMessage(err.message));
  }

  handleMakeOfficial(e) {
    e.preventDefault();
    const jsonData = {};
    jsonData['seller_id'] = this.state.sellerId;
    Seller.makeOfficial(jsonData).then(_ => {
      this.setState({
        isOfficial: true,
      });
      successMessage('This seller is now an official', 'Official Seller');
    }).catch(err => errorMessage(err.message));
  }

  handleRevokeOfficial(e) {
    e.preventDefault();
    const jsonData = {};
    jsonData['seller_id'] = this.state.sellerId;
    Seller.revokeOfficial(jsonData).then(_ => {
      this.setState({
        isOfficial: false,
      });
      successMessage('This seller is no longer official now', 'Official Seller');
    }).catch(err => errorMessage(err.message));
  }

  render() {
    const formatter = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    });

    const columns = [
      {
        Header: 'Gambar Product',
        id: 'r',
        accessor: d => d,
        Cell: ({ row }) => (<img src={row.r.photoUrlDefault} alt='...' style={{ width: 60, height: 60 }} />),
        className: 'text-center',
      },
      {
        Header: 'Nama Product',
        id: 'r',
        accessor: d => d,
        Cell: ({ row }) => (<Link to={{ pathname: '/product/' + row.r.id }}>{row.r.name}</Link>),
      },
      {
        Header: 'Harga',
        id: 'price',
        accessor: d => Number(d.price),
        Cell: ({ row }) => formatter.format(row.price),
      },
      {
        Header: 'Kategori',
        accessor: 'category.name',
      },
      {
        Header: 'Status Product',
        accessor: 'isActive',
        Cell: ({ value }) => (value === 1 ? 'Aktif' : 'Tidak'),
      },
    ];
    return (
      <React.Fragment>
        {/* Content Header (Page header) */}
        <section className='content-header'>
          <h1>
            Seller Profile
          </h1>
        </section>
        {/* Main content */}
        <section className='content'>
          <div className='row'>
            <div className='col-md-3'>
              {/* Profile Image */}
              <div className='box box-primary'>
                <div className='box-body box-profile'>
                  {this.state.photoSeller !== '' ?
                    <img className='profile-user-img img-responsive img-circle' src={this.state.photoSeller}
                         alt='seller_picture' /> :
                    <div className='profile-user-img img-circle text-center' style={{ lineHeight: 138 + 'px' }}><span>No Photo</span>
                    </div>}
                  <h3 className='profile-username text-center'>{this.state.nameSeller} {(this.state.isOfficial ?
                    <b><span className='text-yellow'>(Official)</span></b> : '')}</h3>
                  <p className='text-muted text-center'>{this.state.descSeller}</p>
                  <ul className='list-group list-group-unbordered' style={{ margin: 0 }}>
                    <li className='list-group-item'>
                      <strong><i className='fa fa-map-marker margin-r-5' /> Address</strong>
                      <p className='text-muted'>{this.state.addressSeller}</p>
                    </li>
                    <li className='list-group-item'>
                      <strong><i className='fa fa-user margin-r-5' /> Pemilik Toko</strong>
                      <Link to={{ pathname: '/user/' + this.state.user.id }}>
                        <p>{this.state.user.firstName} {this.state.user.lastName}<br />{this.state.user.email}</p>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /.col */}
            <div className='col-md-9'>
              <div className='nav-tabs-custom'>
                <ul className='nav nav-tabs'>
                  <li className='active'><a href='#seller' data-toggle='tab'>Info Seller</a></li>
                  <li><a href='#productList' data-toggle='tab'>List Produk</a></li>
                </ul>
                <div className='tab-content'>
                  {/* /.tab-pane */}
                  <div className='active tab-pane' id='seller'>
                    <form className='form-horizontal' onSubmit={this.handleUpdateSeller}>
                      <div className='form-group'>
                        <label htmlFor='inputName' className='col-sm-3 control-label'>Nama Toko</label>
                        <div className='col-sm-9'>
                          <input type='text' className='form-control' name='nameSeller'
                                 placeholder='Nama Toko' value={this.state.nameSeller}
                                 onChange={this.handleChange} />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label htmlFor='inputName' className='col-sm-3 control-label'>Tentang Toko</label>
                        <div className='col-sm-9'>
                          <input type='text' className='form-control' name='descSeller'
                                 placeholder='Tentang Toko' value={this.state.descSeller}
                                 onChange={this.handleChange} />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label htmlFor='inputSkills' className='col-sm-3 control-label'>Status Toko</label>
                        <div className='col-sm-9'>
                          {/* radio */}
                          <label>
                            <input type='radio' className='minimal' name='statusSeller' value='1'
                                   checked={this.state.statusSeller === '1'} onChange={this.handleChange} />
                            Aktif
                          </label>
                          <label>
                            <input type='radio' className='minimal' name='statusSeller' value='0'
                                   checked={this.state.statusSeller === '0'} onChange={this.handleChange} />
                            Tidak Aktif
                          </label>
                        </div>
                      </div>
                      <div className='form-group'>
                        <div className='col-sm-offset-2 col-sm-6'>
                          {this.state.errors.map(error => (
                            <p className='help-block' key={error}>Error: {error}</p>
                          ))}
                        </div>
                      </div>
                      <div className='form-group'>
                        <div className='col-sm-offset-3 col-sm-2'>
                          <button type='submit' className='btn btn-success'>Simpan</button>
                        </div>
                      </div>
                      {
                        this.state.isOfficial ?
                          <div className='form-group'>
                            <div className='col-sm-offset-3 col-sm-2'>
                              <button type='button' className='btn btn-danger'
                                      onClick={this.handleRevokeOfficial}>Revoke Official
                              </button>
                            </div>
                          </div> :
                          <div className='form-group'>
                            <div className='col-sm-offset-3 col-sm-2'>
                              <button type='button' className='btn btn-warning' onClick={this.handleMakeOfficial}>Make
                                Official
                              </button>
                            </div>
                          </div>
                      }
                    </form>
                  </div>
                  <div className='tab-pane' id='productList'>
                    <ReactTable
                      columns={columns}
                      data={this.state.productList}
                      defaultPageSize={5} />
                  </div>
                  {/* /.tab-pane */}
                </div>
                {/* /.tab-content */}
              </div>
              {/* /.nav-tabs-custom */}
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </section>
        {/* /.content */}
      </React.Fragment>
    );
  }
}

export default SellerDetail;