import React, { Component } from 'react';
import ApiService from '../../service/TermconditionService';
import { Link, withRouter } from 'react-router-dom';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { errorMessage, successMessage } from '../../utils/NotificationMessage';

const API = new ApiService();

class TermCondition extends Component {

  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.state = {
      btnTitle: 'Create',
      id: null,
      editorState: EditorState.createEmpty(),
      version: '',
      dataList: [],
    };
  }

  componentDidMount() {
    this.getList();
  }

  handleChange(e) {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
    );
  }

  onEditorStateChange(editorState) {
    this.setState({
      editorState,
    });
  };

  handleEdit(id) {
    API.getById(id).then(dt => {
      const contentBlock = htmlToDraft(dt.terms_condition.content);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        this.setState({
          btnTitle: 'Update',
          id: id,
          version: dt.terms_condition.version,
          editorState,
        });
      }
    }).catch(err => errorMessage(err.message));
  }

  handleAdd(e) {
    e.preventDefault();
    const content = this.state.editorState;
    const data = {
      version: this.state.version,
      content: draftToHtml(convertToRaw(content.getCurrentContent())),
    };
    if (this.state.id === null) {
      API.add(data).then(() => {
        successMessage('Berhasil Menambah Data');
        this.getList();
        this.setState({
          id: null,
          version: '',
          btnTitle: 'Create',
          editorState: EditorState.createEmpty(),
        });
      }).catch(err => errorMessage(err.message));
    } else {
      data.id = this.state.id;
      API.edit(data).then(() => {
        successMessage('Berhasil Merubah Data');
        this.getList();
        this.setState({
          id: null,
          version: '',
          btnTitle: 'Create',
          editorState: EditorState.createEmpty(),
        });
      }).catch(err => errorMessage(err.message));
    }
  }

  getList() {
    API.getList().then(res => {
      this.setState({
        dataList: res.terms_conditions,
      });
    }).catch(err => errorMessage(err.message));
  }

  onDelete(id) {
    API.deletee(id).then(() => {
      successMessage('Berhasil Dihapus');
      this.getList();
    }).catch(err => errorMessage(err.message));
  }

  render() {

    const { editorState } = this.state;

    return (
      <React.Fragment>
        <section className='content-header'>
          <h1>
            Daftar Term & Condition
          </h1>
        </section>
        {/* Main content */}
        <section className='content'>
          <div className='row'>
            <div className='col-xs-12'>
              <div className='box'>
                {/* /.box-header */}
                <div className='box-body'>
                  <div style={{ marginBottom: 10, marginTop: 20 }}>
                    <div className='list-group-item'>
                      <b>Create Daftar Term & Condition</b>
                      <form className='form-horizontal' onSubmit={this.handleAdd}>
                        <div className='form-group'>
                          <label htmlFor='inputName' className='col-sm-3 control-label'>Version</label>
                          <div className='col-sm-9'>
                            <input type='text' className='form-control' id='version' name='version'
                                   placeholder='Version' value={this.state.version}
                                   onChange={this.handleChange} />
                          </div>
                        </div>
                        <div className='form-group'>
                          <label className='col-sm-3 control-label'>Content</label>
                          <div className='col-sm-9'>
                            <Editor
                              editorState={editorState}
                              toolbarClassName='toolbarClassName'
                              wrapperClassName='demo-wrapper'
                              editorClassName='form-control'
                              onEditorStateChange={this.onEditorStateChange}
                            />
                            {/* <input type='text' className='form-control' name='descBanner'
                                                            placeholder='Deskripsi Banner' value={this.state.content} onChange={this.handleChange} required /> */}
                          </div>
                        </div>
                        <div className='form-group text-right'>
                          <div className='col-sm-12'>
                            <button type='submit' className='btn btn-primary'>
                              <i className='fa fa-plus'> {this.state.btnTitle}</i>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <ul className='todo-list list-group'>
                    {this.state.dataList.map((item, index) => (
                      <li key={index} style={{ marginBottom: 10, display: 'flex', alignItems: 'center' }}>
                        <div style={{ flex: 'auto' }}>
                          <span className='text m-0'>Version {item.version}</span><br />
                        </div>

                        <div className='tools'>
                          <Link to='#' onClick={() => this.handleEdit(item.id)} style={{ marginRight: 5 }}>
                            <i className='fa fa-edit' />
                          </Link>
                          <Link to='#' onClick={() => {
                            if (window.confirm('Are you sure you wish to delete this?')) this.onDelete(item.id);
                          }}>
                            <i className='fa fa-trash-o' />
                          </Link>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withRouter(TermCondition);