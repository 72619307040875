import React, { Component } from 'react';
import DeliveryService from '../../../service/DeliveryService';
import { Link } from 'react-router-dom';
import withAuth from '../../../service/auth/withAuth';
import { errorMessage, successMessage } from '../../../utils/NotificationMessage';

const Delivery = new DeliveryService();

class Services extends Component {

  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.handleAddServices = this.handleAddServices.bind(this);
    this.state = {
      typeList: ['Motor', 'Sepeda', 'Mobil', 'Truck'],
      serviceList: [],
      typeName: 'Motor',
      serviceName: '',
      initDistance: '',
      baseprice: '',
      nextPrice: '',
      maxDistance: '',
      maxWeight: '',
      comission: '',
      loading: true,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    if (!Delivery.isTokenExpired()) {
      this.getServicesList();
    } else {
      Delivery.reloadToken().then(() => {
        this.getServicesList();
      }).catch(err => errorMessage(err.message));
    }
  }

  getServicesList() {
    this.setState({ loading: true });
    Delivery.getServicesList().then(e => {
      this.setState({
        loading: false,
        serviceList: e.data,
      });
    }).catch(err => errorMessage(err.message));
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleAddServices(e) {
    e.preventDefault();
    const jsonData = {};
    jsonData['type'] = this.state.typeName;
    jsonData['service'] = this.state.serviceName;
    jsonData['init_distance'] = this.state.initDistance;
    jsonData['init_price'] = Number(this.state.baseprice);
    jsonData['next_price'] = Number(this.state.nextPrice);
    jsonData['max_weight'] = Number(this.state.maxWeight);
    jsonData['max_distance'] = Number(this.state.maxDistance);
    jsonData['comission'] = Number(this.state.comission);
    if (!Delivery.isTokenExpired()) {
      console.log(JSON.stringify(jsonData));
      Delivery.addServices(jsonData).then(e => {
        if (e.status === 200) {
          this.setState({
            serviceName: '',
            initDistance: '',
            baseprice: '',
            nextPrice: '',
            maxDistance: '',
            maxWeight: '',
            comission: '',
          });
          this.getServicesList();
          successMessage('Service berhasil ditambahkan', 'Success');
        }
      }).catch(err => errorMessage(err.message));
    } else {
      alert('Token Expired, silakan refresh halaman ini');
    }
  }

  onDelete(e, id, name) {
    e.preventDefault();
    if (!Delivery.isTokenExpired()) {
      Delivery.deleteServices(id).then(e => {
        if (e.status === 200) {
          this.getServicesList();
          successMessage('Berhasil delete service ' + name, 'Success');
        }
      }).catch(err => errorMessage(err.message));
    } else {
      alert('Token Expired, silakan refresh halaman ini');
    }
  }

  render() {

    const formatter = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    });

    return (
      <React.Fragment>
        <section className='content-header'>
          <h1>
            Daftar Layanan
          </h1>
        </section>
        <section className='content'>
          <div className='row'>
            <div className='col-xs-12'>
              <div className='box'>
                <div className='box-body'>
                  <div className='row'>
                    {this.state.loading ?
                      <div className='col-xs-3'>
                        <div className='square-box' style={{ height: 250, marginBottom: 10 }}>
                          <i className='fa fa-spinner fa-pulse fa-2x fa-fw' aria-hidden='true' />
                        </div>
                      </div> :
                      this.state.serviceList.map((val, index) => (
                        <div key={index} className='col-xs-4'>
                          <Link to={{ pathname: '/services/' + val.id }} className='square-box'
                                style={{ height: 250, marginBottom: 10 }}>
                            <span className='fa-stack fa-lg icon-delete' title='Delete Service'
                                  style={{ top: 10 }} onClick={(e) => {
                              if (window.confirm('Yakin Ingin Menghapus Layanan ' + val.service + '?')) {
                                this.onDelete(e, val.id, val.service);
                              } else {
                                e.preventDefault();
                              }
                            }}>
                                <i className='fa fa-circle fa-stack-2x text-danger' />
                                <i className='fa fa-trash-o fa-stack-1x fa-inverse' />
                            </span>
                            <span>{`${val.type} - ${val.service}`}</span>
                            <div style={{ width: '100%', paddingLeft: 20, marginTop: '10%' }}>
                              <p>{`Harga : ${formatter.format(val.init_price)}`}</p>
                              <p>{`Harga Selanjutnya : ${formatter.format(val.next_price)} per ${val.init_distance} km`}</p>
                              {val.max_weight > 1000 ?
                                <p>{`Maksimal Berat : ${val.max_weight/1000} kilogram`}</p> :
                                <p>{`Maksimal Berat : ${val.max_weight} gram`}</p>
                              }
                              <p>{`Maksimal Jarak : ${val.max_distance} km`}</p>
                              <p>{`Komisi : ${val.comission}% untuk perusahaan`}</p>
                            </div>
                          </Link>
                        </div>
                      ))}
                  </div>
                  <div style={{ marginBottom: 10, marginTop: 20 }}>
                    <div className='category-group list-group-item'>
                      <b>Buat Layanan Baru</b>
                      <form className='form-horizontal' onSubmit={this.handleAddServices}>
                        <div className='form-group'>
                          <label className='col-sm-3 control-label'>Pilih Type</label>
                          <div className='col-sm-9'>
                            <select name='typeName' value={this.state.typeName} className='form-control'
                                    onChange={this.handleChange}>
                              {this.state.typeList.map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className='form-group'>
                          <label className='col-sm-3 control-label'>Nama Layanan</label>
                          <div className='col-sm-9'>
                            <input type='text' className='form-control' name='serviceName'
                                   placeholder='Nama Services' value={this.state.serviceName}
                                   onChange={this.handleChange} required />
                          </div>
                        </div>
                        <div className='form-group'>
                          <label className='col-sm-3 control-label'>Harga</label>
                          <div className='col-sm-9'>
                            <div className='input-group'>
                              <span className='input-group-addon'>Rp.</span>
                              <input type='number' className='form-control' name='baseprice'
                                     placeholder='Harga' value={this.state.baseprice} onChange={this.handleChange}
                                     required />
                            </div>
                          </div>
                        </div>
                        <div className='form-group'>
                          <label className='col-sm-3 control-label'>Harga Selanjutnya</label>
                          <div className='col-sm-5'>
                            <div className='input-group'>
                              <span className='input-group-addon'>Rp.</span>
                              <input type='number' className='form-control' name='nextPrice'
                                     placeholder='Harga Selanjutnya' value={this.state.nextPrice}
                                     onChange={this.handleChange} required />
                            </div>
                          </div>
                          <label className='col-sm-1 control-label' style={{ textAlign: 'center' }}>per</label>
                          <div className='col-sm-3'>
                            <div className='input-group'>
                              <input type='number' className='form-control' name='initDistance'
                                     value={this.state.initDistance} onChange={this.handleChange} required />
                              <span className='input-group-addon'>km</span>
                            </div>
                          </div>
                        </div>
                        <div className='form-group'>
                          <label className='col-sm-3 control-label'>Maksimal Berat</label>
                          <div className='col-sm-9'>
                            <div className='input-group'>
                              <input type='number' className='form-control' name='maxWeight'
                                     placeholder='Maksimal Berat' value={this.state.maxWeight}
                                     onChange={this.handleChange} required />
                              <span className='input-group-addon'>gram</span>
                            </div>
                          </div>
                        </div>
                        <div className='form-group'>
                          <label className='col-sm-3 control-label'>Maksimal Jarak</label>
                          <div className='col-sm-9'>
                            <div className='input-group'>
                              <input type='number' className='form-control' name='maxDistance'
                                     placeholder='Maksimal Jarak' value={this.state.maxDistance}
                                     onChange={this.handleChange} required />
                              <span className='input-group-addon'>km</span>
                            </div>
                          </div>
                        </div>
                        <div className='form-group'>
                          <label className='col-sm-3 control-label'>Komisi</label>
                          <div className='col-sm-9'>
                            <div className='input-group'>
                              <input type='number' className='form-control' name='comission'
                                     placeholder='Komisi untuk perusahaan' value={this.state.comission}
                                     onChange={this.handleChange} required max={100} />
                              <span className='input-group-addon'>%</span>
                            </div>
                          </div>
                        </div>
                        <div className='form-group text-right'>
                          <div className='col-sm-12'>
                            <button type='submit' className='btn btn-primary'>
                              <i className='fa fa-plus'> Tambahkan</i>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /.content */}
      </React.Fragment>
    );
  }
}

export default withAuth(Services);