import { ADD_USER, AUTH, POINT, ROLEGROUP, USER } from '../utils/APIUtil';
import AuthService from './auth/AuthService';
import axios from 'axios';

const Auth = new AuthService();
export default class UserService {
  constructor() {
    this.fetch = this.fetch.bind(this);
  }

  async getUserList() {
    const response = await axios.get(USER + '/list', { headers: { Authorization: 'Bearer ' + Auth.getToken() } });
    const res = response.data;
    return Promise.resolve(res);
  }

  async getAdminUserList() {
    const response = await axios.get(USER + '/list/admin', { headers: { Authorization: 'Bearer ' + Auth.getToken() } });
    const res = response.data;
    return Promise.resolve(res);
  }

  async getUserById(id) {
    const res = await this.fetch(USER + '/' + id, {
      method: 'GET',
    });
    return Promise.resolve(res);
  }

  async getPoint(type, id) {
    const res = await this.fetch(POINT + '/balance/' + type + '/' + id, {
      method: 'GET',
    });
    return Promise.resolve(res);
  }

  async getPointListById(id, date) {
    const res = await this.fetch(POINT + `/list?id=${id}&${date}`, {
      method: 'GET',
    });
    return Promise.resolve(res);
  }

  async createAdminUser(jsonData) {
    const response = await axios.post(ADD_USER + '/admin', jsonData, { headers: { Authorization: 'Bearer ' + Auth.getToken()}});
    const res = response.data;
    if (response.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject(res.error);
    }
  }

  async updateUser(jsonData, id) {
    const response = await axios.put(USER + '/' + id, jsonData, { headers: { Authorization: 'Bearer ' + Auth.getToken() } });
    const res = response.data;
    if (res.success) {
      return Promise.resolve(res);
    } else {
      return Promise.reject(res.error);
    }
  }

  async changePassword(oldPassword, newPassword) {
    const response = await axios.post(AUTH + '/password/reset', { oldPassword, newPassword }, { headers: { Authorization: 'Bearer ' + Auth.getToken() } });
    const res = response.data;
    if (response.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject(res.error);
    }
  }

  async changePasswordSuperAdmin(userId, newPassword) {
    const response = await axios.post(AUTH + '/super/change-password', { userId, newPassword }, { headers: { Authorization: 'Bearer ' + Auth.getToken() } });
    const res = response.data;
    if (response.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject(res.error);
    }
  }

  async updateUserRole(jsonData, id) {
    try {
      const response = await axios.put(USER + '/role/' + id, jsonData, { headers: { Authorization: 'Bearer ' + Auth.getToken() } });
      const res = response.data;
      if (res.result === 'OK') {
        return Promise.resolve(res);
      } else if (res.result === 'NOK') {
        return Promise.reject(res.error);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }

  fetch(url, options) {
    // performs api calls sending the required authentication headers
    const headers = {
      'Accept': 'application/json',
    };
    headers['Authorization'] = 'Bearer ' + Auth.getToken();
    return fetch(url, {
      headers,
      ...options,
    })
      .then(this._checkStatus)
      .then(response => response.json());
  }

  _checkStatus(response) {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      const error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  }
}
