import React, { Component } from 'react';

export default class GlobalSearchComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: '',
    };
  }

  handleChange = event => {
    this.setState({ searchInput: event.target.value }, () =>
      this.globalSearch());
    // this.globalSearch()
  };

  globalSearch = () => {
    let { searchInput } = this.state;
    let filteredData = this.props.data.filter(value => {
      return (
        value.transaction_code.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.sender_name.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.receiver_name.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.price.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.status.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.service.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    this.props.handleSetData(filteredData);
  };

  render() {
    return (
      <>
        <br />
        <div className='input-group pull-right' style={{ width: 300, marginBottom: 10 }}>
          <span className='input-group-addon'><i className='fa fa-search' /></span>
          <input type='text' className='form-control' name='searchInput' placeholder='Cari Driver'
                 onChange={this.handleChange} value={this.state.searchInput || ''} />
        </div>
        {/* <Input
              size="large"
              name="searchInput"
              value={this.state.searchInput || ""}
              onChange={this.handleChange}
              label="Search"
            /> */}
        <br />
        <br />
      </>
    );
  }
}