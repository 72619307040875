import React, { Component } from 'react';
import UploadService from '../service/UploadService';
import { errorMessage } from '../utils/NotificationMessage';

const Uploader = new UploadService();

class ImageUpload extends Component {

  state = {
    uploading: false,
    image: '',
  };

  onChange = e => {
    const files = Array.from(e.target.files);
    this.setState({ uploading: true });

    const formData = new FormData();
    formData.append('file', files[0]);

    Uploader.uploadFile(formData).then(res => {
      this.setState({
        image: res.fileDownloadUri,
        uploading: false,
      });

      this.props.onFileUploaded(res.fileDownloadUri);
    }).catch(err => errorMessage(err.message));
  };

  render() {
    const { uploading, image } = this.state;

    const content = () => {
      switch (true) {
        case uploading:
          return 'Uploading';
        case image !== '':
          return <img src={image} width='100%' alt='image_upload' />;
        default:
          return <input type='file' className='form - control' onChange={this.onChange} />;
      }
    };

    return (
      <div>
        <div className='buttons'>
          {content()}
        </div>
      </div>
    );
  }

}

export default ImageUpload;