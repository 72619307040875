import React, { createContext, useContext, useState } from 'react';

const RoleContext = createContext({
  groupName: '',
  accessName: [],
  updateGroupName: (value) => value,
  updateAccessName: (value) => value,
});

export const useRoleContext = () => useContext(RoleContext);

export const RoleProvider = ({ children }) => {
  const [groupName, setGroupName] = useState('');
  const [accessName, setAccessName] = useState([]);

  function updateGroupName(value) {
    setGroupName(value);
  }

  function updateAccessName(value) {
    setAccessName(value);
  }

  return (
    <RoleContext.Provider value={{ groupName, accessName, updateGroupName, updateAccessName }}>
      {children}
    </RoleContext.Provider>
  );

};

export default RoleContext;