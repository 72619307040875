import React, { Component } from 'react';
import CashoutService from '../../service/CashoutService';
import { withRouter } from 'react-router-dom';
import GlobalSearchComponent from './GlobalSearchCashout';
import ReactTable from 'react-table-6';
import { errorMessage, successMessage } from '../../utils/NotificationMessage';

const CashoutServices = new CashoutService();

class Cashout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataList: [],
      filteredData: [],
      columns: [],
      loading: true,
      searchInput: '',
    };
  }

  componentDidMount() {
    this.getList();
    this.getColumns();
  }

  getList() {
    CashoutServices.getList().then(e => {
      this.setState({
        dataList: e,
        filteredData: e,
      });
    }).catch(err => {
      errorMessage(err.message);
    }).finally(() => {
      this.setState({loading: false});
    });
  }

  handleSetData = dataList => {
    this.setState({ filteredData: dataList });
  };

  updateStatus(row, status) {
    CashoutServices.edit({ cashout_id: row.r.id, status }).then(_ => {
      this.getList();
      successMessage('Status Cashout Berhasil Diubah');
    }).catch(err => {
      errorMessage(err.message);
    });
  }

  getColumns = () => {
    const formatter = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    });

    const moment = require('moment');

    let columns = [
      {
        Header: 'Nama User',
        id: 'r',
        accessor: d => d,
        Cell: ({ row }) => {
          return (
            <span>{row.r.user.firstName} {row.r.user.lastName}</span>
          );
        },
      },
      {
        Header: 'Amount',
        id: 'amount',
        accessor: d => formatter.format(d.amount),
      },
      {
        Header: 'Bank Information',
        id: 'r',
        accessor: d => d,
        Cell: ({ row }) => {
          return (
            <p>{row.r.bankAccountNo}<br />{row.r.bankAccountHolder}<br />{row.r.bank.name}</p>
          );
        },
      },
      {
        Header: 'Tanggal Transaksi',
        id: 'createdAt',
        accessor: d => moment(d.createdAt).format('DD MMM YYYY, h:mm A'),
      },
      {
        Header: 'Status',
        id: 'r',
        accessor: d => d,
        Cell: ({ row }) => {
          return (
            <span>{row.r.status === 0 ? 'Pending' : 'Transfered'}</span>
          );
        },
      }, {
        Header: 'Action',
        id: 'r',
        accessor: d => d,
        Cell: ({ row }) => (
          row.r.status === 0 ?
            <button className='btn btn-success btn-xs' onClick={() => this.updateStatus(row, 1)}>Set To
              Transfered</button> :
            <button className='btn btn-danger btn-xs' onClick={() => this.updateStatus(row, 0)}>Set To Pending</button>
        ),
      },
    ];
    this.setState({ columns });
  };

  render() {
    let { filteredData, loading, columns } = this.state;
    return (
      <React.Fragment>
        <section className='content-header'>
          <h1>
            Daftar Cashout
          </h1>
          <ol className='breadcrumb'>
            <li className='active'>Data tables</li>
          </ol>
        </section>
        <section className='content'>
          <div className='row'>
            <div className='col-xs-12'>
              <div className='box'>
                <div className='box-body'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='input-group pull-right' style={{ width: 300, marginBottom: 10 }}>
                        {/* <span className="input-group-addon"><i className="fa fa-search" /></span> */}
                        {/* <input type="text" className="form-control" name="searchInput" placeholder="Search User" onChange={this.handleChange} value={searchInput || ""}/> */}
                        <GlobalSearchComponent
                          data={this.state.dataList}
                          handleSetData={this.handleSetData}
                        />
                      </div>
                    </div>
                  </div>
                  <ReactTable
                    columns={columns}
                    data={filteredData}
                    loading={loading}
                    defaultPageSize={10}
                    className='-striped -highlight'
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withRouter(Cashout);