import React, { Component } from 'react';
import DeliveryService from '../../../service/DeliveryService';
import withAuth from '../../../service/auth/withAuth';
import { errorMessage, successMessage } from '../../../utils/NotificationMessage';

const DeliveryServices = new DeliveryService();

class ServicesDetail extends Component {

  constructor() {
    super();
    this.submit = this.submit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      typeList: ['Motor', 'Sepeda', 'Mobil', 'Truck'],
      serviceId: 0,
      serviceName: '',
      typeName: '',
      basePrice: '',
      nextPrice: '',
      initDistance: '',
      maxDistance: '',
      maxWeight: '',
      comission: '',
      isLoading: true,
    };
  }


  componentDidMount() {
    const pathname = window.location.pathname;
    const split = pathname.split('/');
    this.setState({ serviceId: split[2] });
    if (!DeliveryServices.isTokenExpired()) {
      this.getServicesById(split[2]);
    } else {
      DeliveryServices.reloadToken().then(() => {
        this.getServicesById(split[2]);
      }).catch(err => errorMessage(err.message));
    }
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  getServicesById(id) {
    this.setState({ isLoading: true });
    DeliveryServices.getServicesById(id).then(e => {
      this.setState({
        typeName : e.type,
        serviceName: e.service,
        basePrice: e.init_price,
        nextPrice: e.next_price,
        initDistance: e.init_distance,
        maxDistance: e.max_distance,
        maxWeight: e.max_weight,
        comission: e.comission,
        isLoading: false,
      });
    }).catch(err => errorMessage(err.message));
  }

  submit(e) {
    e.preventDefault();
    const jsonData = {};
    this.setState({ isLoading: true });
    jsonData['type'] = this.state.typeName;
    jsonData['service'] = this.state.serviceName;
    jsonData['init_distance'] = this.state.initDistance;
    jsonData['init_price'] = Number(this.state.basePrice);
    jsonData['next_price'] = Number(this.state.nextPrice);
    jsonData['max_weight'] = Number(this.state.maxWeight);
    jsonData['max_distance'] = Number(this.state.maxDistance);
    jsonData['comission'] = Number(this.state.comission);
    if (!DeliveryServices.isTokenExpired()) {
      DeliveryServices.updateServices(this.state.serviceId, jsonData).then(() => {
        successMessage('Data Bershasil diubah');
        this.props.history.replace('/services');
      }).catch(err => errorMessage(err.message)).finally(() => this.setState({ isLoading: false }));
    } else {
      alert('Token Expired, silakan refresh page ini');
    }
  }

  render() {
    if (!this.state.isLoading) {
      return (
        <React.Fragment>
          <section className='content-header'>
            <h1>
              Detail Layanan
            </h1>
          </section>
          <section className='content'>
            <div className='row'>
              <div className='col-xs-12'>
                <div className='box box-primary'>
                  <div className='box-body'>
                    <form className='form-horizontal' onSubmit={this.submit}>
                      <div className='form-group'>
                        <label className='col-sm-3 control-label'>Pilih Type</label>
                        <div className='col-sm-9'>
                          <select name='typeName' value={this.state.typeName} className='form-control'
                                  onChange={this.handleChange}>
                            {this.state.typeList.map((item, index) => (
                              <option key={index} value={item}>{item}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className='form-group'>
                        <label className='col-sm-3 control-label'>Nama Layanan</label>
                        <div className='col-sm-9'>
                          <input type='text' className='form-control' name='serviceName'
                                 placeholder='Nama Services' value={this.state.serviceName} onChange={this.handleChange}
                                 required />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label className='col-sm-3 control-label'>Harga</label>
                        <div className='col-sm-9'>
                          <div className='input-group'>
                            <span className='input-group-addon'>Rp.</span>
                            <input type='number' className='form-control' name='basePrice'
                                   placeholder='Harga' value={this.state.basePrice} onChange={this.handleChange}
                                   required />
                          </div>
                        </div>
                      </div>
                      <div className='form-group'>
                        <label className='col-sm-3 control-label'>Harga Selanjutnya</label>
                        <div className='col-sm-5'>
                          <div className='input-group'>
                            <span className='input-group-addon'>Rp.</span>
                            <input type='number' className='form-control' name='nextPrice'
                                   placeholder='Harga Selanjutnya' value={this.state.nextPrice}
                                   onChange={this.handleChange} required />
                          </div>
                        </div>
                        <label className='col-sm-1 control-label' style={{ textAlign: 'center' }}>per</label>
                        <div className='col-sm-3'>
                          <div className='input-group'>
                            <input type='number' className='form-control' name='initDistance'
                                   value={this.state.initDistance} onChange={this.handleChange} required />
                            <span className='input-group-addon'>km</span>
                          </div>
                        </div>
                      </div>
                      <div className='form-group'>
                        <label className='col-sm-3 control-label'>Maksimal Berat</label>
                        <div className='col-sm-9'>
                          <div className='input-group'>
                            <input type='number' className='form-control' name='maxWeight'
                                   placeholder='Maksimal Berat' value={this.state.maxWeight}
                                   onChange={this.handleChange} required />
                            <span className='input-group-addon'>gram</span>
                          </div>
                        </div>
                      </div>
                      <div className='form-group'>
                        <label className='col-sm-3 control-label'>Maksimal Jarak</label>
                        <div className='col-sm-9'>
                          <div className='input-group'>
                            <input type='number' className='form-control' name='maxDistance'
                                   placeholder='Maksimal Jarak' value={this.state.maxDistance}
                                   onChange={this.handleChange} required />
                            <span className='input-group-addon'>km</span>
                          </div>
                        </div>
                      </div>
                      <div className='form-group'>
                        <label className='col-sm-3 control-label'>Komisi</label>
                        <div className='col-sm-9'>
                          <div className='input-group'>
                            <input type='number' className='form-control' name='comission'
                                   placeholder='Komisi untuk perusahaan' value={this.state.comission}
                                   onChange={this.handleChange} max={100} required />
                            <span className='input-group-addon'>%</span>
                          </div>
                        </div>
                      </div>
                      <div className='form-group'>
                        <div className='col-sm-offset-3 col-sm-2'>
                          <button type='submit' className='btn btn-success'>Simpan</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <section className='content-header'>
            <h1>
              Services Detail
            </h1>
          </section>
          <section className='content'>
            <div className='box box-primary'>
              <div className='box-body box-profile text-center'>
                <i className='fa fa-spinner fa-pulse fa-3x fa-fw' />
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    }
  }
}

export default withAuth(ServicesDetail);