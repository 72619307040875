import React, { Component } from 'react';
import DeliveryService from '../../../service/DeliveryService';
import { Link, withRouter } from 'react-router-dom';
import ReactTable from 'react-table-6';
import GlobalSearchComponent from './GlobalSearchTracking';
import { errorMessage } from '../../../utils/NotificationMessage';

const Delivery = new DeliveryService();

class Tracking extends Component {

  constructor() {
    super();
    this.fetchData = this.fetchData.bind(this);
    this.state = {
      trackingList: [],
      filteredData: [],
      columns: [],
      searchInput: '',
      pages: 0,
      loading: true,
    };
  }

  componentDidMount() {
    this.fetchData.bind(this);
    this.getColumns();
  }

  fetchData(state) {
    this.setState({ loading: true });
    // jsonData['keyword'] = state.filtered;
    if (!Delivery.isTokenExpired()) {
      this.getTrackingList(state);
    } else {
      Delivery.reloadToken().then(() => {
        this.getTrackingList(state);
      }).catch(err => errorMessage(err.message));
    }
  }

  getTrackingList(state) {
    Delivery.getTrackingList().then(e => {
      this.setState({
        trackingList: e.data,
        filteredData: e.data,
      });
    }).catch(err => errorMessage(err.message)).finally(() => this.setState({loading: false}));
  }

  handleSetData = trackingList => {
    this.setState({ filteredData: trackingList });
  };

  getColumns = () => {
    const moment = require('moment-timezone');

    let columns = [
      {
        Header: 'Nama',
        id: 'r',
        accessor: d => d,
        Cell: ({ row }) => (<Link to={{ pathname: '/tracking/' + row.r.id }}>{row.r.fullname}</Link>),
        // id: 'r',
        // accessor: d => d,
        // Cell: ({ row }) => (
        //     <img src={row.r.photoUrlDefault} alt='photoDefault' style={{width : 60, height: 60}} />
        // ),
        // className: 'text-center',
        // sortable: false,
      },
      {
        Header: 'No Kendaraan',
        accessor: 'vehicle_number',
        // id: 'name',
        // accessor: d => d,
        // Cell: ({ row }) => (<Link to={{ pathname: '/product/'+row.name.id}}>{row.name.name}</Link>)
      },
      {
        Header: 'LatLon',
        id: 'r',
        accessor: d => d,
        Cell: ({ row }) => row.r.lat + ', ' + row.r.lng,
        // id: 'price',
        // accessor: d => Number(d.price),
        // Cell : ({ row }) => formatter.format(row.price),
      },
      {
        Header: 'Terakhir Update',
        id: 'r',
        accessor: d => d,
        Cell: ({ row }) => moment.utc(row.r.last_update).local().format('LLL'),
      },
    ];
    this.setState({ columns });
  };

  render() {
    let { filteredData, columns } = this.state;
    return (
      <React.Fragment>
        {/* Content Header (Page header) */}
        <section className='content-header'>
          <h1>
            Daftar Tracking
          </h1>
          <ol className='breadcrumb'>
            <li className='active'>Data tables</li>
          </ol>
        </section>
        {/* Main content */}
        <section className='content'>
          <div className='row'>
            <div className='col-xs-12'>
              <div className='box'>
                <div className='box-body'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='input-group pull-right' style={{ width: 300, marginBottom: 10 }}>
                        <GlobalSearchComponent
                          data={this.state.trackingList}
                          handleSetData={this.handleSetData} />
                      </div>
                    </div>
                  </div>
                  <ReactTable
                    columns={columns}
                    data={filteredData}
                    sortable={false}
                    // filtered={this.state.filter}
                    loading={this.state.loading}
                    onFetchData={this.fetchData}
                    defaultPageSize={10} />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /.content */}
      </React.Fragment>
    );
  }
}

export default withRouter(Tracking);