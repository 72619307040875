import { GET_ME, UPDATE_PROFILE_PICTURE, USER } from '../utils/APIUtil';
import axios from 'axios';
import AuthService from './auth/AuthService';

const Auth = new AuthService();
export default class ProfileService {
  constructor() {
    this.fetch = this.fetch.bind(this);
  }

  getMe() {
    return this.fetch(GET_ME, {
      method: 'GET',
    }).then(res => {
      if (res.result === 'NOK') {
        return Promise.reject(res.error);
      }
      return Promise.resolve(res);
    });
  }

  updateProfile(jsonData) {
    return axios.put(USER, jsonData, { headers: { Authorization: 'Bearer ' + Auth.getToken() } })
      .then(response => {
        const res = response.data;
        if (res.result === 'OK') {
          return Promise.resolve(res);
        } else {
          return Promise.reject(res.error);
        }
      });
  }

  updateProfilePicture(formData) {
    return axios.post(UPDATE_PROFILE_PICTURE, formData, { headers: { Authorization: 'Bearer ' + Auth.getToken() } })
      .then(response => {
        const res = response.data;
        if (res.result === 'NOK') {
          return Promise.reject(res.error);
        }
        if (res.user.photo !== '') {
          Auth.setUserFoto(res.user.photo);
        }
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  fetch(url, options) {
    // performs api calls sending the required authentication headers
    const headers = {
      'Accept': 'application/json',
    };
    headers['Authorization'] = 'Bearer ' + Auth.getToken();
    return fetch(url, {
      headers,
      ...options,
    })
      .then(this._checkStatus)
      .then(response => response.json());
  }

  _checkStatus(response) {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      const error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  }
}
