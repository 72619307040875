import React, { Component } from 'react';
import PromoService from '../../service/PromoService';
import { Link, withRouter } from 'react-router-dom';

import ImageUpload from '../ImageUpload';
import { errorMessage, successMessage } from '../../utils/NotificationMessage';

const Promos = new PromoService();

class Promo extends Component {

  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.handleAddPromo = this.handleAddPromo.bind(this);
    this.onFileUploaded = this.onFileUploaded.bind(this);
    this.state = {
      promo_title: 'Promo Sample Genesis 3',
      promo_page_url: '',
      active: 1,
      image_url: '',
      promo_code: 'bogapromotest3',
      start_period: '29-04-2020 01:00:00',
      end_period: '19-05-2020 14:00:00',
      cashback_maximum_amount: 45000,
      cashback_minimum_payment: 40000,
      cashback_percent: 20,
      usage_quota: 999,
      usage_quota_per_user: 1,
      product_category: 'Produk kecantikan dari Roshan Store!',
      promoList: [],
    };
  }

  componentDidMount() {
    this.getPromoList();
  }

  handleChange(e) {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
    );
  }

  onFileChange(e) {
    if (e.target.files.length > 0) {
      this.setState({
        selectedFile: e.target.files[0],
      });
    } else {
      this.setState({
        selectedFile: null,
      });
    }
  }

  onFileUploaded(url) {
    this.setState({
      image_url: url,
    });
  }

  handleAddPromo(e) {
    e.preventDefault();
    const {
      promo_title,
      promo_page_url,
      active,
      image_url,
      promo_code,
      start_period,
      end_period,
      cashback_maximum_amount,
      cashback_minimum_payment,
      cashback_percent,
      usage_quota,
      usage_quota_per_user,
      product_category,
    } = this.state;

    Promos.addPromo({
      promo_title,
      promo_page_url,
      active,
      image_url,
      promo_code,
      start_period,
      end_period,
      cashback_maximum_amount,
      cashback_minimum_payment,
      cashback_percent,
      usage_quota,
      usage_quota_per_user,
      product_category,
    }).then(() => {
      successMessage('Berhasil Menambah Banner');
      this.getPromoList();
      this.setState({
        promo_title: '',
        promo_page_url: '',
        active: 0,
        image_url: '',
        promo_code: '',
        start_period: '',
        end_period: '',
        cashback_maximum_amount: 0,
        cashback_minimum_payment: 0,
        cashback_percent: 0,
        usage_quota: 0,
        usage_quota_per_user: 0,
        product_category: '',
      });
    }).catch(err => errorMessage(err.message));
  }

  getPromoList() {
    Promos.getPromoList().then(res => {
      this.setState({
        promoList: res.promo_pages,
      });
    }).catch(err => errorMessage(err.message));
  }

  onDelete(id) {
    Promos.deletePromo(id).then(() => {
      successMessage('Berhasil Dihapus');
      this.getPromoList();
    }).catch(err => errorMessage(err.message));
  }

  render() {
    return (
      <React.Fragment>
        <section className='content-header'>
          <h1>
            Daftar Promo
          </h1>
        </section>
        {/* Main content */}
        <section className='content'>
          <div className='row'>
            <div className='col-xs-12'>
              <div className='box'>
                {/* /.box-header */}
                <div className='box-body'>
                  <div style={{ marginBottom: 10, marginTop: 20 }}>
                    <div className='list-group-item'>
                      <b>Create Promo</b>
                      <br /><br />
                      <div className='row'>
                        <div className='col-md-12'>
                          <form onSubmit={this.handleAddPromo}>
                            <div className='row'>
                              <div className='col-md-6'>
                                <div className='form-group'>
                                  <label className='control-label'>Upload Image</label>
                                  <ImageUpload onFileUploaded={this.onFileUploaded} />
                                </div>
                              </div>
                              <div className='col-md-6'>
                                <div className='form-group'>
                                  <label className='control-label'>Promo Title</label>
                                  <input type='text' className='form-control' name='promo_title'
                                         placeholder='Promo Title' value={this.state.promo_title}
                                         onChange={this.handleChange} required />
                                </div>
                              </div>
                              <div className='col-md-6'>
                                <div className='form-group'>
                                  <label className='control-label'>Promo Page URL</label>
                                  <input type='text' className='form-control' name='promo_page_url'
                                         placeholder='Page URL' value={this.state.promo_page_url}
                                         onChange={this.handleChange} />
                                </div>
                              </div>
                              <div className='col-md-6'>
                                <div className='form-group'>
                                  <label className='control-label'>Promo Code</label>
                                  <input type='text' className='form-control' name='promo_code'
                                         placeholder='Promo Code' value={this.state.promo_code}
                                         onChange={this.handleChange} required />
                                </div>
                              </div>
                              <div className='col-md-6'>
                                <div className='form-group'>
                                  <label className='control-label'>Start Periode</label>
                                  <input type='text' className='form-control' name='start_period'
                                         placeholder='Start Period' value={this.state.start_period}
                                         onChange={this.handleChange} required />
                                </div>
                              </div>
                              <div className='col-md-6'>
                                <div className='form-group'>
                                  <label className='control-label'>End Perios</label>
                                  <input type='text' className='form-control' name='end_period'
                                         placeholder='End Periode' value={this.state.end_period}
                                         onChange={this.handleChange} required />
                                </div>
                              </div>
                              <div className='col-md-6'>
                                <div className='form-group'>
                                  <label className='control-label'>Max Cashback</label>
                                  <input type='text' className='form-control' name='cashback_maximum_amount'
                                         placeholder='Max Cashback' value={this.state.cashback_maximum_amount}
                                         onChange={this.handleChange} required />
                                </div>
                              </div>
                              <div className='col-md-6'>
                                <div className='form-group'>
                                  <label className='control-label'>Min Payment</label>
                                  <input type='text' className='form-control' name='cashback_minimum_payment'
                                         placeholder='Min Payment' value={this.state.cashback_minimum_payment}
                                         onChange={this.handleChange} required />
                                </div>
                              </div>
                              <div className='col-md-6'>
                                <div className='form-group'>
                                  <label className='control-label'>Cashback %</label>
                                  <input type='text' className='form-control' name='cashback_percent'
                                         placeholder='Cashback %' value={this.state.cashback_percent}
                                         onChange={this.handleChange} required />
                                </div>
                              </div>
                              <div className='col-md-6'>
                                <div className='form-group'>
                                  <label className='control-label'>Usage Quota</label>
                                  <input type='text' className='form-control' name='usage_quota'
                                         placeholder='Usage Quota' value={this.state.usage_quota}
                                         onChange={this.handleChange} required />
                                </div>
                              </div>
                              <div className='col-md-6'>
                                <div className='form-group'>
                                  <label className='control-label'>Usage Quota / User</label>
                                  <input type='text' className='form-control' name='usage_quota_per_user'
                                         placeholder='Usage Quota / User' value={this.state.usage_quota_per_user}
                                         onChange={this.handleChange} required />
                                </div>
                              </div>
                              <div className='col-md-6'>
                                <div className='form-group'>
                                  <label className='control-label'>Product Category</label>
                                  <input type='text' className='form-control' name='product_category'
                                         placeholder='Product Category' value={this.state.product_category}
                                         onChange={this.handleChange} required />
                                </div>
                              </div>
                              <div className='col-md-6'>
                                <div className='form-group'>
                                  <label className='control-label'>Status</label>
                                  <select className='form-control' name='active' onChange={this.handleChange}
                                          value={this.state.active}>
                                    <option value='1'>Active</option>
                                    <option value='0'>Inactive</option>
                                  </select>
                                </div>
                              </div>
                              <div className='col-md-12'>
                                <div className='form-group text-right'>
                                  <div className='col-sm-12'>
                                    <button type='submit' className='btn btn-primary'>
                                      <i className='fa fa-plus'> Create</i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul className='todo-list list-group'>
                    {this.state.promoList.map((item, index) => (
                      <li key={index} style={{ marginBottom: 10, display: 'flex', alignItems: 'center' }}>
                        <img alt='image_url' src={item.image_url} style={{ width: 200, marginRight: 10 }} />
                        <div style={{ flex: 'auto' }}>
                          <span className='text m-0'>{item.promo_title}</span><br />
                          <span>{item.product_category}</span>
                        </div>

                        <div className='tools'>
                          <Link to='#' onClick={() => {
                            if (window.confirm('Are you sure you wish to delete this banner?')) this.onDelete(item.id);
                          }}>
                            <i className='fa fa-trash-o'/>
                          </Link>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withRouter(Promo);