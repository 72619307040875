import React, { Component } from 'react';
import CategoryService from '../../service/CategoryService';
import { errorMessage, successMessage } from '../../utils/NotificationMessage';

const Categorys = new CategoryService();

class CategoryDetail extends Component {

  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdateCategory = this.handleUpdateCategory.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.state = {
      categoryId: 0,
      categoryName: '',
      categoryParent: 0,
      defaultFile: null,
      selectedFile: null,
      editedFile: null,
      categoryList: [],
    };
  }

  componentDidMount() {
    const pathname = window.location.pathname;
    const split = pathname.split('/');
    const categoryId = Number(split[2]);
    this.setState({
      categoryId: categoryId,
    });
    this.getCategoryById(categoryId);
    this.getCategoryList();
  }

  getCategoryById(categoryId) {
    Categorys.getCategoryById(categoryId).then(res => {
      this.setState({
        categoryName: res.category.name,
        categoryParent: res.tree.id,
        defaultFile: res.category.iconUrl,
        selectedFile: res.category.iconUrl,
      });
    }).catch(err => {
      errorMessage(err.message);
    });
  }

  getCategoryList() {
    Categorys.getCategoryList().then(res => {
      this.setState({
        categoryList: res,
      });
    }).catch(err => errorMessage(err.message));
  }

  onFileChange(e) {
    let reader = new FileReader();
    if (e.target.files.length > 0) {
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        this.setState({
          editedFile: reader.result,
        });
      };
      this.setState({
        selectedFile: e.target.files[0],
      });
    } else {
      this.setState({
        selectedFile: this.state.defaultFile,
        editedFile: null,
      });
    }
  }

  handleChange(e) {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
    );
  }

  validate(nameCategory) {
    this.setState({ errors: [] });
    const errors = [];
    if (nameCategory.length < 1) {
      errors.push('Nama Kategori tidak boleh kosong');
    }
    return errors;
  }

  handleUpdateCategory(e) {
    e.preventDefault();
    const name = this.state.categoryName;
    const parent = this.state.categoryParent !== this.state.categoryId ? this.state.categoryParent : null;
    const errors = this.validate(name);
    const data = new FormData();
    if (errors.length > 0) {
      this.setState({ errors });
      return;
    }
    const jsonData = {};
    jsonData['name'] = name;
    jsonData['parentId'] = parent;
    Categorys.updateCategory(jsonData, this.state.categoryId).then(() => {
      if (this.state.editedFile !== null) {
        data.append('file', this.state.selectedFile);
        Categorys.addIcon(data, this.state.categoryId).then(() => {
          successMessage('Update Product Berhasil');
          this.props.history.replace('/category');
        }).catch(err => errorMessage(err.message));
        return;
      }
      successMessage('Update Product Berhasil');
      this.props.history.replace('/category');
    }).catch(err => errorMessage(err.message));
  }

  render() {
    return (
      <React.Fragment>
        <section className='content-header'>
          <h1>
            Edit Category
          </h1>
        </section>
        <section className='content'>
          <div className='box box-primary'>
            <div className='box-body'>
              <form className='form-horizontal' onSubmit={this.handleUpdateCategory}>
                <div className='form-group'>
                  <label className='col-sm-3 control-label' />
                  <div className='col-sm-9'>
                    {this.state.selectedFile !== null ?
                      <div className='form-control' style={{ width: 200, height: 200 }}>
                        {this.state.editedFile === null ?
                          <a href={this.state.selectedFile} data-fancybox={''}>
                            <img src={this.state.selectedFile} alt='iconCategory' style={{ width: 100 + '%' }} />
                          </a> :
                          <img src={this.state.editedFile} alt='iconCategory' style={{ width: 100 + '%' }} />}
                      </div> :
                      <div className='form-control text-center'
                           style={{ width: 200, height: 200, lineHeight: 200 + 'px' }}><span>No Icon</span></div>}
                  </div>
                </div>
                <div className='form-group'>
                  <label className='col-sm-3 control-label'>Icon Kategori</label>
                  <div className='col-sm-9'>
                    <input className='form-control' type='file'
                           accept='image/*' id='file' name='file' onChange={this.onFileChange} />
                  </div>
                </div>
                <div className='form-group'>
                  <label htmlFor='inputName' className='col-sm-3 control-label'>Nama Kategori</label>
                  <div className='col-sm-9'>
                    <input type='text' className='form-control' name='categoryName'
                           placeholder='Nama Kategori' value={this.state.categoryName}
                           onChange={this.handleChange} />
                  </div>
                </div>
                <div className='form-group'>
                  <label htmlFor='inputName' className='col-sm-3 control-label'>Parent Category</label>
                  <div className='col-sm-9'>
                    <select name='categoryParent' className='form-control' value={this.state.categoryParent}
                            onChange={this.handleChange}>
                      {this.state.categoryList.map((item, index) => (
                        <option key={index} value={item.id}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className='form-group'>
                  <div className='col-sm-offset-3 col-sm-2'>
                    <button type='submit' className='btn btn-success'>Simpan</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default CategoryDetail;