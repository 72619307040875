import { ORDER, ORDERID } from '../utils/APIUtil';
import axios from 'axios';
import AuthService from './auth/AuthService';

const Auth = new AuthService();
export default class OrderService {
  constructor() {
    this.fetch = this.fetch.bind(this);
  }

  async getOrderList(page, limit) {
    const res = await this.fetch(ORDER, {
      method: 'GET',
    });
    return Promise.resolve(res);
  }

  async getOrderById(orderId) {
    const res = await this.fetch(ORDERID + '/' + orderId, {
      method: 'GET',
    });
    return Promise.resolve(res);
  }

  async updateOrder(jsonData, id) {
    const response = await axios.put(ORDER + '/' + id, jsonData, { headers: { Authorization: 'Bearer ' + Auth.getToken() } });
    const res = response.data;
    if (res.result === 'OK') {
      return Promise.resolve(res);
    } else {
      return Promise.reject(res.error);
    }
  }

  fetch(url, options) {
    // performs api calls sending the required authentication headers
    const headers = {
      'Accept': 'application/json',
    };
    headers['Authorization'] = 'Bearer ' + Auth.getToken();
    return fetch(url, {
      headers,
      ...options,
    })
      .then(this._checkStatus)
      .then(response => response.json());
  }

  _checkStatus(response) {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      const error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  }
}
