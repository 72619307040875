import React, { Component } from 'react';
import PromoService from '../../service/PromoService';
import { Link, withRouter } from 'react-router-dom';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { errorMessage, successMessage } from '../../utils/NotificationMessage';

const Promos = new PromoService();

class PromoWebView extends Component {

  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
    this.handleAddPromo = this.handleAddPromo.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.state = {
      btnTitle: 'Create',
      id: null,
      editorState: EditorState.createEmpty(),
      promoList: [],
    };
  }

  componentDidMount() {
    this.getPromoList();
  }

  handleChange(e) {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
    );
  }

  onEditorStateChange(editorState) {
    this.setState({
      editorState,
    });
  };

  handleEdit(id) {
    Promos.getPromoWVById(id).then(dt => {
      const contentBlock = htmlToDraft(dt.promo_pages.content);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        this.setState({
          btnTitle: 'Update',
          id: id,
          editorState,
        });
      }
    }).catch(err => errorMessage(err.message));
  }

  handleAddPromo(e) {
    e.preventDefault();
    const content = this.state.editorState;
    const data = {
      content: draftToHtml(convertToRaw(content.getCurrentContent())),
    };
    if (this.state.id === null) {
      Promos.addPromoWV(data).then(() => {
        successMessage('Berhasil Menambah Promo');
        this.getPromoList();
        this.setState({
          id: null,
          btnTitle: 'Create',
          editorState: EditorState.createEmpty(),
        });
      }).catch(err => errorMessage(err.message));
    } else {
      data.id = this.state.id;
      Promos.editPromoWV(data).then(() => {
        successMessage('Berhasil Merubah Promo');
        this.getPromoList();
        this.setState({
          id: null,
          btnTitle: 'Create',
          editorState: EditorState.createEmpty(),
        });
      }).catch(err => errorMessage(err.message));
    }
  }

  getPromoList() {
    Promos.getPromoViewList().then(res => {
      this.setState({
        promoList: res.promo_pages,
      });
    }).catch(err => errorMessage(err.message));
  }

  onDelete(id) {
    Promos.deletePromoWV(id).then(() => {
      successMessage('Berhasil Dihapus', 'Category');
      this.getPromoList();
    }).catch(err => errorMessage(err.message));
  }

  render() {

    const { editorState } = this.state;

    return (
      <React.Fragment>
        <section className='content-header'>
          <h1>
            Daftar Promo Page
          </h1>
        </section>
        {/* Main content */}
        <section className='content'>
          <div className='row'>
            <div className='col-xs-12'>
              <div className='box'>
                {/* /.box-header */}
                <div className='box-body'>
                  <div style={{ marginBottom: 10, marginTop: 20 }}>
                    <div className='list-group-item'>
                      <b>Create Promo Page</b>
                      <form className='form-horizontal' onSubmit={this.handleAddPromo}>
                        <div className='form-group'>
                          <label className='col-sm-3 control-label'>Content</label>
                          <div className='col-sm-9'>
                            <Editor
                              editorState={editorState}
                              toolbarClassName='toolbarClassName'
                              wrapperClassName='demo-wrapper'
                              editorClassName='form-control'
                              onEditorStateChange={this.onEditorStateChange}
                            />
                            {/* <input type='text' className='form-control' name='descBanner'
                                                            placeholder='Deskripsi Banner' value={this.state.content} onChange={this.handleChange} required /> */}
                          </div>
                        </div>
                        <div className='form-group text-right'>
                          <div className='col-sm-12'>
                            <button type='submit' className='btn btn-primary'>
                              <i className='fa fa-plus'> {this.state.btnTitle}</i>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <ul className='todo-list list-group'>
                    {this.state.promoList.map((item, index) => (
                      <li key={index} style={{ marginBottom: 10, display: 'flex', alignItems: 'center' }}>
                        <div style={{ flex: 'auto' }}>
                          <span className='text m-0'>{item.content}</span><br />
                        </div>

                        <div className='tools'>
                          <Link to='#' onClick={() => this.handleEdit(item.id)} style={{ marginRight: 5 }}>
                            <i className='fa fa-edit' />
                          </Link>
                          <Link to='#' onClick={() => {
                            if (window.confirm('Are you sure you wish to delete this banner?')) this.onDelete(item.id);
                          }}>
                            <i className='fa fa-trash-o' />
                          </Link>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withRouter(PromoWebView);