import React, { Component } from 'react';
import OrderService from '../../service/OrderService';
import { Link, withRouter } from 'react-router-dom';
import GlobalSearchComponent from './GlobalSearchOrder';
import ReactTable from 'react-table-6';
import { errorMessage } from '../../utils/NotificationMessage';

const Orders = new OrderService();

class Order extends Component {

  constructor() {
    super();
    this.state = {
      data: [],
      filteredData: [],
      columns: [],
      loading: true,
      searchInput: '',
    };
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.fetchData.bind(this);
    this.getColumns();
  }

  fetchData(state) {
    this.setState({ loading: true });
    Orders.getOrderList().then(res => {
      this.setState({
        loading: false,
        data: res.list,
        filteredData: res.list,
      });
    }).catch(err => errorMessage(err.message));
  }

  getColumns = () => {
    const formatter = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    });

    const moment = require('moment');

    let columns = [
      {
        Header: 'No. Kuitansi',
        id: 'r',
        accessor: d => d,
        Cell: ({ row }) => (<Link to={{ pathname: '/order/' + row.r.id }}>{row.r.receiptId}</Link>),
      },
      {
        Header: 'Total Harga',
        id: 'Price',
        accessor: d => formatter.format(d.totalPrice + d.deliveryCost),
      },
      {
        Header: 'Tanggal Pesanan',
        id: 'createdAt',
        accessor: d => moment(d.createdAt).format('DD MMM YYYY, h:mm A'),
      },
      {
        Header: 'Nama Pembeli',
        id: 'user',
        accessor: d => d.user.firstName + ' ' + d.user.lastName,
      },
      {
        Header: 'Nama Penjual',
        accessor: 'seller.namaToko',
      },
      {
        Header: 'Status Pesanan',
        accessor: 'status',
        id: 'status',
        Cell: ({ value }) => (
          value === 7 ? 'Dibatalkan' : value === 6 ? 'Selesai' : value === 5 ? 'Diterima' : value === 4 ? 'Sedang Dikirim' : value === 3 ? 'Pesanan Siap Diambil' : value === 2 ? 'Diproses' : value === 1 ? 'Menunggu Konfirmasi' : value === 0 ? 'Menunggu Pembayaran' : 'Unknown'),
      },
    ];
    this.setState({ columns });
  };

  handleSetData = data => {
    console.log(data);
    this.setState({ filteredData: data });
  };

  render() {
    let { filteredData, columns } = this.state;

    return (
      <React.Fragment>
        {/* Content Header (Page header) */}
        <section className='content-header'>
          <h1>
            Daftar Order
          </h1>
          <ol className='breadcrumb'>
            <li className='active'>Data tables</li>
          </ol>
        </section>
        {/* Main content */}
        <section className='content'>
          <div className='row'>
            <div className='col-xs-12'>
              <div className='box'>
                {/* /.box-header */}
                <div className='box-body'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='input-group pull-right' style={{ width: 300, marginBottom: 10 }}>
                        {/* <span className="input-group-addon"><i className="fa fa-search" /></span> */}
                        {/* <input type="text" className="form-control" name="searchInput" placeholder="Search User" onChange={this.handleChange} value={searchInput || ""}/> */}
                        <GlobalSearchComponent
                          data={this.state.data}
                          handleSetData={this.handleSetData}
                        />
                      </div>
                    </div>
                  </div>
                  <ReactTable
                    columns={columns}
                    data={filteredData}
                    sortable={false}
                    defaultPageSize={10}
                    loading={this.state.loading}
                    onFetchData={this.fetchData} />
                </div>
                {/* /.box-body */}
              </div>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </section>
        {/* /.content */}
      </React.Fragment>
    );
  }
}

export default withRouter(Order);