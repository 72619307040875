export const RoleAccessList = [
  {
    nameCategory: 'User',
    categoryList: [
      'UserList',
      'UserManagement',
    ]
  },
  {
    nameCategory: 'Seller',
    categoryList: [
      'SellerList',
      'SellerManagement',
    ]
  },
  {
    nameCategory: 'Product',
    categoryList: [
      'ProductList',
      'ProductManagement',
    ]
  },
  {
    nameCategory: 'Order',
    categoryList: [
      'OrderList',
      'OrderManagement',
    ]
  },
  {
    nameCategory: 'Category',
    categoryList: [
      'CategoryList',
      'CategoryManagement',
    ]
  },
  {
    nameCategory: 'Promo',
    categoryList: [
      'PromoManagement',
      'PromoContentManagement',
    ]
  },
  {
    nameCategory: 'Cash Out',
    categoryList: [
      'CashOutManagement',
    ]
  },
  {
    nameCategory: 'Term & Condition',
    categoryList: [
      'TermConditionManagement',
    ]
  }
]

export const RoleAccessDeliveryList = [
  {
    nameCategory: 'HollaMan Delivery',
    categoryList: [
      'HollaManDeliveryList',
      'HollaManDeliveryManagement',
    ]
  },
  {
    nameCategory: 'HollaMan Driver',
    categoryList: [
      'HollaManDriverList',
      'HollaManDriverManagement',
    ]
  },
  {
    nameCategory: 'HollaMan Service',
    categoryList: [
      'HollaManServiceList',
      'HollaManServiceManagement',
    ]
  },
  {
    nameCategory: 'HollaMan Tracking',
    categoryList: [
      'HollaManTrackingList',
      'HollaManTrackingDetail',
    ]
  },
]