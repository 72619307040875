import React, { Component } from 'react';
import DeliveryService from '../../../service/DeliveryService';
import withAuth from '../../../service/auth/withAuth';
import { errorMessage, successMessage } from '../../../utils/NotificationMessage';

const DeliveryServices = new DeliveryService();

class DriverDetail extends Component {

  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.handleActivatedDriver = this.handleActivatedDriver.bind(this);
    this.handleUpdateDriver = this.handleUpdateDriver.bind(this);
    this.state = {
      driverId: 0,
      driverData: [],
      fullname: '',
      email: '',
      phone: '',
      address: '',
      status: false,
      isLoading: true,
      defaultFile: '',
      editedFile: '',
      selectedFile: null,
    };
  }

  componentDidMount() {
    const pathname = window.location.pathname;
    const split = pathname.split('/');
    this.setState({ driverId: split[2] });
    if (!DeliveryServices.isTokenExpired()) {
      this.getDriverDetail(split[2]);
    } else {
      DeliveryServices.reloadToken().then(() => {
        this.getDriverDetail(split[2]);
      }).catch(err => errorMessage(err.message));
    }
  }

  getDriverDetail(id) {
    this.setState({ isLoading: true });
    DeliveryServices.getDriverDetail(id).then(e => {
      console.log(e.data);
      const status = e.data.status_id === 1;
      this.setState({
        driverData: e.data,
        fullname: e.data.fullname,
        email: e.data.email,
        phone: e.data.phone,
        address: e.data.address,
        status: status,
        defaultFile: e.data.profile_pic,
      });
    }).catch(err => errorMessage(err.message)).finally(() => this.setState({ isLoading: false }));
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleChangeStatus() {
    this.setState({ status: !this.state.status });
  }

  handleUpdateDriver(e) {
    e.preventDefault();
    this.setState({ isLoading: true });
    const data = new FormData();
    if (this.state.selectedFile !== null) {
      data.append('driver_id', this.state.driverId);
      data.append('image', this.state.selectedFile);
      if (!DeliveryServices.isTokenExpired()) {
        DeliveryServices.postPictDriver(data).then(_ => {
          this.updateDriverData();
        }).catch(err => errorMessage(err.message));
      } else {
        alert('Token Expired silakan refresh page ini');
      }
      return;
    }
    this.updateDriverData();
  }

  updateDriverData() {
    const { driverData } = this.state;
    const jsonData = {};
    jsonData['username'] = driverData.username;
    jsonData['fullname'] = this.state.fullname;
    jsonData['email'] = this.state.email;
    jsonData['phone'] = this.state.phone;
    jsonData['address'] = this.state.address;
    jsonData['status_id'] = this.state.status ? 1 : 0;
    jsonData['token'] = driverData.token;
    if (!DeliveryServices.isTokenExpired()) {
      console.log(JSON.stringify(jsonData));
      DeliveryServices.editDriver(this.state.driverId, jsonData).then(() => {
        successMessage('Update Driver Berhasil');
        this.props.history.replace('/driver');
        // this.getDriverDetail(this.state.driverId);
      }).catch(err => errorMessage(err.message)).finally(() => this.setState({ isLoading: false }));
    } else {
      alert('Token Expired silakan refresh page ini');
    }
  }

  handleActivatedDriver() {
    if (!DeliveryServices.isTokenExpired()) {
      DeliveryServices.activateDriver(this.state.driverId).then(() => {
        successMessage('Activasi Driver Berhasil');
        this.getDriverDetail(this.state.driverId);
      }).catch(err => errorMessage(err.message));
    } else {
      alert('Token Expired silakan refresh page ini');
    }
  }

  onFileChange(e) {
    let reader = new FileReader();
    if (e.target.files.length > 0) {
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        this.setState({
          editedFile: reader.result,
        });
      };
      this.setState({
        selectedFile: e.target.files[0],
      });
    } else {
      this.setState({
        selectedFile: this.state.defaultFile,
        editedFile: null,
      });
    }
  }

  onDelete() {
    if (!DeliveryServices.isTokenExpired()) {
      DeliveryServices.deleteDriver(this.state.driverId).then(e => {
        if (e.status === 200) {
          successMessage('Berhasil Delete Driver');
          this.props.history.push('/driver');
        }
      }).catch(err => errorMessage(err.message));
    } else {
      alert('Token Expired silakan refresh page ini');
    }
  }

  render() {
    const { driverData } = this.state;

    if (!this.state.isLoading) {
      return (
        <React.Fragment>
          <section className='content-header'>
            <h1>
              Driver Detail
            </h1>
          </section>
          <section className='content'>
            <div className='row'>
              <div className='col-md-3'>
                <div className='box box-primary'>
                  <div className='box-body box-profile flex-center'>
                    <span className='fa-stack fa-lg icon-delete' title='Delete Driver' onClick={() => {
                      if (window.confirm('Are you sure want to delete this driver?')) this.onDelete();
                    }}>
                      <i className='fa fa-circle fa-stack-2x text-danger' />
                      <i className='fa fa-trash-o fa-stack-1x fa-inverse' />
                    </span>
                    {this.state.selectedFile !== null ?
                      <img className='profile-user-img img-responsive img-circle can-change-pp'
                           src={this.state.editedFile} alt='driver_pic' /> :
                      this.state.defaultFile !== '' ?
                        <img className='profile-user-img img-responsive img-circle can-change-pp'
                             src={this.state.defaultFile} alt='driver_pic' /> :
                        <div className='profile-user-img img-circle text-center can-change-pp'
                             style={{ lineHeight: 138 + 'px' }}>
                          <span>No Photo</span>
                        </div>
                    }
                    <div className='profile-user-img img-circle text-center change-pp'
                         style={{ lineHeight: 138 + 'px' }} onClick={() => {
                      this.refs.fileUploader.click();
                    }}>
                      <span>Ganti Foto</span>
                    </div>
                    <input className='d-none' type='file' accept='image/*' id='file' name='file' ref='fileUploader'
                           onChange={this.onFileChange} />
                    <h3 className='profile-username text-center'>{driverData.fullname}</h3>
                    <p className='text-muted text-center'>{driverData.vehicle_number}</p>
                    <p className='text-muted text-center'>{driverData.no_imei}</p>
                  </div>
                </div>

                <div className='box box-primary'>
                  <div className='box-body box-profile'>
                    <h4>Dokumen Pendaftaran</h4>
                    <hr />
                    <div className='row'>
                      <div className='col-md-6'>
                        <label>KTP</label>
                        {driverData.doc_ktp !== null ?
                          <img className='img-responsive' src={driverData.doc_ktp} alt='ktp' /> :
                          <div className='img-bordered img-responsive text-center'
                               style={{ lineHeight: 138 + 'px' }}>
                            <span>No KTP Uploaded</span>
                          </div>
                        }
                      </div>
                      <div className='col-md-6'>
                        <label>SELFIE</label>
                        {driverData.doc_selfie !== null ?
                          <img className='img-responsive' src={driverData.doc_selfie} alt='selfie' /> :
                          <div className='img-bordered img-responsive text-center'
                               style={{ lineHeight: 138 + 'px' }}>
                            <span>No Selfie Uploaded</span>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-9'>
                <div className='box box-primary'>
                  <div className='box-body box-profile'>
                    <form className='form-horizontal' onSubmit={this.handleUpdateDriver}>
                      <div className='form-group'>
                        <label htmlFor='inputName' className='col-sm-3 control-label'>Nama</label>
                        <div className='col-sm-9'>
                          <input type='text' className='form-control' id='fullname' name='fullname'
                                 placeholder='Nama Lengkap' value={this.state.fullname}
                                 onChange={this.handleChange} />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label htmlFor='inputName' className='col-sm-3 control-label'>E-mail</label>
                        <div className='col-sm-9'>
                          <input type='email' className='form-control' id='email' name='email'
                                 placeholder='E-mail' value={this.state.email}
                                 onChange={this.handleChange} />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label htmlFor='inputName' className='col-sm-3 control-label'>No Handphone</label>
                        <div className='col-sm-9'>
                          <input type='text' className='form-control' id='phone' name='phone'
                                 placeholder='No Handphone' value={this.state.phone}
                                 onChange={this.handleChange} />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label htmlFor='inputName' className='col-sm-3 control-label'>Alamat</label>
                        <div className='col-sm-9'>
                          <textarea className='form-control' name='address' rows='4' cols='50'
                                    placeholder='Alamat Driver' value={this.state.address}
                                    onChange={this.handleChange} />
                        </div>
                      </div>
                      {/*<div className='form-group'>*/}
                      {/*  <label htmlFor='inputName' className='col-sm-3 control-label'>No IMEI</label>*/}
                      {/*  <div className='col-sm-9'>*/}
                      {/*    <input type='text' className='form-control' id='noimei' name='noimei'*/}
                      {/*           placeholder='No Imei' value={this.state.noimei}*/}
                      {/*           onChange={this.handleChange} />*/}
                      {/*  </div>*/}
                      {/*</div>*/}
                      <div className='form-group'>
                        <label htmlFor='inputName' className='col-sm-3 control-label'>Status Driver</label>
                        <div className='col-sm-9'>
                          <label className='toggle'>
                            <input className='toggle-checkbox' type='checkbox' checked={this.state.status}
                                   onChange={() => this.handleChangeStatus()} />
                            <div className='toggle-switch' />
                          </label>
                        </div>
                      </div>
                      <div className='form-group'>
                        <div className='col-sm-offset-3 col-sm-2'>
                          <button type='submit' className='btn btn-success'>Simpan</button>
                        </div>
                      </div>
                      {
                        driverData.is_active === 0 ?
                          <div className='form-group'>
                            <div className='col-sm-offset-3 col-sm-2'>
                              <button onClick={this.handleActivatedDriver} type='button'
                                      className='btn btn-info'>Aktivasi Driver
                              </button>
                            </div>
                          </div>
                          : ''
                      }
                    </form>
                  </div>
                </div>

                <div className='box box-primary'>
                  <div className='box-body box-profile'>
                    <h4>Dokumen Kendaraan</h4>
                    <hr />
                    {driverData.services.length > 0 ?
                      <React.Fragment>

                        <form className='form-horizontal' style={{ marginBottom: '36px' }}>

                          <div className='form-group'>
                            <label htmlFor='inputName' className='col-sm-2 control-label'>Layanan Delivery</label>
                            <div className='col-sm-10'>
                              <input type='text' className='form-control' readOnly={true}
                                     defaultValue={`${driverData.services[0].type} - ${driverData.services[0].service}`} />
                            </div>
                          </div>

                          <div className='form-group'>
                            <label htmlFor='inputName' className='col-sm-2 control-label'>Merk Kendaraan</label>
                            <div className='col-sm-10'>
                              <input type='text' className='form-control' readOnly={true}
                                     defaultValue={driverData.services[0].vehicle_manufacturer} />
                            </div>
                          </div>

                          {driverData.services[0].type !== 'Sepeda' ?
                            <React.Fragment>
                              <div className='form-group'>
                                <label htmlFor='inputName' className='col-sm-2 control-label'>Tahun Kendaraan</label>
                                <div className='col-sm-10'>
                                  <input type='text' className='form-control' readOnly={true}
                                         defaultValue={driverData.services[0].vehicle_year} />
                                </div>
                              </div>

                              <div className='form-group'>
                                <label htmlFor='inputName' className='col-sm-2 control-label'>Plat Nomer</label>
                                <div className='col-sm-10'>
                                  <input type='text' className='form-control' readOnly={true}
                                         defaultValue={driverData.services[0].license_number} />
                                </div>
                              </div>
                            </React.Fragment> : ''
                          }

                        </form>

                        <div className='row'>
                          <div className='col-md-12' style={{ marginBottom: '36px' }}>
                            <label>Foto Kendaraan</label>
                            {driverData.services[0].vehicle_image !== null ?
                              <div className='row'>
                                {driverData.services[0].vehicle_image.split("***").map((val, index) =>
                                  <div className='col-md-4' key={index}>
                                    <img className='img-responsive' src={val} alt='vehicle' />
                                  </div>
                                )}
                              </div> :
                              <div className='img-bordered img-responsive text-center'
                                   style={{ lineHeight: 138 + 'px' }}>
                                <span>No Vehicle Image</span>
                              </div>
                            }
                          </div>

                          {driverData.services[0].type !== 'Sepeda' ?
                            <React.Fragment>
                              <div className='col-md-6'>
                                <label>SIM Driver</label>
                                {driverData.services[0].sim_photo_url !== null && driverData.services[0].sim_photo_url ?
                                  <img className='img-responsive' src={driverData.services[0].sim_photo_url} alt='sim' /> :
                                  <div className='img-bordered img-responsive text-center'
                                       style={{ lineHeight: 138 + 'px' }}>
                                    <span>No SIM Uploaded</span>
                                  </div>
                                }
                              </div>

                              <div className='col-md-6'>
                                <label>STNK Kendaraan</label>
                                {driverData.services[0].stnk_photo_url !== null && driverData.services[0].stnk_photo_url ?
                                  <img className='img-responsive' src={driverData.services[0].stnk_photo_url}
                                       alt='stnk' /> :
                                  <div className='img-bordered img-responsive text-center'
                                       style={{ lineHeight: 138 + 'px' }}>
                                    <span>No STNK Uploaded</span>
                                  </div>
                                }
                              </div>
                            </React.Fragment> : ""
                          }

                        </div>
                      </React.Fragment> : <div>Belum Ada Document Kendaraan</div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <section className='content-header'>
            <h1>
              Driver Detail
            </h1>
          </section>
          <section className='content'>
            <div className='box box-primary'>
              <div className='box-body box-profile text-center'>
                <i className='fa fa-spinner fa-pulse fa-3x fa-fw' />
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    }
  }
}

export default withAuth(DriverDetail);