import { CASHOUT } from '../utils/APIUtil';
import axios from 'axios';
import AuthService from './auth/AuthService';

const Auth = new AuthService();
export default class CashoutService {

  async getList() {
    const response = await axios.get(CASHOUT, { headers: { Authorization: 'Bearer ' + Auth.getToken() } });
    const res = response.data;
    if (response.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject(res.error);
    }
  }

  async edit(formData) {
    const response = await axios.post(CASHOUT + '/status', formData, { headers: { Authorization: 'Bearer ' + Auth.getToken() } });
    const res = response.data;
    console.log(response);
    if (res.result === 'OK') {
      return Promise.resolve(res);
    } else {
      return Promise.reject(res.error);
    }
  }

  async add(formData) {
    const response = await axios.post(CASHOUT, formData, { headers: { Authorization: 'Bearer ' + Auth.getToken() } });
    const res = response.data;
    console.log(response);
    if (res.result === 'OK') {
      return Promise.resolve(res);
    } else {
      return Promise.reject(res.error);
    }
  }

}    