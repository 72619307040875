import React, { useEffect, useState } from 'react';
import RoleService from '../../service/RoleService';
import { errorMessage, successMessage } from '../../utils/NotificationMessage';
import ReactTable from 'react-table-6';
import { Link, useHistory } from 'react-router-dom';
import UserService from '../../service/UserService';

const RoleUserManagement = () => {
  const history = useHistory();
  const roleService = new RoleService();
  const userService = new UserService();
  const [columns, setColumns] = useState([]);
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [roleGroupList, setRoleGroupList] = useState([]);
  const [selectedIdUser, setSelectedIdUser] = useState(0);
  const [userRoleGroupList, setUserRoleGroupList] = useState([]);
  const [selectedIdRoleGroup, setSelectedIdRoleGroup] = useState(0);

  useEffect(() => {
    getRoleGroupList();
    getUserRoleList();
    getUserList();
    getColumns();
  }, []);

  function getUserList() {
    setIsLoading(true);
    userService.getAdminUserList().then(value => {
      setUserList(value);
    }).catch(err => errorMessage(err.message)).finally(() => setIsLoading(false));
  }

  function getUserRoleList() {
    setIsLoading(true);
    roleService.getUserRoleList().then(value => {
      setUserRoleGroupList(value.userRoleGroupList);
    }).catch(err => errorMessage(err.message)).finally(() => setIsLoading(false));
  }

  function getRoleGroupList() {
    setIsLoading(true);
    roleService.getRoleGroupList().then(value => {
      setRoleGroupList(value.roleGroups);
    }).catch(err => errorMessage(err.message)).finally(() => setIsLoading(false));
  }

  function addUserRole(e) {
    e.preventDefault();
    if (selectedIdUser > 0 && selectedIdRoleGroup > 0) {
      setIsLoading(true);
      roleService.addUserToRoleGroup(selectedIdRoleGroup, selectedIdUser).then(() => {
        successMessage('Berhasil Menambahkan User ke Role Group');
        setSelectedIdRoleGroup(0);
        setSelectedIdUser(0);
        getUserRoleList();
      }).catch(err => errorMessage(err.message)).finally(() => setIsLoading(false));
      return;
    }
    errorMessage('Pastikan Semua terisi');
  }

  function deleteRoleGroupUser(e, userId, roleGroupId) {
    e.preventDefault();
    if (confirm("Yakin ingin delete role group dari user ini ?")) {
      setIsLoading(true);
      roleService.removeUserToRoleGroup(roleGroupId, userId).then(() => {
        successMessage('Berhasil Menghapus User ke Role Group');
        getUserRoleList();
      }).catch(err => errorMessage(err.message)).finally(() => setIsLoading(false));
    }
  }

  const getColumns = () => {
    let columns = [
      {
        Header: 'Nama User',
        id: 'r',
        accessor: d => d,
        Cell: ({ row }) => (
          <Link to={{ pathname: '/user/' + row.r.user.id }}>{row.r.user.firstName} {row.r.user.lastName}</Link>),
      },
      {
        Header: 'Email',
        id: 'r',
        accessor: d => d,
        Cell: ({ row }) => {
          return (<span>{row.r.user.email}</span>);
        },
      },
      {
        Header: 'Nomor Telepon',
        id: 'r',
        accessor: d => d,
        Cell: ({ row }) => {
          return (<span>{row.r.user.phone}</span>);
        },
      },
      {
        Header: 'Role Group',
        id: 'r',
        accessor: d => d,
        Cell: ({ row }) => {
          return (<span>{row.r.roleGroup.name}</span>);
        },
      },
      {
        Header: '',
        id: 'r',
        width: 50,
        accessor: d => d,
        Cell: ({ row }) => {
          return (
            <a href={'#'} onClick={(e) => deleteRoleGroupUser(e, row.r.user.id, row.r.roleGroup.id)}
               style={{display: 'flex', justifyContent: 'center', color: 'red'}}>
              <i className='fa fa-trash'/>
            </a>
          );
        },
      },
    ];
    setColumns(columns);
  };

  return (
    <React.Fragment>
      <section className='content-header'>
        <h1>
          Role User Management
        </h1>
      </section>
      {!isLoading ?
        <section className='content'>
          <div className='row'>
            <div className='col-xs-12'>
              <div className='box box-primary'>
                <div className='box-body'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='input-group pull-left' style={{ marginBottom: 10 }}>
                        <button type='button' className='btn btn-primary' onClick={() => {history.push('/createadmin');}}>
                          <i className='fa fa-plus'> Create User Admin</i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <ReactTable
                    columns={columns}
                    data={userRoleGroupList}
                    loading={isLoading}
                    defaultPageSize={5}
                    className='-striped -highlight'
                  />
                  <div style={{ marginBottom: 10, marginTop: 20 }}>
                    <div className='category-group list-group-item'>
                      <b>Add User Role Group</b>
                      <div style={{ marginBottom: 10, marginTop: 20 }} onSubmit={addUserRole}>
                        <form className='form-horizontal'>
                          <select name='typeName' className='form-control' value={selectedIdUser}
                                  onChange={(event => setSelectedIdUser(Number(event.target.value)))}>
                            <option value={0}>Pilih User Admin</option>
                            {userList.map((item) => (
                              <option key={item.id} value={item.id}>{`${item.completeName}`}</option>
                            ))}
                          </select>
                          <div style={{ marginBottom: 10, marginTop: 10 }}>
                            <select name='typeName' className='form-control' value={selectedIdRoleGroup}
                                    onChange={(event => setSelectedIdRoleGroup(Number(event.target.value)))}>
                              <option value={0}>Pilih Role Group</option>
                              {roleGroupList.map((item) => (
                                <option key={item.id} value={item.id}>{`${item.name}`}</option>
                              ))}
                            </select>
                          </div>
                          <div className='form-group text-right'>
                            <div className='col-sm-12'>
                              <button type='submit' className='btn btn-primary'>
                                Add User Role
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> :
        <section className='content'>
          <div className='box box-primary'>
            <div className='box-body box-profile text-center'>
              <i className='fa fa-spinner fa-pulse fa-3x fa-fw' />
            </div>
          </div>
        </section>
      }
    </React.Fragment>
  );
};

export default RoleUserManagement;