import React from 'react';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { useRoleContext } from '../contexts/RoleContext';

const Menu = () => {
  const history = useHistory();
  const roleContext = useRoleContext();

  function goTo(url) {
    history.push(url);
  }

  function activeClass(...route) {
    return route.map((value) => {
      if ('/' + window.location.pathname.split('/')[1] === value) {
        return ' active ';
      } else {
        return '';
      }
    });
  }

  return (
    <aside className='main-sidebar'>
      <section className='sidebar'>
        <ul className='sidebar-menu' data-widget='tree'>
          <li className='header'>MAIN NAVIGATION</li>
          <li className={activeClass('/') + ' treeview'}>
            <Link to={{ pathname: '/' }} onClick={() => {goTo('/')}}>
              <i className='fa fa-dashboard' />
              <span> Dashboard</span>
            </Link>
          </li>
          {roleContext.accessName.some(value => value === 'UserList') ?
            <li className={activeClass('/user') + ' treeview'}>
              <Link to={{ pathname: '/user' }} onClick={() => {goTo('/user')}}>
                <i className='fa fa-user' />
                <span> User</span>
              </Link>
            </li> : ""
          }
          {roleContext.accessName.some(value => value === 'SellerList') ?
            <li className={activeClass('/seller') + ' treeview'}>
              <Link to={{ pathname: '/seller' }} onClick={() => {goTo('/seller')}}>
                <i className='fa fa-users' />
                <span> Seller</span>
              </Link>
            </li> : ""
          }
          {roleContext.accessName.some(value => value === 'ProductList') ?
            <li className={activeClass('/product') + ' treeview'}>
              <Link to={{ pathname: '/product' }} onClick={() => {goTo('/product')}}>
                <i className='fa fa-tags' />
                <span> Produk</span>
              </Link>
            </li> : ""
          }
          {roleContext.accessName.some(value => value === 'OrderList') ?
            <li className={activeClass('/order') + ' treeview'}>
              <Link to={{ pathname: '/order' }} onClick={() => {goTo('/order')}}>
                <i className='fa fa-exchange' />
                <span> Order</span>
              </Link>
            </li> : ""
          }
          {roleContext.accessName.some(value => value === 'CashOutManagement') ?
            <li className={activeClass('/cashout') + ' treeview'}>
              <Link to={{ pathname: '/cashout' }} onClick={() => {goTo('/cashout')}}>
                <i className='fa fa-money' />
                <span> Cashout</span>
              </Link>
            </li> : ""
          }
          {roleContext.accessName.some(value => value === 'CategoryList') ?
            <li className={activeClass('/category') + ' treeview'}>
              <Link to={{ pathname: '/category' }} onClick={() => {goTo('/category')}}>
                <i className='fa fa-tag' />
                <span> Kategori</span>
              </Link>
            </li> : ""
          }
          {roleContext.accessName.some(value => value === 'PromoManagement') ?
            <li className={activeClass('/promo') + ' treeview'}>
              <Link to={{ pathname: '/promo' }} onClick={() => {goTo('/promo')}}>
                <i className='fa fa-percent' />
                <span> Promo</span>
              </Link>
            </li> : ""
          }
          {roleContext.accessName.some(value => value === 'PromoContentManagement') ?
            <li className={activeClass('/promowv') + ' treeview'}>
              <Link to={{ pathname: '/promowv' }} onClick={() => {goTo('/promowv')}}>
                <i className='fa fa-picture-o' />
                <span> Promo Page</span>
              </Link>
            </li> : ""
          }
          {roleContext.accessName.some(value => value === 'TermConditionManagement') ?
            <li className={activeClass('/termcondition') + ' treeview'}>
              <Link to={{ pathname: '/termcondition' }} onClick={() => {goTo('/termcondition')}}>
                <i className='fa fa-list' />
                <span> Terms & Conditions</span>
              </Link>
            </li> : ""
          }
          {roleContext.accessName.some(value => value.includes('HollaMan')) ?
            <li className={activeClass('/driver', '/delivery', '/services', '/tracking', '/createdriver') + ' treeview'}>
              <Link to={{ pathname: '/' }} onClick={(e) => {e.preventDefault()}}>
                <i className='fa fa-paper-plane' />
                <span> Delivery</span>
                <span className='pull-right-container'><i className='fa fa-angle-left pull-right' /></span>
              </Link>
              <ul className='treeview-menu'>
                {roleContext.accessName.some(value => value === 'HollaManDeliveryList') ?
                  <li className={activeClass('/delivery')}>
                    <Link to={{ pathname: '/delivery' }} onClick={() => {goTo('/delivery')}}>
                      <i className='fa fa-circle-o' />
                      <span> Delivery</span>
                    </Link>
                  </li> : ""
                }
                {roleContext.accessName.some(value => value === 'HollaManDriverList') ?
                  <li className={activeClass('/driver', '/createdriver')}>
                    <Link to={{ pathname: '/driver' }} onClick={() => {goTo('/driver')}}>
                      <i className='fa fa-circle-o' />
                      <span> Driver</span>
                    </Link>
                  </li> : ""
                }
                {roleContext.accessName.some(value => value === 'HollaManServiceList') ?
                  <li className={activeClass('/services')}>
                    <Link to={{ pathname: '/services' }} onClick={() => {goTo('/services')}}>
                      <i className='fa fa-circle-o' />
                      <span> Layanan</span>
                    </Link>
                  </li> : ""
                }
                {roleContext.accessName.some(value => value === 'HollaManTrackingList') ?
                  <li className={activeClass('/tracking')}>
                    <Link to={{ pathname: '/tracking' }} onClick={() => {goTo('/tracking')}}>
                      <i className='fa fa-circle-o' />
                      <span> Tracking</span>
                    </Link>
                  </li> : ""
                }
              </ul>
            </li> : ""
          }
          {roleContext.accessName.some(value => value === 'RoleManagement') ?
            <li className={activeClass('/rolegroup', '/roleuser') + ' treeview'}>
              <Link to={{ pathname: '/' }} onClick={(e) => {e.preventDefault()}}>
                <i className='fa fa-user-secret' />
                <span> Role Management</span>
                <span className='pull-right-container'><i className='fa fa-angle-left pull-right' /></span>
              </Link>
              <ul className='treeview-menu'>
                <li className={activeClass('/rolegroup')}>
                  <Link to={{ pathname: '/rolegroup' }} onClick={() => {goTo('/rolegroup')}}>
                    <i className='fa fa-circle-o' />
                    <span> Manage Group Role</span>
                  </Link>
                </li>
                <li className={activeClass('/roleuser')}>
                  <Link to={{ pathname: '/roleuser' }} onClick={() => {goTo('/rolegroup')}}>
                    <i className='fa fa-circle-o' />
                    <span> Manage User Role</span>
                  </Link>
                </li>
              </ul>
            </li> : ""
          }
        </ul>
      </section>
    </aside>
  );
};

export default withRouter(Menu);
