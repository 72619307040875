import React, { Component } from 'react';
import AuthService from './AuthService';
import ProfileService from '../ProfileService';

export default function withAuth(AuthComponent) {
  const Auth = new AuthService();
  const Profile = new ProfileService();
  return class AuthWrapped extends Component {
    constructor() {
      super();
      this.state = {
        user: null,
      };
    }

    componentDidMount() {
      if (!Auth.loggedIn()) {
        this.props.history.replace('/login');
      } else {
        Profile.getMe().then(res => {
            if (res.result !== 'OK') {
              Auth.logout();
              this.props.history.replace('/login');
            }
          }).catch(err => {
            Auth.logout();
            this.props.history.replace('/login');
            alert(err);
          });
      }
      try {
        const profile = Auth.getName();
        this.setState({
          user: profile,
        });
      } catch (err) {
        Auth.logout();
        this.props.history.replace('/login');
      }
    }

    render() {
      if (this.state.user) {
        return (
          <AuthComponent history={this.props.history} user={this.state.user} />
        );
      } else {
        return null;
      }
    }
  };
}

