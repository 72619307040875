import React, { Component } from 'react';
import OrderService from '../../service/OrderService';
import { errorMessage, successMessage } from '../../utils/NotificationMessage';

const Order = new OrderService();

class OrderDetail extends Component {

  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdateOrder = this.handleUpdateOrder.bind(this);
    this.state = {
      orderId: 0,
      status: 0,
      orderInfo: [],
      users: [],
      seller: [],
      sellerInfo: [],
      address: [],
      addressOrder: [],
      addressSeller: [],
      orders: [],
      errors: [],
    };
  }

  componentDidMount() {
    const pathname = window.location.pathname;
    const split = pathname.split('/');
    this.setState({
      orderId: split[2],
    });
    this.getOrderById(split[2]);
  }

  getOrderById(orderId) {
    Order.getOrderById(orderId).then(res => {
      const orderId = res.data.orderId;
      this.setState({
        orderInfo: orderId,
        status: orderId.status,
        users: orderId.user,
        seller: orderId.seller,
        sellerInfo: orderId.seller.user,
        addressSeller: orderId.seller.address.kodepos,
        addressOrder: orderId.address,
        address: orderId.address.kodepos,
        orders: res.data.orders,
      });
    }).catch(err => errorMessage(err.message));
  }

  getStatusOrder(statusId) {
    switch (statusId) {
      case 0 :
      case '0' :
        return 'Menunggu Pembayaran';
      case 1 :
      case '1' :
        return 'Menunggu Konfirmasi';
      case 2 :
      case '2' :
        return 'Diproses';
      case 3 :
      case '3' :
        return 'Pesanan Siap Diambil';
      case 4 :
      case '4' :
        return 'Sedang Dikirim';
      case 5 :
      case '5' :
        return 'Diterima';
      case 6 :
      case '6' :
        return 'Selesai';
      case 7 :
      case '7' :
        return 'Dibatalkan';
      default :
        return 'Unknown';
    }
  }

  handleChange(e) {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
    );
  }

  handleUpdateOrder(e) {
    e.preventDefault();
    const status = this.state.status;
    const jsonData = {};
    jsonData['status'] = Number(status);
    Order.updateOrder(jsonData, this.state.orderId).then(() => {
      successMessage('Update Status Order Berhasil');
      this.props.history.replace('/order');
    }).catch(err => errorMessage(err.message));
  }

  render() {
    const moment = require('moment');
    return (
      <React.Fragment>
        <section className='content-header'>
          <h1>
            Order Detail
          </h1>
        </section>
        <section className='content'>
          <div className='box box-primary'>
            <div className='box-body box-profile'>
              <section className='invoice'>
                <div className='row'>
                  <div className='col-xs-12'>
                    <h2 className='page-header'>
                      <i className='fa fa-globe' /> HollaCash
                      <small
                        className='pull-right'>Date: {moment(this.state.orderInfo.orderDate).format('DD/MM/YYYY')}</small>
                    </h2>
                  </div>
                </div>

                <div className='row invoice-info'>
                  <div className='col-sm-4 invoice-col'>
                    To :
                    <address>
                      <strong>{this.state.addressOrder.receiver}</strong><br />
                      {this.state.address.desa}, {this.state.address.kecamatan}<br />
                      {this.state.address.daerahTingkatDua} {this.state.address.kabupatenKota}, {this.state.address.provinsi}, {this.state.address.kodePos}<br />
                      Phone: {this.state.users.phone}
                    </address>
                  </div>
                  <div className='col-sm-4 invoice-col'>
                    From :
                    <address>
                      <strong>{this.state.seller.namaToko}</strong><br />
                      {this.state.addressSeller.desa}, {this.state.addressSeller.kecamatan}<br />
                      {this.state.addressSeller.daerahTingkatDua} {this.state.addressSeller.kabupatenKota}, {this.state.addressSeller.provinsi} ,{this.state.addressSeller.kodePos}<br />
                      Phone: {this.state.sellerInfo.phone}
                    </address>
                  </div>
                  <div className='col-sm-4 invoice-col'>
                    <b>Invoice : </b>{this.state.orderInfo.receiptId}<br />
                    <b>Status Order : </b>{this.getStatusOrder(this.state.status)}<br />
                    <b>Delivery Agent : </b><span
                    className='text-uppercase'>{this.state.orderInfo.deliveryAgent} {this.state.orderInfo.deliveryService}</span><br />
                    <b>Delivery Number : </b>{this.state.orderInfo.deliveryNumber}<br />
                  </div>
                </div>

                <div className='row'>
                  <div className='col-xs-12 table-responsive'>
                    <table className='table table-striped'>
                      <thead>
                      <tr>
                        <th>Qty</th>
                        <th>Product</th>
                        <th>Note</th>
                        <th>Subtotal</th>
                      </tr>
                      </thead>
                      <tbody>
                      {this.state.orders.map((item, index) => (
                        <tr key={index}>
                          <td>{item.qty}</td>
                          <td>{item.product.name}</td>
                          <td>{item.note}</td>
                          <td>Rp.{item.product.price * item.qty}</td>
                        </tr>
                      ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-xs-6 pull-right'>
                    <div className='table-responsive'>
                      <table className='table'>
                        <tbody>
                        <tr>
                          <td>Subtotal :</td>
                          <td>Rp. {this.state.orderInfo.totalPrice}</td>
                        </tr>
                        <tr>
                          <td>Shipping :</td>
                          <td>Rp. {this.state.orderInfo.deliveryCost}</td>
                        </tr>
                        <tr>
                          <td>Total :</td>
                          <td>Rp. {this.state.orderInfo.totalPrice + this.state.orderInfo.deliveryCost}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </section>
              <hr />
              <form className='form-horizontal' onSubmit={this.handleUpdateOrder}>
                <div className='form-group'>
                  <label htmlFor='inputName' className='col-sm-3 control-label'>Status Order</label>
                  <div className='col-sm-9'>
                    <select name='status' className='form-control' value={this.state.status}
                            onChange={this.handleChange}>
                      <option value='0'>Menunggu Pembayaran</option>
                      <option value='1'>Menunggu Konfirmasi</option>
                      <option value='2'>Diproses</option>
                      <option value='3'>Pesanan Siap Diambil</option>
                      <option value='4'>Sedang Dikirim</option>
                      <option value='5'>Diterima</option>
                      <option value='6'>Selesai</option>
                      <option value='7'>Dibatalkan</option>
                    </select>
                  </div>
                </div>
                <div className='form-group'>
                  <div className='col-sm-offset-3 col-sm-2'>
                    <button type='submit' className='btn btn-success'>Simpan</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default OrderDetail;