import React, { Component } from 'react';
import AuthService from '../service/auth/AuthService';
import ProfileService from '../service/ProfileService';
import { errorMessage } from '../utils/NotificationMessage';
import 'react-notifications/lib/notifications.css';
import { Link } from 'react-router-dom';

class Login extends Component {

  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.Auth = new AuthService();
    this.Profile = new ProfileService();
    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    this.checkLogin();
  }

  checkLogin() {
    if (this.Auth.loggedIn()) {
      this.Profile.getMe().then(() => {
        try {
          const profile = this.Auth.getName();
          this.setState({
            user: profile,
          });
          window.location.replace('/');
        } catch (err) {
          this.Auth.logout();
          this.props.history.replace('/login');
        }
      }).catch(err => errorMessage(err.message));
    }
  }

  handleFormSubmit(e) {
    e.preventDefault();
    this.setState({ isLoading: true });
    this.Auth.login(this.state.emailOrPhone, this.state.password).then(() => {
      this.Auth.loginDelivery(this.Auth.getToken()).then(e => {
        this.Auth.setTokenDelivery(e.token);
        window.location.replace('/');
      }).catch(err => errorMessage(err.message)).finally(() => this.setState({ isLoading: false }));
    }).catch(_ => {
      errorMessage('Invalid username or password')
      this.setState({ isLoading: false });
    });
  }

  handleChange(e) {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
    );
  }

  render() {
    if (this.Auth.loggedIn()) {
      return (
        <div />
      );
    } else {
      return (
        <React.Fragment>
          <div className='login-page' style={{ display: 'flex', placeItems: 'center', height: '100vh' }}>
            <div className='login-box'>
              <div className='login-logo'>
                <b>Boga</b> Dashboard
              </div>
              {/* /.login-logo */}
              <div className='login-box-body'>
                <p className='login-box-msg'>Silahkan Masuk</p>
                <form onSubmit={this.handleFormSubmit}>
                  <div className='form-group has-feedback'>
                    <input type='email' className='form-control' placeholder='Email' name='emailOrPhone'
                           onChange={this.handleChange} />
                    <span className='glyphicon glyphicon-envelope form-control-feedback' />
                  </div>
                  <div className='form-group has-feedback'>
                    <input type='password' className='form-control' placeholder='Password'
                           name='password' onChange={this.handleChange} />
                    <span className='glyphicon glyphicon-lock form-control-feedback' />
                  </div>
                  <div className='row'>
                    {/* /.col */}
                    <div className='col-xs-4'>
                      <button type='submit' className='btn btn-primary btn-block btn-flat'
                              value='SUBMIT' disabled={this.state.isLoading}>Login
                      </button>
                    </div>
                    <div className='col-xs-8' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Link to={{ pathname: '/forgetPassword' }} onClick={() => {
                        this.props.history.push('/forgetPassword');
                      }}>
                        <span>Forget Password?</span>
                      </Link>
                    </div>
                    {/* /.col */}
                  </div>
                </form>
              </div>
              {/* /.login-box-body */}
              <p>&nbsp;</p>
            </div>
          </div>
        </React.Fragment>
      );
    }

  }
}

export default Login;