import React, { Component } from 'react';
import SellerService from '../../service/SellerService';
import ReactTable from 'react-table-6';
import { Link, withRouter } from 'react-router-dom';
import GlobalSearchComponent from './GlobalSearchSeller';
import { errorMessage } from '../../utils/NotificationMessage';

const Sellers = new SellerService();

class Seller extends Component {
	constructor() {
		super();
		this.state = {
			sellerList: [],
			filteredData: [],
			columns: [],
			loading: true,
			searchInput: ''
		}
	}

	componentDidMount() {
		this.getSellerList()
		this.getColumns()
	}

	getSellerList() {
		Sellers.getSellerList().then(res => {
			this.setState({
				loading: false,
				sellerList: res,
				filteredData: res,
			})
		}).catch(err => errorMessage(err.message))
	}

	handleSetData = res => {
		this.setState({ filteredData: res })
	}

	getColumns = () => {
		let columns = [
			{
				Header: 'Nama Toko',
				id: 'r',
				accessor: d => d,
				Cell: ({ row }) => (<Link to={{ pathname: '/seller/' + row.r.user.id }} className="mailbox-name" key={row.r.user.id}> {row.r.namaToko} </Link>)
			},
			{
				Header: 'Pemilik Toko',
				accessor: 'user',
				Cell: row => {
					return (
						<div>
							<p>{row.row.user.firstName} {row.row.user.lastName}</p>
						</div>
					)
				}
			},
			{
				Header: 'Status Toko',
				id: 'r',
				accessor: d => d,
				Cell: ({ row }) => {
					return (
						<div>
							<p>{row.r.isActive ? 'Aktif' : 'Tidak Aktif'} {row.r.officialSeller ? <b><span className="text-yellow">(Official)</span></b> : ''}</p>
						</div>
					)
				}
			}
		]
		this.setState({ columns })
	}

	render() {

		let { filteredData, loading, columns } = this.state

		return (
			<React.Fragment>
				<section className="content-header">
					<h1>
						Daftar Seller
						</h1>
					<ol className="breadcrumb">
						<li className="active">Data tables</li>
					</ol>
				</section>
				{/* Main content */}
				<section className="content">
					<div className="row">
						<div className="col-xs-12">
							<div className="box">
								{/* /.box-header */}
								<div className="box-body">
									<div className="row">
										<div className="col-md-12">
											<div className="input-group pull-right" style={{ width: 300, marginBottom: 10 }}>
												<GlobalSearchComponent
													data={this.state.sellerList}
													handleSetData={this.handleSetData}
												/>
											</div>
										</div>
									</div>
									<ReactTable
										columns={columns}
										data={filteredData}
										loading={loading}
										defaultPageSize={10}
										className="-striped -highlight"
									/>
								</div>
								{/* /.box-body */}
							</div>
						</div>
						{/* /.col */}
					</div>
					{/* /.row */}
				</section>
				{/* /.content */}
			</React.Fragment>
		);
	}
}

export default withRouter(Seller);