import { UPLOADFILE } from '../utils/APIUtil';
import axios from 'axios';
import AuthService from './auth/AuthService';

const Auth = new AuthService();
export default class UploadService {

  async uploadFile(formData) {
    const response = await axios.post(UPLOADFILE, formData, { headers: { Authorization: 'Bearer ' + Auth.getToken() } });
    return response.data;
    // console.log(response)
    // if (res.result === "OK") {
    //     return Promise.resolve(res);
    // }
    // else {
    //     return Promise.reject(res.error);
    // }
  }
}    