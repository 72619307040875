import { PROMO, PROMOVIEW } from '../utils/APIUtil';
import axios from 'axios';
import AuthService from './auth/AuthService';

const Auth = new AuthService();
export default class PromoService {

  async getPromoList() {
    const response = await axios.get(PROMO, { headers: { Authorization: 'Bearer ' + Auth.getToken() } });
    const res = response.data;
    if (response.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject(res.error);
    }
  }

  async getPromoViewList() {
    const response = await axios.get(PROMOVIEW + '?withContent=1', { headers: { Authorization: 'Bearer ' + Auth.getToken() } });
    const res = response.data;
    if (response.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject(res.error);
    }
  }

  async getPromoById(id) {
    const response = await axios.get(PROMO + '/' + id);
    console.log(response);
    const res = response.data;
    if (response.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject(res.error);
    }
  }

  async getPromoWVById(id) {
    const response = await axios.get(PROMOVIEW + '/' + id, { headers: { Authorization: 'Bearer ' + Auth.getToken() } });
    const res = response.data;
    if (response.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject(res.error);
    }
  }

  async editPromo(formData, id) {
    const response = await axios.put(PROMO + '/' + id, formData, { headers: { Authorization: 'Bearer ' + Auth.getToken() } });
    const res = response.data;
    console.log(response);
    if (res.result === 'OK') {
      return Promise.resolve(res);
    } else {
      return Promise.reject(res.error);
    }
  }

  async editPromoWV(formData, id) {
    const response = await axios.put(PROMOVIEW, formData, { headers: { Authorization: 'Bearer ' + Auth.getToken() } });
    const res = response.data;
    console.log(response);
    if (res.result === 'OK') {
      return Promise.resolve(res);
    } else {
      return Promise.reject(res.error);
    }
  }

  async addPromo(formData) {
    const response = await axios.post(PROMO, formData, { headers: { Authorization: 'Bearer ' + Auth.getToken() } });
    const res = response.data;
    console.log(response);
    if (res.result === 'OK') {
      return Promise.resolve(res);
    } else {
      return Promise.reject(res.error);
    }
  }

  async addPromoWV(formData) {
    const response = await axios.post(PROMOVIEW, formData, { headers: { Authorization: 'Bearer ' + Auth.getToken() } });
    const res = response.data;
    console.log(response);
    if (res.result === 'OK') {
      return Promise.resolve(res);
    } else {
      return Promise.reject(res.error);
    }
  }

  async deletePromo(id) {
    const response = await axios.delete(PROMO, {
      data: { id: id },
      headers: { Authorization: 'Bearer ' + Auth.getToken() },
    });
    const res = response.data;
    if (response.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject(res.error);
    }
  }

  async deletePromoWV(id) {
    const response = await axios.delete(PROMOVIEW, {
      data: { id: id },
      headers: { Authorization: 'Bearer ' + Auth.getToken() },
    });
    const res = response.data;
    if (response.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject(res.error);
    }
  }
}    